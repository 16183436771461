import * as yup from "yup";
import i18n from "./i18n";

yup.setLocale({
  mixed: {
    required: i18n.t("greco.form.errors.required"),
  },
  number: {
    min: i18n.t("greco.form.errors.number.min"),
    max: i18n.t("greco.form.errors.number.max"),
  },
});

export default yup;
