import { errorHandler } from "components/AppHeader/NotificationMessages";
import { QUERY_KEYS, notificationTypes } from "library/shared";
import { DataObj } from "library/types";
import moment from "moment";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";

const generateCascoInvoice = async (dataObj: DataObj) => {
  const response = await api.post(`/GenerateCascoInvoice`, {
    appInfo: {
      dataObj: {
        ...dataObj,
        inp_external_contractdate_ut: moment(
          dataObj?.inp_external_contractdate_ut
        ).format("YYYY-MM-DD"),
      },
      resolvedProperties: {
        title: "BCR Heavy Machinery",
      },
    },
  });

  return response.data?.appInfo;
};

function useGenerateCascoInvoice() {
  const queryClient = useQueryClient();

  return useMutation(generateCascoInvoice, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.vehicleList });
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.offerDataItem });
    },
    onError: (error: any) => {
      errorHandler({
        label: "Error",
        type: notificationTypes.error,
        value: "Error while generate casco invoice ",
        errors: error?.message,
        fieldName: "",
      });
    },
  });
}

export default useGenerateCascoInvoice;
