import React, {
  useMemo,
  useState,
  useCallback,
  createContext,
  useContext,
} from "react";

import { getTabConfig } from "pages/VehicleItem/config";
import {
  formatDataForReadOnlyView,
  getValidationSchemaObj,
} from "pages/VehicleItem/utils";
import { useLoadOfferData } from "hooks/data/queries/useLoadOfferData";
import { useLocation } from "react-router";
import { EMainTab, ESidemenuTab, EViewMode } from "pages/VehicleItem/types";
import { COPY_OFFER, OPEN_OFFER } from "library/shared";
import { useLoadCopyOfferData } from "hooks/data/queries/useLoadCopyOfferData";
import { ObjectShape } from "yup/lib/object";
import { AnySchema } from "yup";
import Reference from "yup/lib/Reference";
import Lazy from "yup/lib/Lazy";
import { IVehicle } from "library/types";
import { offerConfig } from "pages/VehicleItem/OfferPreview/config";
import { useTranslation } from "react-i18next";

interface IContext {
  offerData: IVehicle;
  isReadOnlyMode: boolean;
  onSideTabSet: (tab: ESidemenuTab) => void;
  onNextSideTab: () => void;
  onPrevSideTab: () => void;
  onMainTabSet: (tab: EMainTab) => void;
  activeMainTab: EMainTab;
  activeSideTab: ESidemenuTab | string;
  viewMode: EViewMode;
  onSetViewMode: (tab: EViewMode) => void;
  tabConfig: any;
  offerDataLoadedSuccessfully: boolean;
  isLoadingOfferData: boolean;
  validationSchemaObj: ObjectShape;
  onUpdateValidationSchemaObj: (
    fieldValidation: Record<string, AnySchema | Reference | Lazy<any, any>>
  ) => void;
}

type Props = {
  children: React.ReactNode;
};

export const VehicleFormContext = createContext<IContext | undefined>(
  undefined
);

export const useVehicleFormContext = (): IContext => {
  const context = useContext(VehicleFormContext);

  if (!context) {
    throw new Error(
      "useVehicleFormContext cannot be used without VehicleFormContext"
    );
  }

  return context;
};

export const VehicleFormProvider = ({ children }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const defaultValidationSchemaObj = getValidationSchemaObj(t);

  const queryParams = new URLSearchParams(location.search);
  const actionType = queryParams.get("actionType");
  const offerId = queryParams.get("offerId");

  const isOpenOffer = actionType === OPEN_OFFER;
  const isCopyOffer = actionType === COPY_OFFER;

  const defaultView = isOpenOffer ? EViewMode.readOnlyMode : EViewMode.editMode;

  const [validationSchemaObj, setValidationSchemaObj] = useState(
    defaultValidationSchemaObj
  );

  const [viewMode, setViewMode] = useState(defaultView);

  const isReadOnlyMode = viewMode === EViewMode.readOnlyMode;

  const offerTabsAreReadonly = !!offerId && !isCopyOffer;

  const noOfferCreated = !offerId || isCopyOffer;

  const {
    data,
    isSuccess,
    isRefetching,
    isFetching,

    isLoading: isLoadingOfferData,
  } = useLoadOfferData(!isCopyOffer ? offerId : null);

  const {
    data: copyData,
    isLoading: isLoadingCopyOfferData,
    isSuccess: copyDataSuccess,
    isRefetching: copyDataRefetching,
    isFetching: copyDataFetching,
  } = useLoadCopyOfferData(offerId, isCopyOffer);

  const hasPolicy = data?.dataObj?.policyCascoId;

  const hasInvoice = data?.dataObj?.invoiceCascoId;

  const readOnlyData = formatDataForReadOnlyView(data?.dataObj, t);

  const noInvoiceCreated = !hasInvoice;

  const offerExist = offerConfig.some((row) => {
    if (!data?.dataObj) return false;
    return (
      data?.dataObj?.[row.totalPremiumField] !== "0 EUR" &&
      data?.dataObj?.[row.totalPremiumField] !== "0 RON"
    );
  });

  const tabConfig = getTabConfig({
    readOnlyData,
    offerTabsAreReadonly,
    noOffer: noOfferCreated || !offerExist,
    noOfferCreated,
    noInvoiceCreated,
    hasPolicy,
  });

  const [activeMainTab, setActiveMainTab] = useState(EMainTab.offer);

  const initSideTab = tabConfig[activeMainTab]?.sideTabs[0].value;

  const [activeSideTab, setActiveSideTab] = useState(initSideTab);

  const onSideTabSet = useCallback((value: ESidemenuTab) => {
    setActiveSideTab(value);
  }, []);

  const onNextSideTab = useCallback(() => {
    setActiveSideTab((prev) => (prev as number) + 1);
  }, []);

  const onPrevSideTab = useCallback(() => {
    setActiveSideTab((prev) => (prev as number) - 1);
  }, []);

  const onMainTabSet = useCallback((value: EMainTab) => {
    setActiveMainTab(value);
  }, []);

  const onSetViewMode = useCallback((value: EViewMode) => {
    setViewMode(value);
  }, []);

  const onUpdateValidationSchemaObj = useCallback(
    (
      fieldValidation: Record<string, AnySchema | Reference | Lazy<any, any>>
    ) => {
      setValidationSchemaObj((prev) => {
        return {
          ...prev,
          ...fieldValidation,
        };
      });
    },
    []
  );

  const offerDataLoading = isLoadingOfferData || isFetching || isRefetching;
  const copyOfferDataLoading =
    isLoadingCopyOfferData || copyDataFetching || copyDataRefetching;

  const value = useMemo(
    () => ({
      offerData: isCopyOffer ? copyData : data,
      isReadOnlyMode,
      onSideTabSet,
      activeMainTab,
      onMainTabSet,
      activeSideTab,
      onSetViewMode,
      viewMode,
      onNextSideTab,
      tabConfig,
      isLoadingOfferData: offerDataLoading || copyOfferDataLoading,
      offerDataLoadedSuccessfully: isSuccess || copyDataSuccess,
      onPrevSideTab,
      onUpdateValidationSchemaObj,
      validationSchemaObj,
    }),
    [
      isCopyOffer,
      copyData,
      data,
      isReadOnlyMode,
      onSideTabSet,
      activeMainTab,
      onMainTabSet,
      activeSideTab,
      onSetViewMode,
      viewMode,
      onNextSideTab,
      tabConfig,
      offerDataLoading,
      copyOfferDataLoading,
      isSuccess,
      copyDataSuccess,
      onPrevSideTab,
      onUpdateValidationSchemaObj,
      validationSchemaObj,
    ]
  );

  return (
    <VehicleFormContext.Provider value={value}>
      {children}
    </VehicleFormContext.Provider>
  );
};
