import React, {
  useMemo,
  useState,
  useCallback,
  createContext,
  useContext,
  useEffect,
} from "react";

import {
  defcolumns,
  hydrateColumn,
} from "pages/MainPage/VehicleList/columns/columns";
import { useTranslation } from "react-i18next";
import { ExpandedColumn, ISetting, IVehicleListParams } from "library/types";
import { useLoadOfferListData } from "hooks/data/queries/useLoadOfferListData";

interface IContext {
  params: IVehicleListParams;
  onResetParams: () => void;
  columns: ExpandedColumn[];
  onSetParams: (params: any) => void;
  onSetColumns: (columns: ExpandedColumn[]) => void;
  tempColumns: ExpandedColumn[];
  onSetTempColumns: (columns: ExpandedColumn[]) => void;

  ///

  selectedView: ISetting;
  onSetSelectedView: (sett: ISetting) => void;
  selectedViewColumns: ExpandedColumn[];
  setSelectedViewColumns: (columns: ExpandedColumn[]) => void;
  selectedViewId: string | number;
  onSetSelectedViewId: (value: string | number) => void;
}

type Props = {
  children: React.ReactNode;
};

export const MainContext = createContext<IContext | undefined>(undefined);

export const useMainContext = (): IContext => {
  const context = useContext(MainContext);

  if (!context) {
    throw new Error("useMainContext cannot be used without MainContext");
  }

  return context;
};

const defaultVehicleParams = {
  offerRequestType: "",
  sort: {
    key: "offerId",
    dir: "desc",
  },
  search: "",
  top: 40,
  skip: 0,
  showArchived: false,
};

export const MainProvider = ({ children }: Props) => {
  const { t } = useTranslation();

  const { data: dataObj, isSuccess: offerRequestParamLoaded } =
    useLoadOfferListData();

  const [params, setParams] = useState(defaultVehicleParams);
  const [tempColumns, setTempColumns] = useState(defcolumns);

  const [selectedView, setSelectedView] = useState(undefined);

  const [selectedViewColumns, setSelectedViewColumns] = useState(null);
  const [selectedViewId, setSelectedViewId] = useState(null);

  const [cols, setCols] = useState(defcolumns);

  const onSetParams = useCallback((params) => {
    return setParams((prev) => ({
      ...prev,
      ...params,
    }));
  }, []);

  const onSetColumns = useCallback((columns) => {
    return setCols(columns);
  }, []);
  const onSetTempColumns = useCallback((columns) => {
    return setTempColumns(columns);
  }, []);

  const onSetSelectedView = useCallback((view: ISetting) => {
    return setSelectedView(view);
  }, []);

  const onSetSelectedViewId = useCallback((viewId: string | number) => {
    return setSelectedViewId(viewId);
  }, []);

  const onResetParams = useCallback(() => {
    return setParams({
      ...defaultVehicleParams,
      offerRequestType: dataObj?.offerRequestType || "",
    });
  }, [dataObj]);

  const columns = useMemo(() => {
    const withTranslatedHeader = cols
      .filter((c) => c.isVisible)

      .map((column: any) => {
        let iconName = column.iconName;

        if (column.key === params.sort?.key && column.isSortable) {
          iconName =
            params.sort?.key === column.key
              ? params.sort!.dir === "asc"
                ? "SortUp"
                : "SortDown"
              : "Sort";
        }
        return {
          ...column,
          name: t(column.labelKey),
          iconName: iconName,
        };
      });
    return [...withTranslatedHeader];
  }, [params.sort, cols, t]);

  useEffect(() => {
    if (offerRequestParamLoaded) {
      setParams({
        ...defaultVehicleParams,
        offerRequestType: dataObj?.offerRequestType || "",
      });
    }
  }, [offerRequestParamLoaded, dataObj]);

  useEffect(() => {
    if (!selectedView) {
      setSelectedViewColumns(defcolumns);
      onSetTempColumns(defcolumns);
      return;
    }
    const columnsValue = JSON.parse(
      selectedView.userAppSettingValue
    ) as ExpandedColumn[];
    const mappedColumns0 = columnsValue.map((c) => {
      const c0Elem = columns.find((c0) => c0.key === c.key);

      return { ...c0Elem, ...c };
    });
    const mappedColumns = mappedColumns0.map((c) =>
      hydrateColumn({ column: c, defaultColumns: defcolumns })
    );
    setSelectedViewColumns(mappedColumns);
    onSetColumns(mappedColumns);
    onSetTempColumns(mappedColumns);
  }, [selectedView]);

  const value = useMemo(
    () => ({
      params,
      onSetParams,
      columns,
      onResetParams,
      onSetColumns,
      tempColumns,
      onSetTempColumns,
      selectedView,
      onSetSelectedView,
      selectedViewColumns,
      setSelectedViewColumns,
      selectedViewId,
      onSetSelectedViewId,
    }),
    [
      params,
      onSetParams,
      columns,
      onResetParams,
      onSetColumns,
      tempColumns,
      onSetTempColumns,
      selectedView,
      onSetSelectedView,
      selectedViewColumns,
      selectedViewId,
      onSetSelectedViewId,
    ]
  );

  return <MainContext.Provider value={value}>{children}</MainContext.Provider>;
};
