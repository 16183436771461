import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Spinner,
} from "@fluentui/react-components";
import { DeleteDismissFilled } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import { useDeleteOffer } from "hooks/data/mutations/useDeleteOffer";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { QUERY_KEYS } from "library/shared";
import { useMainContext } from "providers/MainProvider";

type Props = {
  isOpen: boolean;
  toggleDialogOpen: () => void;
  offerId: string;
};

function DeleteOfferDialog({ isOpen, toggleDialogOpen, offerId }: Props) {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { onResetParams } = useMainContext();

  const { mutate: deleteOffer, isLoading: deletingOfferInProgress } =
    useDeleteOffer();

  const onInvalidateOffer = async () => {
    deleteOffer(offerId, {
      onSuccess: () => {
        queryClient.removeQueries(QUERY_KEYS.vehicleList);
        onResetParams();
        toast.success(t("greco.success"));
        toggleDialogOpen();
      },
    });
  };
  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        toggleDialogOpen();
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t("bcm.invalidateOffer.label")}</DialogTitle>
          <DialogContent>
            {t("bcm.invalidateOfferMessage.label")} {` #${offerId}`}
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">{t("bcm.close.label")}</Button>
            </DialogTrigger>
            <Button
              appearance="primary"
              onClick={onInvalidateOffer}
              disabled={deletingOfferInProgress}
              icon={
                deletingOfferInProgress ? (
                  <Spinner size="tiny" />
                ) : (
                  <DeleteDismissFilled fontSize={16} />
                )
              }
            >
              {t("bcm.invalidateOffer.label")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

export default DeleteOfferDialog;
