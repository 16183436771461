import { Column, ColumnOptions as GrecoColumnOptions } from "@greco/components";
import { useLoadUADTaxonomy } from "hooks/data/queries/useLoadUADTaxonomy";
import { ExpandedColumn, ISetting } from "library/types";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { defcolumns } from "../VehicleList/columns/columns";
import { useDeleteAppSettings } from "hooks/data/mutations/useDeleteAppSettings";
import { useUpdateAppSettings } from "hooks/data/mutations/useUpdateAppSettings";
import { useAddAppSettings } from "hooks/data/mutations/useAddAppSettings";
import { useMainContext } from "providers/MainProvider";
import { useGetAppSettings } from "hooks/data/queries/useGetAppSettings";
import { TFunction } from "i18next";

const reorderColumn = (
  columns: ExpandedColumn[],
  index1: number,
  index2: number
) => {
  const clone = [...columns];
  clone.splice(index2, 0, clone.splice(index1, 1)[0]);
  return clone;
};

export const ColumnOptions = ({
  isOpen,
  closePanel,
}: {
  isOpen: boolean;
  closePanel: () => void;
}) => {
  const { t } = useTranslation();
  const {
    onSetColumns,
    tempColumns,
    onSetTempColumns,
    selectedViewColumns,
    selectedViewId,
    onSetSelectedViewId,
    selectedView,
    onSetSelectedView,
  } = useMainContext();
  const { data: taxonomyUad } = useLoadUADTaxonomy();

  const applicationCodeId = taxonomyUad?.ApplicationCode?.find(
    (el) => el.code === "Application.BCM"
  ).id;

  const { mutate: deleteSetting } = useDeleteAppSettings();

  const { mutate: updateSetting, isLoading: updateInProgress } =
    useUpdateAppSettings();

  const { mutate: createSetting, isLoading: createInProgress } =
    useAddAppSettings();

  const {
    data: appSettings,
    isSuccess: appSettingsLoaded,
    isLoading: settingsLoading,
    refetch: settingsRefetch,
  } = useGetAppSettings(applicationCodeId);

  const columnOptionsSettings = useMemo(() => {
    return appSettings.filter(
      (item) =>
        item.appSettingCodeId.toString() !== process.env.REACT_APP_USER_SETTINGS //language
    );
  }, [appSettings]);

  const areSettingsLoading =
    updateInProgress || createInProgress || settingsLoading;

  useEffect(() => {
    if (appSettingsLoaded) {
      const defaultView = columnOptionsSettings.find((s) => s.isDefault);
      if (defaultView) {
        onSetSelectedViewId(defaultView.userAppSettingId);
      }
    }
  }, [appSettingsLoaded, columnOptionsSettings]);

  useEffect(() => {
    if (appSettingsLoaded) {
      const selectedView = columnOptionsSettings?.find(
        (s) =>
          s.userAppSettingId === selectedViewId &&
          s.appSettingCodeId === 900000000010303
      );
      onSetSelectedView(selectedView);
    }
  }, [selectedViewId, columnOptionsSettings, appSettingsLoaded]);

  const appSettingCodeId = taxonomyUad?.ApplicationSetting?.find(
    (el) => el.code === "AppSetting.ColumnOptions"
  ).id;

  const transformedColumns = useMemo(() => {
    const transformedColumns = tempColumns?.map((c) => {
      if (c.key === "options") {
        return {
          ...c,
          label: t(c.labelKey),
          isPresentInColumnOptions: false,
        };
      }
      return {
        ...c,
        label: t(c.labelKey),
      };
    });

    const fixedColumn = transformedColumns?.filter((column) => column.isFixed);

    const unfixedTransformedColumns = transformedColumns?.filter(
      (column) => !column.isFixed
    );

    return fixedColumn?.concat(unfixedTransformedColumns);
  }, [tempColumns, t]);

  if (!isOpen) return null;

  return (
    <div>
      <GrecoColumnOptions
        t={t as any}
        columns={transformedColumns as Column[]}
        isReady={!areSettingsLoading}
        isViewLoading={areSettingsLoading}
        closePanel={closePanel}
        onChangeView={(id) => {
          onSetSelectedViewId(id);
        }}
        onClickApply={() => {
          const visibleColumns = tempColumns.map((c) =>
            c.key === "options" ? { ...c, isVisible: true } : c
          );
          onSetColumns(visibleColumns);
          closePanel();
        }}
        onClickClearAllFilters={() => {
          const visibleColumns = tempColumns.map((c) =>
            c.key === "options" ? { ...c, isVisible: true } : c
          );
          const value = visibleColumns.map((c) => ({
            ...c,
            filter: defcolumns.find((dc) => dc.key === c.key)?.filter,
          }));
          onSetTempColumns(value);
        }}
        onClickDeleteView={() => {
          deleteSetting(selectedView.userAppSettingId);
        }}
        onClickReset={() => {
          onSetTempColumns(selectedViewColumns);
        }}
        onClickSaveView={() => {
          const visibleColumns = tempColumns.map((c) =>
            c.key === "options" ? { ...c, isVisible: true } : c
          );
          updateSetting({
            ...selectedView,
            userAppSettingValue: JSON.stringify(visibleColumns),
          });
        }}
        onCreateView={(data) => {
          const visibleColumns = tempColumns.map((c) =>
            c.key === "options" ? { ...c, isVisible: true } : c
          );
          const newSetting = {
            applicationCodeId: applicationCodeId,
            appSettingCodeId: appSettingCodeId,
            userAppSettingValue: JSON.stringify(visibleColumns),
            ...data,
          } as ISetting;

          createSetting(newSetting, {
            onSuccess: (allSettings) => {
              const newSettingId = allSettings.data.find(
                (s) => s.userAppSettingName === newSetting.userAppSettingName
              )?.userAppSettingId;
              if (newSettingId) {
                onSetSelectedViewId(newSettingId);
              }
              settingsRefetch();
              closePanel();
            },
          });
        }}
        onUpdateView={(data) => {
          updateSetting(
            {
              ...selectedView,
              ...data,
            },
            {
              onSuccess: () => {
                closePanel();
                settingsRefetch();
              },
            }
          );
        }}
        onDeselectAll={() => {
          const visibleColumns = tempColumns.map((c) =>
            c.key === "options" ? { ...c, isVisible: true } : c
          );
          const value = visibleColumns.map((c) => ({
            ...c,
            isVisible: c.isFixed ? true : false,
          }));
          onSetTempColumns(value);
        }}
        onSelectAll={() => {
          const value = tempColumns.map((c) => ({
            ...c,
            isVisible: true,
          }));
          onSetTempColumns(value);
        }}
        onReorderColumn={(src, dest) => {
          const value = reorderColumn(tempColumns, src, dest);
          onSetTempColumns(value);
        }}
        onToggleColumnVisibility={(columnKey) => {
          const value = tempColumns.map((c) => {
            if (c.key === columnKey) {
              return {
                ...c,
                isVisible: !c.isVisible,
              };
            }
            return c;
          });
          onSetTempColumns(value);
        }}
        selectedView={selectedView}
        setFilter={(columnKey, filter) => {
          const visibleColumns = tempColumns.map((c) =>
            c.key === "options" ? { ...c, isVisible: true } : c
          );
          const value = visibleColumns.map((c) => {
            if (c.key === columnKey) {
              return {
                ...c,
                filter,
              };
            }
            return c;
          });
          onSetTempColumns(value as ExpandedColumn[]);
        }}
        taxonomy={{}}
        views={columnOptionsSettings}
      />
    </div>
  );
};
