import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQueryClient,
} from "react-query";
import api from "../../../service/api";
import axios from "axios";
import { ExpandedColumn, IVehicleListParams } from "library/types";
import { createAzureFilterParam } from "library/utils";
import { QUERY_KEYS } from "library/shared";

const cancelSource = axios.CancelToken.source();

export const getVehicleList = async (
  data: IVehicleListParams,
  columns: ExpandedColumn[]
) => {
  const { search, sort, top, skip, offerRequestType, showArchived } = data;

  let searchUrl = `/GetOfferList`;

  if (offerRequestType) {
    searchUrl += `?offerRequestType=${offerRequestType}`;
  }

  if (showArchived === true) {
    searchUrl += `&showArchived=${showArchived}`;
  }

  searchUrl += `&searchCriteria=${search?.replace(
    /&/g,
    encodeURIComponent("&")
  )}*`;

  if (sort && sort.key !== "" && sort.dir !== "") {
    searchUrl += `&$orderby=${sort.key} ${sort.dir}`;
  }
  searchUrl += `&form=json`;
  searchUrl += `&$count=true`;
  searchUrl += `&$top=${top}&$skip=${skip}`;

  let filterStr = createAzureFilterParam(columns as any);

  if (filterStr.length > 0) {
    searchUrl += `&$filter=${filterStr}`;
  }

  const res = await api.get(searchUrl, {
    cancelToken: cancelSource.token,
  });

  const skipValue = res.data["@odata.count"] === 1 ? 1 : data.skip;

  return {
    results: res,
    nextPage: skipValue,
    totalPages: res.data["@odata.count"],
  };
};

export function useGetVehicleList(
  data: IVehicleListParams,
  columns: ExpandedColumn[]
): UseInfiniteQueryResult<any> {
  return useInfiniteQuery(
    [
      QUERY_KEYS.vehicleList,
      data.offerRequestType,
      data.search,
      data.sort,
      data.showArchived,
      columns,
    ],
    () => getVehicleList(data, columns),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.nextPage < lastPage.totalPages) {
          return lastPage.nextPage + 1;
        }
        return undefined;
      },
      enabled: !!data.offerRequestType,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
}

export const useInvalidateVehicleList = () => {
  const queryClient = useQueryClient();

  queryClient.invalidateQueries({ queryKey: QUERY_KEYS.vehicleList });
};
