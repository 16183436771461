import { Skeleton, SkeletonItem } from "@fluentui/react-components";
import React from "react";
import { styled } from "styled-components";

function VehicleFormSkeleton() {
  return (
    <>
      <Nav>
        <SkeletonItem size={48} />
      </Nav>
      <Tabs>
        <SkeletonItem shape="rectangle" size={48} animation="wave" />
      </Tabs>
      <Content>
        <Skeleton>
          <SkeletonItem shape="rectangle" size={128} animation="wave" />
          <SkeletonItem shape="rectangle" size={128} animation="wave" />
          <SkeletonItem shape="rectangle" size={128} animation="wave" />
          <SkeletonItem shape="rectangle" size={128} animation="wave" />
          <SkeletonItem shape="rectangle" size={128} animation="wave" />
        </Skeleton>
      </Content>
    </>
  );
}

export default VehicleFormSkeleton;

const Nav = styled.div`
  width: 100%;
  margin-bottom: 50px;
`;

const Content = styled.div`
  width: 900px;
  margin: 50px auto;
`;

const Tabs = styled.div`
  width: 600px;
  margin: 50px auto;
`;
