import React, { useMemo } from "react";

const options = {
  leasing: "Contract de leasing financiar",
  credit: "Contract de credit",
};

function ContractType({ data }) {
  const content = useMemo(() => {
    if (!data) return "FN";
    if (data === options.credit) {
      return "CC";
    }
    if (data === options.leasing) {
      return "LO";
    }

    return "";
  }, [data]);

  return <div>{content}</div>;
}

export default ContractType;
