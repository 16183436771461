import { format, formatISO } from "date-fns";
import { TFunction } from "i18next";
import pdfMake from "pdfmake";
import moment from "moment";

import { ExpandedColumn, VehicleAzure } from "library/types";

export const debounce = (callback, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

export const transformVehiclesForExcel = ({
  vehicles,
  columns,
  t,
}: {
  vehicles: VehicleAzure[];
  columns: ExpandedColumn[];
  t: TFunction;
}) => {
  return vehicles.map((v) => {
    return columns
      .filter((c) => c.key !== "options")
      .reduce((transformedVehicle, c) => {
        if (c.excel && c.excel.render) {
          transformedVehicle[c.key] = c.excel.render({
            t,
            row: v,
          });
        }
        //
        else {
          transformedVehicle[c.key] = v[c.key];
        }

        return transformedVehicle;
      }, {});
  });
};

export const hydrateOffers = (newOffers: any) => {
  const columns = newOffers.map((row) => {
    return {
      ...row,
      inp_lessee_username:
        row.inp_lessee_type_ut === "Persoana fizica"
          ? row.inp_lessee_firstname + " " + row.inp_lessee_lastname
          : row.inp_lessee_company_name,
      inp_lessee_id:
        row.inp_lessee_type_ut === "Persoana fizica"
          ? row.inp_lessee_compreg
          : row.inp_lessee_uid,
      cascoInvoiceIssuedAt: formatDateString(row.cascoInvoiceIssuedAt),
      offerRequestedAt: formatDateString(row.offerRequestedAt),
      lastModifiedAt: formatDateString(row.lastModifiedAt),
      cascoPolicyIssuedAt: formatDateString(row.cascoPolicyIssuedAt),
      validFrom: formatDateString(row.validFrom),
      inp_beneficiar_ut: firstLetterUpper(row.inp_beneficiar_ut),
      invoiceCascoPremium: row?.invoiceCascoPremium?.toString(),
      policyCascoPremium: row?.policyCascoPremium?.toString(),
    };
  });

  return columns;
};

export const transformDataForExcel = ({
  data,
  columns,
  t,
}: {
  data: any[];
  columns: ExpandedColumn[];
  t: TFunction;
}) => {
  // const actualColumns = columns.filter(
  //   (c) => c.key !== "options"
  // );

  return data.map((v) => {
    return columns
      .filter((c) => c.key !== "options")
      .reduce((transformedData, c) => {
        if (c.excel && c.excel.render) {
          transformedData[c.key] = c.excel.render({
            t,
            row: v,
          });
        }
        //
        else {
          transformedData[c.key] = v[c.key];
        }

        return transformedData;
      }, {});
  });
};

export const formatDateString = (date: string) => {
  return date !== null && date !== ""
    ? format(new Date(date), "dd.MM.yyyy")
    : "";
};
export const formatFullDate = (date: string) => {
  return date !== null && date !== ""
    ? format(new Date(date), "dd.MM.yyyy hh:mm:ss")
    : "";
};

export const formatDateWithTime = (date?: Date) =>
  format(date, "yyyy_MM_dd_kk_mm_ss");

export const createPDFOffers = ({
  result,
  columns,
  t,
}: {
  result: any;
  columns: ExpandedColumn[];
  t: TFunction;
}) => {
  const actualColumns = columns.filter((c) => c.key !== "options");

  const docDefinition = {
    pageOrientation: "landscape",
    pageMargins: [20, 20, 20, 20],
    pageSize: {
      width:
        actualColumns.reduce((sum, c) => {
          if (c.pdf && c.pdf?.width !== undefined) {
            return sum + c.pdf.width;
          }
          return sum + (c?.width || c?.minWidth || c?.maxWidth);
        }, 0) * 1.1,
      height: 1200,
    },
    info: {
      title: `Oferta ${formatDateWithTime(new Date())}`,
    },
    content: [
      {
        table: {
          headerRows: 1,
          widths: actualColumns.map((c) => {
            if (c.pdf && c.pdf.width !== undefined) {
              return c.pdf.width;
            }
            return c?.width || c?.minWidth || c?.maxWidth;
          }),
          body: [
            actualColumns.map((c) => {
              if (c.pdf && c.pdf.label !== undefined) {
                return {
                  text: c.pdf.label,
                  style: "headerCell",
                };
              }
              return {
                text: t(c.labelKey),
                style: "headerCell",
              };
            }),
          ].concat(
            result.map((r) => {
              return actualColumns.map((c) => {
                const value = r[c.key];
                if (c.pdf && c.pdf.render) {
                  try {
                    return {
                      style: "bodyCell",
                      ...c.pdf.render({
                        offer: r,
                        // taxonomy,
                        t: t,
                      }),
                    };
                  } catch (err) {
                    console.log(err);
                  }
                }
                if (value === undefined || value === null) return "";
                return {
                  text: value,
                  style: "bodyCell",
                };
              });
            })
          ),
        },
      },
    ],
    styles: {
      headerCell: {
        fillColor: "#005aa1",
        color: "#fff",
        bold: true,
        alignment: "center",
      },
      bodyCell: {
        alignment: "center",
      },
    },
    defaultStyle: {
      font: "SegoeUI",
    },
  };
  return pdfMake.createPdf(docDefinition);
};

const isFilteredNumber = function isFilteredNumber(f) {
  if (f.operator === "between") {
    return (
      f.number1 !== null &&
      f.number2 !== null &&
      f.number1 !== undefined &&
      f.number2 !== undefined &&
      f.number1 !== "" &&
      f.number2 !== ""
    );
  }

  return f.number1 !== null && f.number1 !== undefined && f.number1 !== "";
};

const isFilteredText = function isFilteredText(f) {
  return f.value.length > 0;
};

const isFilteredTaxonomy = function isFilteredTaxonomy(f) {
  return f.value.length > 0;
};
const isFilteredDate = function isFilteredDate(f) {
  if (f.operator === "isBetween") {
    return !!f.date1 && !!f.date2;
  }

  return !!f.date1;
};
const isFilteredByType = {
  number: isFilteredNumber,
  text: isFilteredText,
  date: isFilteredDate,
  taxonomy: isFilteredTaxonomy,
  boolean: function boolean(f) {
    return typeof f.unfilteredValue === "undefined"
      ? f.value !== null
      : f.value !== f.unfilteredValue;
  },
};

const isColumnFiltered = function isColumnFiltered(f) {
  if (!f) return false;
  return isFilteredByType[f.type](f);
};

export const createAzureFilterParam = function createAzureFilterParam(columns) {
  var columnFilterStrings = columns.reduce(function (f, c) {
    if (c.filter) {
      if (c.filter.type === "taxonomy" && isColumnFiltered(c.filter)) {
        f.push("search.in(" + c.key + ", '" + c.filter.value.join(",") + "')");
      } else if (c.filter.type === "boolean" && isColumnFiltered(c.filter)) {
        f.push(c.key + " eq " + c.filter.value);
      } else if (c.filter.type === "text" && isColumnFiltered(c.filter)) {
        f.push("search.ismatch('" + c.filter.value + "', '" + c.key + "')");
      } else if (c.filter.type === "date" && isColumnFiltered(c.filter)) {
        if (c.filter.operator === "isAfter") {
          f.push(
            c.key +
              " gt " +
              formatISO(new Date(c.filter.date1), {
                representation: "date",
              })
          );
        } else if (c.filter.operator === "isBefore") {
          f.push(
            c.key +
              " lt " +
              formatISO(new Date(c.filter.date1), {
                representation: "date",
              })
          );
        } else if (c.filter.operator === "isExact") {
          f.push(
            `${c.key} ge ${c.filter.date1.toISOString()} and ${
              c.key
            } lt ${moment(c.filter.date1).add(24, "hour").toISOString()}`
          );
        } else if (c.filter.operator === "isBetween") {
          f.push(
            c.key +
              " ge " +
              formatISO(new Date(c.filter.date1), {
                representation: "date",
              }) +
              " and " +
              c.key +
              " le " +
              formatISO(new Date(c.filter.date2), {
                representation: "date",
              })
          );
        }
      } else if (c.filter.type === "number" && isColumnFiltered(c.filter)) {
        var operatorStringNumber1 = c.filter.operator;
        var operatorStringNumber2 = c.filter.operator;

        if (c.filter.operator === "gt" && c.filter.includeNumber1) {
          operatorStringNumber1 = "ge";
        } else if (c.filter.operator === "lt" && c.filter.includeNumber1) {
          operatorStringNumber1 = "le";
        } else if (c.filter.operator === "between") {
          if (c.filter.includeNumber1) {
            operatorStringNumber1 = "ge";
          } else {
            operatorStringNumber1 = "gt";
          }

          if (c.filter.includeNumber2) {
            operatorStringNumber2 = "le";
          } else {
            operatorStringNumber2 = "lt";
          }
        }

        if (c.filter.operator === "between") {
          f.push(
            c.key +
              " " +
              operatorStringNumber1 +
              " " +
              c.filter.number1
                .replaceAll(" ", "")
                .replaceAll(".", "")
                .replaceAll(",", ".") +
              " and " +
              c.key +
              " " +
              operatorStringNumber2 +
              " " +
              c.filter.number2
                .replaceAll(" ", "")
                .replaceAll(".", "")
                .replaceAll(",", ".")
          );
        } else {
          f.push(
            c.key +
              " " +
              operatorStringNumber1 +
              " " +
              c.filter.number1
                .replaceAll(" ", "")
                .replaceAll(".", "")
                .replaceAll(",", ".")
          );
        }
      }
    }

    return f;
  }, []);
  return []
    .concat(columnFilterStrings)
    .filter(function (s) {
      return !!s;
    })
    .join(" and ");
};

export const firstLetterUpper = (string: string) => {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatNumber = (n: number, minPrecision = 2, maxPrecision = 2) => {
  if (!n) return null;
  return n.toLocaleString("de-DE", {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: maxPrecision,
  });
};

export const getLastNYearsOptions = (n: number) => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = 0; i < 14; i++) {
    const val = currentYear - i;
    years.push({ label: val, value: val });
  }

  return years;
};

export const getTaxonomyValue = (
  options: {
    value: string | number;
    label: string;
  }[],
  fieldValue: string,
  t: any
) => t(options?.find((option) => option.value === fieldValue)?.label);

export const urlFormat = (url: string) => {
  if (!url) return "";

  let result = url;
  if (url.charAt(url.length - 1) === "/") {
    result = url.slice(0, -1);
  }
  return result;
};

export const openLinkInNewTab = (path: string) => {
  return window.open(path);
};
