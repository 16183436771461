import { useMemo } from "react";

const maxPolicyValue1 = 300_000;
const maxPolicyValue2 = 200_000;

function usePolicyValueValidation(values) {
  const insurer = values?.inp_beneficiar_ut;

  const isAllianzInsurer = insurer === "allianz";
  const isGroupamaInsurer = insurer === "groupama";
  const isAsiromInsurer = insurer === "asirom";

  const policyValue = values?.inp_policy_value_ut;

  const isRonPolicyCurrency = values?.inp_policy_currency_ut === "RON";

  const eurExchangeRate = values?.EURExchangeRate || values?.eurExchangeRate;

  const maxPolicyValue = useMemo(() => {
    let maxValue =
      isAsiromInsurer || isGroupamaInsurer ? maxPolicyValue1 : maxPolicyValue2;

    if (isRonPolicyCurrency) {
      maxValue *= eurExchangeRate;
    }

    return maxValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAsiromInsurer,
    isGroupamaInsurer,
    isRonPolicyCurrency,
    eurExchangeRate,
  ]);

  const maxPolicyValueCorrect = useMemo(() => {
    return policyValue <= maxPolicyValue ? true : false;
  }, [maxPolicyValue, policyValue]);

  return {
    maxPolicyValue,
    maxPolicyValueCorrect,
    isAllianzInsurer,
    insurer,
  };
}

export default usePolicyValueValidation;
