import {
  ConstrainMode,
  DetailsListLayoutMode,
  IColumn,
  IDetailsColumnRenderTooltipProps,
  IDetailsHeaderProps,
  IRenderFunction,
  MarqueeSelection,
  Modal,
  ScrollablePane,
  SelectAllVisibility,
  Selection,
  SelectionMode,
  Sticky,
  StickyPositionType,
  TooltipHost,
} from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";

import { ShimmeredDetailsList } from "@fluentui/react";
import {
  CheckboxVisibility,
  DetailsRow,
  IDetailsRowStyles,
} from "office-ui-fabric-react";

import { useGetVehicleList } from "hooks/data/queries/useGetVehicleList";
import { OPEN_OFFER, ROUTES } from "library/shared";
import { IVehicleListItem } from "library/types";
import { useMainContext } from "providers/MainProvider";
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import RightClickContextMenu from "./RightClickContextMenu";
import { getClassNames } from "./helpers";

export const VehicleList = () => {
  const { params, onSetParams, columns } = useMainContext();
  const [contextualMenuState, setContextualMenuState] = useState({
    offer: null,
    target: null,
    open: false,
  });

  const [onScrollFetch, setOnScrollFetch] = useState(false);
  const navigate = useNavigate();
  const sort = params.sort;

  const {
    data: vehicleData,
    isLoading: isLoadingVehicleData,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetVehicleList(params, columns);

  const count = vehicleData?.pages[0].results.data["@odata.count"];

  const theme = useTheme() as IStyledTheme;

  useEffect(() => {
    if (
      isFetching ||
      isFetchingNextPage ||
      isLoadingVehicleData ||
      endReached ||
      !onScrollFetch
    )
      return;
    setOnScrollFetch(false);
    onSetParams({
      skip: params.skip + 40,
    });
  }, [isFetching, isFetchingNextPage, isLoadingVehicleData, onScrollFetch]);

  useEffect(() => {
    if (
      isFetching ||
      isFetchingNextPage ||
      isLoadingVehicleData ||
      endReached ||
      onScrollFetch ||
      !hasNextPage
    )
      return;

    fetchNextPage();
  }, [params.skip]);

  const items: IVehicleListItem[] =
    vehicleData?.pages.flatMap((page) => page.results.data.value) || [];

  const onRenderColumnHeaderTooltip: IRenderFunction<
    IDetailsColumnRenderTooltipProps
  > = (tooltipHostProps) => {
    return (
      <TooltipHost
        {...tooltipHostProps}
        content={tooltipHostProps?.column?.name}
      />
    );
  };

  const onRenderDetailsHeader = (
    props: IDetailsHeaderProps | undefined,
    defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
  ) => (
    <Sticky
      stickyPosition={StickyPositionType.Header}
      isScrollSynced
      stickyClassName={classes.table}
    >
      <>
        {defaultRender!({
          ...props!,
          selectAllVisibility: SelectAllVisibility.hidden,
          onRenderColumnHeaderTooltip,
        })}
      </>
    </Sticky>
  );

  const onItemInvoked = (vehicle: IVehicleListItem) => {
    const hasInvoice = vehicle?.status.includes("Decont");
    const path = ROUTES.getVehicleItemPath({
      offerId: vehicle.offerId,
      actionType: OPEN_OFFER,
      ...(hasInvoice && { hasInvoice }),
    });
    navigate(path);
  };

  const onCloseContextualMenu = useCallback(() => {
    setContextualMenuState((prev) => ({
      ...prev,
      open: false,
    }));
  }, []);

  const endReached = items?.length >= count;

  const selection = new Selection({
    onSelectionChanged: () => {},
    getKey: (item: any) => _getKey(item),
  });

  const _getKey = (item: any, index?: number): string => {
    return item && item.offerId;
  };

  const onRenderItemColumn = (
    item: any,
    index: any,
    column: any
  ): JSX.Element | string | number => {
    return item[column.key];
  };

  const onClickHeaderCell = (column) => {
    if (!column.isSortable) return;
    const isSorted = sort?.key === column.key;

    if (isSorted) {
      if (sort?.dir === "asc") {
        onSetParams({
          sort: {
            key: column.key,
            dir: "desc",
          },
          skip: 0,
        });
      } else {
        onSetParams({
          sort: {
            key: "",
            dir: "desc",
          },
          skip: 0,
        });
      }
    }
    //
    else {
      onSetParams({
        sort: {
          key: column.key,
          dir: "asc",
        },
        skip: 0,
      });
    }
  };

  const classes = getClassNames(theme);

  const onRenderRow = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      const row = props.item;

      if (!row) return undefined;
      const isArchived = row.isArchived;

      return (
        <DetailsRow
          {...props}
          styles={{
            ...customStyles,
            root: {
              ...(isArchived && {
                background: `${theme.palette.neutralQuaternary} !important`,
              }),
            },
          }}
        />
      );
    }
    return null;
  };

  // console.log(items, "items here");

  return (
    <>
      <Container id="scrollableDiv">
        <ScrollablePane
          onScroll={(ev: any) => {
            if (
              onScrollFetch ||
              isFetching ||
              isFetchingNextPage ||
              isLoadingVehicleData ||
              endReached ||
              !hasNextPage
            )
              return;

            const element = ev.target;
            if (element) {
              let remaningPixels =
                element.scrollHeight -
                  element.scrollTop -
                  element.clientHeight <=
                150;

              if (remaningPixels && hasNextPage) {
                setOnScrollFetch(true);
              }
            }
          }}
          styles={{
            contentContainer: {
              overflowY: "scroll",
            },
          }}
        >
          {/* <SearchResultsCount count={count} label={"Search results:"} /> */}
          <MarqueeSelection selection={selection}>
            <ShimmeredDetailsList
              items={items}
              compact={false}
              columns={(columns as IColumn[]) || []}
              selectionMode={SelectionMode.single}
              checkboxVisibility={CheckboxVisibility.onHover}
              setKey="multiple"
              getKey={_getKey}
              onItemContextMenu={(item?: any, index?: number, ev?: Event) => {
                setContextualMenuState({
                  offer: item,
                  target: ev.target,
                  open: true,
                });
              }}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              onRenderItemColumn={onRenderItemColumn}
              onColumnHeaderClick={(e: any, column) => {
                onClickHeaderCell(column);
              }}
              onItemInvoked={onItemInvoked}
              className={classes.table}
              onRenderRow={onRenderRow}
              // onActiveItemChanged={_onActiveItemChanged}
              selection={selection}
              enableShimmer={isLoadingVehicleData}
              shimmerLines={20}
              onRenderDetailsHeader={(
                props: IDetailsHeaderProps | undefined,
                defaultRender: IRenderFunction<IDetailsHeaderProps> | undefined
              ) => onRenderDetailsHeader(props, defaultRender)}
              selectionPreservedOnEmptyClick={false}
              enterModalSelectionOnTouch={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
              constrainMode={ConstrainMode.unconstrained}
            />
            <RightClickContextMenu
              contextualMenu={contextualMenuState}
              closeContextualMenu={onCloseContextualMenu}
            />
          </MarqueeSelection>
        </ScrollablePane>
      </Container>

      <Modal //prevent scrolling on loading data
        styles={{
          root: {
            opacity: 0,
          },
        }}
        isOpen={isLoadingVehicleData}
        isBlocking={isLoadingVehicleData}
      ></Modal>
    </>
  );
};

const Container = styled.div`
  height: calc(100vh - 100px);
  position: relative;
  overflow: auto;
  width: 100%;
`;

export default VehicleList;
