import React from "react";
import { useTranslation } from "react-i18next";
import { Circle16Filled } from "@fluentui/react-icons";

import styled from "styled-components";
import { Spinner } from "@fluentui/react-components";

type Props = {
  score: number;
};

export const ResultsScore = ({ score }: Props) => {
  const { t } = useTranslation();

  const scoreIsNumber = typeof score === "number";
  return (
    <Container>
      <StyledIcon />
      <ResultsLabel>{t("greco.searchResults")}</ResultsLabel>
      {scoreIsNumber ? (
        <ResultNumber>{score}</ResultNumber>
      ) : (
        <Spinner size="tiny" />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ResultsLabel = styled.div`
  font-size: 14px;
  margin-right: 5px;
  color: ${(p) => p.theme.palette.neutralPrimary};

  @media (max-width: 800px) {
    display: none;
  }
`;

const ResultNumber = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.palette.neutralPrimary};
`;

const StyledIcon = styled(Circle16Filled)`
  font-size: 8px;
  color: ${(p) => p.theme.palette.themePrimary};
  margin-right: 8px;
`;
