import * as React from "react";
import { useFormContext } from "react-hook-form";
import { styled, useTheme } from "styled-components";
import { ErrorMessage } from "@hookform/error-message";
import { StyledErrorMessage } from "components/styled";
import { useTranslation } from "react-i18next";
import { tokens } from "@fluentui/react-theme";

import Select, {
  GroupBase,
  OptionsOrGroups,
  PropsValue,
  components,
} from "react-select";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import { IStyledTheme } from "theme/types";

type OptionType = {
  value: string;
  label: string;
};

type Props = {
  name: string;
  value: string;
  label: string;
  required?: boolean;
  options: OptionType[];
  disabled?: boolean;
};

export const ComboboxField = ({
  name,
  value,
  options,
  label,
  required = false,
  disabled,
}: Props) => {
  const theme = useTheme() as IStyledTheme;

  const { t } = useTranslation();
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const onChange = (option) => {
    setValue(name, option?.value);
  };

  const hasError = errors[name];

  const fieldValue = React.useMemo(() => {
    return options.find((item) => item.value === value) || "";
  }, [value]);

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div data-name={name}>{props.label}</div>
      </components.Option>
    );
  };

  return (
    <Container>
      <FormFieldLabel label={label} required={required} />
      <Select
        className="basic-single"
        classNamePrefix="select"
        menuPlacement="auto"
        menuPosition="fixed"
        // defaultValue={options[0]}
        components={{ Option }}
        styles={{
          container: (baseStyles, state) => ({
            ...baseStyles,
            position: "relative",
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            background: "transparent",
            border: "none",
            borderRadius: "0",
            height: "32px !important",
            minHeight: "32px !important",
            borderBottom: hasError
              ? `2px solid rgb(164, 38, 44) !important`
              : state.isFocused
              ? `2px solid rgb(0, 90, 161) !important`
              : `1px solid ${tokens.colorNeutralStrokeAccessible} !important`,
            boxShadow: "none",
            ...(state.isDisabled && {
              borderBottom: `1px solid ${tokens.colorNeutralStrokeAccessible} !important`,
            }),
          }),
          menu: (p) => ({
            ...p,
            background: theme.palette.neutralLighter,
          }),
          option: (p, state) => ({
            ...p,
            background:
              state.isSelected || state.isFocused
                ? theme.palette.neutralLighter
                : theme.palette.white,
            "&:hover": {
              background: theme.palette.neutralLighter,
            },
            color: theme.palette.black,
            fontSize: "14px",
            fontWeight: 400,
          }),
          input: (p) => ({
            ...p,
            margin: "4px",
            color: (function () {
              if (disabled) {
                return theme.palette.neutralSecondaryAlt;
              }

              return theme.palette.black;
            })(),
          }),
          valueContainer: (p, state) => ({
            ...p,
            padding: "1px 8px !important",
          }),
          placeholder: (p, state) => ({
            ...p,
            padding: "1px 8px !important",
          }),
          singleValue: (p, state) => ({
            ...p,
            fontSize: "14px",
            marginLeft: "5px",
            fontWeight: 400,
            color: theme.palette.black,
          }),
          dropdownIndicator: (p, state) => ({
            ...p,
            ...(state.isDisabled && { visibility: "hidden" }),
          }),

          indicatorSeparator: (p, state) => ({
            ...p,
            visibility: "hidden",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            overflow: "visible",
          }),
        }}
        value={fieldValue as PropsValue<OptionType>}
        isDisabled={disabled}
        onChange={onChange}
        // isLoading={isLoading}
        isClearable={true}
        isSearchable={true}
        name={name}
        options={options as OptionsOrGroups<OptionType, GroupBase<OptionType>>}
        getOptionLabel={(option: OptionType) => {
          return t(option?.label);
        }}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <StyledErrorMessage>{message}</StyledErrorMessage>
        )}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
`;
