import RequiredAsterisk from "components/RequiredAsterisk/RequiredAsterisk";
import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";

type Props = {
  label: string;
  required: boolean;
  disabled?: boolean;
};
function FormFieldLabel({ label, required, disabled }: Props) {
  const { t } = useTranslation();
  return (
    <Label disabled={disabled}>
      {t(label)}
      {required && <RequiredAsterisk />}
    </Label>
  );
}

export default FormFieldLabel;

const Label = styled.div<{ disabled?: boolean }>`
  margin-bottom: 5px;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
`;
