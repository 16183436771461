import React from "react";

import {
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Button,
  Dialog,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

import { ICascoCalculation } from "library/types";
import OfferPreview from "pages/VehicleItem/OfferPreview/OfferPreview";

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  calculationData: ICascoCalculation;
};

function CalculationDialog({ open, setOpen, calculationData }: Props) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onOpenChange={(event, data) => {
        setOpen(data.open);
      }}
    >
      <DialogSurface
        style={{
          minWidth: "700px",
        }}
      >
        <DialogBody>
          <DialogTitle>{t("bcm.calculationInfo.label")}</DialogTitle>
          <DialogContent>
            <OfferPreview infoCalculationData={calculationData} />
          </DialogContent>

          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Close</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

export default CalculationDialog;
