import { ColumnOptionsButton, ColumnOptionsUtils } from "@greco/components";

import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Flex, RightSection, StyledNavigationBar } from "components/styled";

import { Search } from "./Search";
import { ResultsScore } from "components/ResultsScore/ResultsScore";
import { RefreshButton } from "./RefreshButton";
import { ExportExcel } from "./ExportExcel/ExportExcel";
import { useGetVehicleList } from "hooks/data/queries/useGetVehicleList";
import { useMainContext } from "providers/MainProvider";
import { ColumnOptions } from "./ColumnOptions";
import { ExportPDF } from "./ExportPdf";
import { Button } from "@fluentui/react-components";
import { Add16Regular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import { CREATE_OFFER, ROUTES } from "library/shared";
import ShowArchived from "./ShowArchived";
import { useLoadOfferListData } from "hooks/data/queries/useLoadOfferListData";

const MainPageSubheader = () => {
  const refExcelExport = useRef();
  const navigate = useNavigate();

  const { params, columns } = useMainContext();
  const { data: vehicleData, isLoading } = useGetVehicleList(params, columns);
  const count = vehicleData?.pages[0].results.data["@odata.count"];

  const { data: appInfoData } = useLoadOfferListData();

  const isAdminOrManager = appInfoData?.isAdminOrManager;

  const [isColumnOptionsOpen, setIsColumnOptionsOpen] = useState(false);
  const { t } = useTranslation();

  const areAnyColumnsFiltered = columns.some((c) =>
    ColumnOptionsUtils.isColumnFiltered(c.filter)
  );

  return (
    <>
      <StyledNavigationBar>
        <RefreshButton />
        <Search />
        <Button
          disabled={isLoading}
          appearance="subtle"
          icon={<Add16Regular />}
          onClick={() => {
            navigate(
              ROUTES.getVehicleItemPath({
                offerId: null,
                actionType: CREATE_OFFER,
              })
            );
          }}
        >
          {t("bcm.newOffer.label")}
        </Button>

        <ExportExcel refExcelExport={refExcelExport} disabled={isLoading} />

        <ExportPDF disabled={isLoading} />

        {isAdminOrManager && <ShowArchived />}

        <RightSection>
          <Flex>
            <ColumnOptionsButton
              disabled={isLoading}
              t={t as any}
              isActive={areAnyColumnsFiltered}
              onClick={() => {
                setIsColumnOptionsOpen(true);
              }}
            />
            <ColumnOptions
              isOpen={isColumnOptionsOpen}
              closePanel={() => {
                setIsColumnOptionsOpen(false);
              }}
            />
            <ResultsScore score={count} />
          </Flex>
        </RightSection>
      </StyledNavigationBar>
    </>
  );
};

export default MainPageSubheader;
