import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import { QUERY_KEYS, notificationTypes } from "library/shared";
import api from "service/api";
import { errorHandler } from "components/AppHeader/NotificationMessages";
import { IVehicle } from "library/types";

const loadOfferData = async (offerId: string) => {
  const response = await api.post(`/LoadOfferData`, {
    appInfo: {
      dataObj: {
        offerId,
      },
      resolvedProperties: {
        title: "BCR Heavy Machinery",
      },
    },
  });

  return response.data?.appInfo;
};

export function useLoadOfferData(offerId): UseQueryResult<IVehicle> {
  return useQuery<IVehicle, Error>(
    [QUERY_KEYS.offerDataItem, offerId],
    () => loadOfferData(offerId),
    {
      enabled: !!offerId,
      onError: (error: any) => {
        errorHandler({
          label: "Error",
          type: notificationTypes.error,
          value: "Error while loading offer item data",
          errors: error?.message,
          fieldName: "",
        });
      },
      staleTime: 1000,
    }
  );
}

export const useInvalidateOfferData = () => {
  const queryClient = useQueryClient();

  queryClient.invalidateQueries({ queryKey: QUERY_KEYS.offerDataItem });
};
