import { ComboboxField } from "components/controls/ComboBoxField";
import InputField from "components/controls/InputField";

import { offerConfig } from "pages/VehicleItem/OfferPreview/config";
import { FieldsContainer } from "pages/VehicleItem/styled";
import { useVehicleFormContext } from "providers/VehicleFormProvider";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import yup from "validation";

function InvoiceForm() {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const { onUpdateValidationSchemaObj, offerData } = useVehicleFormContext();
  const values = watch();

  useEffect(() => {
    onUpdateValidationSchemaObj({
      inp_beneficiar_ut: yup.mixed().required(),
      inp_policy_leasingctrno_ut: yup
        .string()
        .matches(
          /^[a-zA-Z0-9][0-9]*$/,
          t("bcm.contractLeasingNumberValidation.label")
        ),
    });
  }, []);

  const insurerOptions = offerConfig
    .filter((row) => {
      return (
        offerData?.dataObj[row.totalPremiumField] !== "0 EUR" &&
        offerData?.dataObj[row.totalPremiumField] !== "0 RON"
      );
    })
    .map((item) => ({
      label: item?.name,
      value: item?.name.toLowerCase(),
    }));

  return (
    <FieldsContainer>
      <ComboboxField
        required
        name="inp_beneficiar_ut"
        label="bcm.insurer.label"
        value={values?.inp_beneficiar_ut}
        options={insurerOptions}
      />
      <InputField
        name="inp_policy_leasingctrno_ut"
        value={values?.inp_policy_leasingctrno_ut}
        required
        label="bcm.contractLeasingNumber.label"
      />
    </FieldsContainer>
  );
}

export default InvoiceForm;
