export enum EMainTab {
  invoice = "invoice",
  offer = "offer",
  policy = "policy",
}

export enum ESidemenuTab {
  general = 0,
  vehicle = 1,
  insurerSettings = 2,
  client = 3,
  cascoOffer = 4,
}

export enum EViewMode {
  readOnlyMode = "readOnlyMode",
  editMode = "editMode",
}
