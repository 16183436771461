import { useQuery, UseQueryResult } from "react-query";
import api from "../../../service/api";
import { notificationTypes, QUERY_KEYS } from "library/shared";
import { errorHandler } from "components/AppHeader/NotificationMessages";

const getOfferListData = async () => {
  const response = await api.post(`/LoadOfferListData`, {
    appInfo: {
      dataObj: {},
    },
  });

  return response.data?.appInfo?.dataObj;
};

export function useLoadOfferListData(): UseQueryResult<{
  offerRequestType: string;
  showDMSService: boolean;
  isAdminOrManager: boolean;
}> {
  return useQuery([QUERY_KEYS.offerListData], getOfferListData, {
    enabled: true,
    onError: (error: any) => {
      errorHandler({
        label: "Error",
        type: notificationTypes.error,
        value: "Error while loading offer list data",
        errors: error?.message,
        fieldName: "",
      });
    },
  });
}
