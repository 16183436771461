import { STORAGE_KEYS } from "library/shared";
import { LocalStorage } from "service/storage";

export const getHeaderConfig = (appName: string) => ({
  application_name: appName,
  search_visible: false,
  notifications_visible: true,
  help_visible: false,
  settings_visible: true,

  title_visible: true,
  darkMode: LocalStorage.get(STORAGE_KEYS.darkMode) === "true" ? true : false,
  tooltipsStatus:
    LocalStorage.get(STORAGE_KEYS.darkMode) === "true" ? true : false,
  settings_language_settings: true,
  app_launcher_visible: false,
  isMobile: false,
});
