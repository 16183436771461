export const asiromMachineGroupOptions = [
  {
    label: "bcm.earthworksEquipment.label",
    value: "Utilaje pentru terasamente",
  },
  {
    label: "bcm.miningIndustryEquipment.label",
    value: "Utilaje din industria miniera/ utilaje de sapat tunele",
  },
  {
    label: "bcm.tunnelDigingMachines.label",
    value: "Utilaje de sapat galerii (scuturi) cu:",
  },
  {
    label: "bcm.ballastAndConcreteInstallations.label",
    value:
      "Instalatii de balastiera si instalatii pentru prepararea betoanelor",
  },
  {
    label: "bcm.roadAndRailwayEquipment.label",
    value: "Utilaje de drumuri si cai ferate",
  },
  {
    label: "bcm.cranesLiftingAndTransportingSystems.label",
    value: "Macarale, sisteme de ridicat si transportat",
  },
  {
    label: "bcm.specialEquipment.label",
    value: "Utilaje speciale",
  },
  {
    label: "bcm.agriculturalMachinery.label",
    value: "Utilaje agricole",
  },
  {
    label: "bcm.utilityVehicles.label",
    value: "Autovehicule utilitare si pentru servicii publice",
  },
];

export const asiromMachineTypesAllOptions = {
  "Utilaje pentru terasamente": [
    {
      label: "bcm.earthworksEquipmentOption1.label",
      value: "Excavatoare, screpere, utilaje de sapat, dumpere",
    },
    {
      label: "bcm.earthworksEquipmentOption2.label",
      value: "Incarcatoare, buldozere",
    },
    {
      label: "bcm.earthworksEquipmentOption3.label",
      value: "Buldoexcavatoare",
    },
    {
      label: "bcm.earthworksEquipmentOption4.label",
      value: "Dragline pe pneuri sau senile",
    },
    {
      label: "bcm.earthworksEquipmentOption5.label",
      value: "Exacavatoare rotative, gredere, alte utilaje pentru terasamente",
    },
    {
      label: "bcm.earthworksEquipmentOption6.label",
      value: "Dragi",
    },
  ],
  "Utilaje din industria miniera/ utilaje de sapat tunele": [
    {
      label: "bcm.miningIndustryEquipmentOption1.label",
      value: "Excavatoare, incarcatoare",
    },
    {
      label: "bcm.miningIndustryEquipmentOption2.label",
      value: "Locomotive si vagoane de mina",
    },
  ],
  "Utilaje de sapat galerii (scuturi) cu:": [
    {
      label: "bcm.tunnelDigingMachinesOption1.label",
      value: "diametrul capului de sapare de pana 2,5 m",
    },
    {
      label: "bcm.tunnelDigingMachinesOption2.label",
      value: "dimetrul capului de sapare de pana la 6 m",
    },
    {
      label: "bcm.tunnelDigingMachinesOption3.label",
      value: "diametrul capului de sapare de pana la 16 m",
    },
    {
      label: "bcm.tunnelDigingMachinesOption4.label",
      value:
        "Conveiere (benzi transportatoare), lifturi, instalatii de ventilare si pompare",
    },
    {
      label: "bcm.tunnelDigingMachinesOption5.label",
      value: "Alte utilaje din industria miniera / utilaje de sapat tunele",
    },
  ],
  "Instalatii de balastiera si instalatii pentru prepararea betoanelor": [
    {
      label: "bcm.ballastAndConcreteInstallationsOption1.label",
      value: "Concasoare, concasoare mobile, zdrobitoare, mori",
    },
    {
      label: "bcm.ballastAndConcreteInstallationsOption2.label",
      value: "Mixer de beton autoincarcator",
    },
    {
      label: "bcm.ballastAndConcreteInstallationsOption3.label",
      value:
        "Instalatii de sortare si spalare, benzi transportatoare, malaxoare",
    },
    {
      label: "bcm.ballastAndConcreteInstallationsOption4.label",
      value: "Silozuri, instalatii de depozitare",
    },
    {
      label: "bcm.ballastAndConcreteInstallationsOption5.label",
      value: "Instalatii de preparare a betoanelor",
    },
    {
      label: "bcm.ballastAndConcreteInstallationsOption6.label",
      value:
        "Instalatii de preparare a betoanelor complet automatizate, instalatii de preparare a mixturilor asfaltice",
    },
  ],
  "Utilaje de drumuri si cai ferate": [
    {
      label: "bcm.roadAndRailwayEquipmentOption1.label",
      value: "Compactoare, vibratoare, cilindru compresor",
    },
    {
      label: "bcm.roadAndRailwayEquipmentOption2.label",
      value:
        "Masini de stabilizat dinamic stratul de balast, masini de burat, masini de curatat prima de balast",
    },
    {
      label: "bcm.roadAndRailwayEquipmentOption3.label",

      value:
        "Utilaje pentru aliniamentul caii, compactarea profilului de balast, montarea traverselor si a caii de rulare",
    },
    {
      label: "bcm.roadAndRailwayEquipmentOption4.label",

      value:
        "Echipamente pentru executie parapeti, echipamente pentru executia canalelor de scurgere, echipamente pentru executia bordurilor",
    },
    {
      label: "bcm.roadAndRailwayEquipmentOption5.label",

      value: "Freze pentru decaparea straturilor rutiere",
    },
    {
      label: "bcm.roadAndRailwayEquipmentOption6.label",

      value: "Utilaje pentru asfalt, utilaje pentru betonat",
    },
    {
      label: "bcm.roadAndRailwayEquipmentOption7.label",

      value:
        "Alte echipamente pentru constructii de drumuri (care nu au mijloace de locomotii proprii)",
    },
    {
      label: "bcm.roadAndRailwayEquipmentOption8.label",

      value: "Alte utilaje de constructii cai ferate",
    },
  ],
  "Macarale, sisteme de ridicat si transportat": [
    {
      label: "bcm.cranesLiftingAndTransportingSystemsOption1.label",
      value: "Automacarale (pe pneuri sau pe senile)",
    },
    {
      label: "bcm.cranesLiftingAndTransportingSystemsOption2.label",

      value: "Elevatoare, platforme de ridicat, lifturi",
    },
    {
      label: "bcm.cranesLiftingAndTransportingSystemsOption3.label",

      value:
        "Macarale turn, poduri rulante, alte tipuri de macarale si sisteme de ridicat",
    },
    {
      label: "bcm.cranesLiftingAndTransportingSystemsOption4.label",

      value: "Motostivuitoare",
    },
    {
      label: "bcm.cranesLiftingAndTransportingSystemsOption5.label",

      value: "Locomotive de cale ferata uzinala",
    },
    {
      label: "bcm.cranesLiftingAndTransportingSystemsOption6.label",

      value: "Material rulant",
    },
    {
      label: "bcm.cranesLiftingAndTransportingSystemsOption7.label",

      value: "Conveiere (benzi transportatoare)",
    },
    {
      label: "bcm.cranesLiftingAndTransportingSystemsOption8.label",

      value: "Teleferice, funiculare",
    },
    {
      label: "bcm.cranesLiftingAndTransportingSystemsOption9.label",

      value:
        "Sonete (inclusiv pentru palplanse), instalatii pentru extragerea planselor",
    },
  ],
  "Utilaje speciale": [
    {
      label: "bcm.specialEquipmentOption1.label",
      value: "Motocompresoare, generatoare Diesel",
    },
    {
      label: "bcm.specialEquipmentOption2.label",

      value: "Motopompe",
    },
    {
      label: "bcm.specialEquipmentOption3.label",

      value: "Conducte pentru desecare si epuismente",
    },
    {
      label: "bcm.specialEquipmentOption4.label",

      value: "Echipamente de sudura",
    },
    {
      label: "bcm.specialEquipmentOption5.label",

      value: "Tocatoare de fier vechi",
    },
    {
      label: "bcm.specialEquipmentOption6.label",

      value: "Bacari, birouri de santier",
    },
  ],
  "Utilaje agricole": [
    {
      label: "bcm.agriculturalMachineryOption1.label",
      value: "Tractoare si alte utilaje agricole autopropulsate, vindrovere",
    },
    {
      label: "bcm.agriculturalMachineryOption2.label",

      value: "Instalatii de irigat",
    },
    {
      label: "bcm.agriculturalMachineryOption3.label",

      value: "Combine si masini de treierat",
    },
    {
      label: "bcm.agriculturalMachineryOption4.label",

      value:
        "Alte utilaje agricole (semanatori, seceratori, plantatoare, pluguri, masini de balotat)",
    },
  ],
  "Autovehicule utilitare si pentru servicii publice": [
    {
      label: "bcm.utilityVehiclesOption1.label",
      value: "Autovehicule de pompieri, scari mobile",
    },
    {
      label: "bcm.utilityVehiclesOption2.label",

      value: "Masini pentru curatenia si intretinerea strazilor",
    },
    {
      label: "bcm.utilityVehiclesOption3.label",

      value:
        "Pluguri de zapada, utilaje de intretinere a partiilor de ski, alte vehicule autopropulsate (exceptand vehicule de transport in comun)",
    },
    {
      label: "bcm.utilityVehiclesOption4.label",

      value: "Cositoare, masini de tuns gazonul",
    },
  ],
};

export const asiromGroupOfGoods = [
  {
    label: "bcm.groupOfGoodsMbsAsiromOption1.label",
    value: "Cazane cu combustibili conventionali",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption2.label",

    value: "Generatoare electrice",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption3.label",

    value: "Uzine hidroelectrice",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption4.label",

    value:
      "Transformatoare si sisteme de transport si distributie a energiei electrice",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption5.label",

    value: "Generatoare cu gaz",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption6.label",

    value: "Sisteme de alimentare cu apa si de purifi",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption7.label",

    value: "Cazane",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption8.label",

    value: "Rezervoare sub presiune",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption9.label",

    value: "Reactori",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption10.label",

    value: "Turbo-compresoare",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption11.label",

    value: "Pompe",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption12.label",

    value: "Schimbatoare de caldura",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption13.label",

    value: "Centrifuge",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption14.label",

    value: "Filtre",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption15.label",

    value: "Cuptoare",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption16.label",

    value: "Utilaje din minele subterane sau de la suprafata",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption17.label",

    value:
      "Utilaje de extragere si separare a titeiului, gazelor naturale si a apei",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption18.label",

    value: "Prese",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption19.label",

    value: "Rotative",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption20.label",

    value: "Sisteme de taiere, de multiplicare si de copiere",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption21.label",

    value: "Tabacarii",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption22.label",

    value: "Fabrici de pantofi si alte produse din piele",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption23.label",

    value: "Instalatii din stadioane si arene sportive",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption24.label",

    value: "Industria alimentara",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption25.label",

    value: "Industria celulozei, cartonului si a hartiei",
  },

  {
    label: "bcm.groupOfGoodsMbsAsiromOption26.label",

    value: "Fabrici de conserve si sisteme de refrigerare",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption27.label",

    value: "Industria de extragere si prelucrare a metalelor",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption28.label",

    value: "Industria materialelor de constructii",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption29.label",

    value: "Industria lemnului",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption30.label",

    value: "Industria textila",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption31.label",

    value: "Industria de producere a cauciucului si a maselor practice",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption32.label",

    value:
      "Instalatii din cinematografe, discoteci, teatre, studiouri de radio TV",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption33.label",

    value: "Instalatii de conditionare a aerului si instalatii de ventilatie",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption34.label",

    value: "Instalatii de incalzire centrala in cladiri neindustriale",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption35.label",

    value: "Baterii de acumulatoare electrice",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption36.label",

    value: "Instalatii de incalzire centrala in cladiri industriale",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption37.label",

    value:
      "Masini si instalatii de calcul si contabilizare, masini pentru lucrari adminstrative (inclusiv motoarele electrice)",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption38.label",

    value:
      "Locomotive, masini cu abur cu piston, compresoare si pompe de vid cu cazanele lor",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption39.label",

    value: "Macarale, utilaje si instalatii mobile de transportat si ridicat",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption40.label",

    value: "Transformatoare si autotransformatoare in uzine",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption41.label",

    value:
      "Generatoare de energie electrica, compensatoare, contactoare, convertizoare, rotative, redresoare",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption42.label",

    value: "Masini, utilaje si instalatii pentru agricultura",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption43.label",

    value: "Masini, utilaje si instalatii de avertizat si stins incendii",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption44.label",

    value: "Masini, utilaje si instalatii electrice pentru sudarea metalelor",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption45.label",

    value: "Motoare de orice fel",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption46.label",

    value: "Ventilatoare electrice",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption47.label",

    value:
      "Aparate Rontgen si orice alte masini, utilaje si instalatii din unitati sanitare",
  },
  {
    label: "bcm.groupOfGoodsMbsAsiromOption48.label",

    value: "Valturi de portelan sau piatra",
  },
];
