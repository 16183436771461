import moment from "moment";
import { useMutation } from "react-query";
import api from "service/api";

const calculateCasco = async (dataObj) => {
  const response = await api.post(`/CalculateCasco`, {
    appInfo: {
      dataObj: {
        ...dataObj,
        inp_external_contractdate_ut: moment(
          dataObj?.inp_external_contractdate_ut
        ).format("YYYY-MM-DD"),
      },
      resolvedProperties: {
        title: "BCR Heavy Machinery",
      },
    },
  });

  return response.data?.appInfo;
};

export function useCalculateCasco() {
  return useMutation(calculateCasco, {
    onSuccess: () => {},

    onError: (error) => {
      // handle error
    },
  });
}
