import React from "react";

import { CenteredCell, Flex, StyledLink } from "components/styled";

import { ExpandedColumn, NumberFilter, TaxonomyFilter } from "library/types";
import { firstLetterUpper, formatDateString } from "library/utils";
import { ContextButton } from "./ContextButton";
import ContractType from "./ContractType";
import { OPEN_OFFER, ROUTES } from "library/shared";

export const isFilteredTaxonomy = (f: TaxonomyFilter) => f.value?.length > 0;
export const isFilteredNumber = (f: NumberFilter) => {
  if (f.operator === "between") {
    return (
      f.number1 !== null &&
      f.number2 !== null &&
      f.number1 !== ("" as any) &&
      f.number2 !== ("" as any)
    );
  }
  return f.number1 !== null && f.number1 !== ("" as any);
};

export const hydrateColumn = ({
  column,
  defaultColumns,
}: {
  column: ExpandedColumn;
  defaultColumns: ExpandedColumn[];
}) => {
  const defaultCol = defaultColumns.find((dc) => dc.key === column.key)!;
  const mappedCol = { ...column };
  if (defaultCol?.filter && mappedCol.filter) {
    Object.keys(defaultCol.filter).forEach((key) => {
      const field = defaultCol?.filter![key];
      if (typeof field === "function") {
        mappedCol.filter![key] = field;
      }
    });
  }
  if (mappedCol.filter && mappedCol.filter.type === "date") {
    if (mappedCol.filter.date1) {
      mappedCol.filter.date1 = new Date(mappedCol.filter.date1);
    }
    if (mappedCol.filter.date2) {
      mappedCol.filter.date2 = new Date(mappedCol.filter.date2);
    }
  }
  mappedCol.onRender = defaultCol?.onRender;

  mappedCol.excel = defaultCol?.excel;
  return mappedCol;
};

export const defcolumns: ExpandedColumn[] = [
  {
    key: "offerId",
    name: "Offer ID",
    labelKey: "bcm.offers.offerId.label",
    isResizable: true,
    minWidth: 140,
    maxWidth: 200,
    // minWidth: 150,
    // isFixed: true,
    isVisible: true,
    isFixed: true,
    isSortable: true,
    iconName: "Sort",
    excel: {
      width: 150,
      // render: ({ t, taxonomy, row }) =>
      //   t(taxonomy.VehicleStatusCode.byId[row.vehicleStatusCode]?.code),
      isSortable: false,
    },
    onRender: (row) => {
      if (!row) return;

      const hasInvoice = row?.status.includes("Decont");
      const path = ROUTES.getVehicleItemPath({
        offerId: row.offerId,
        actionType: OPEN_OFFER,
        ...(hasInvoice && { hasInvoice }),
      });
      return (
        <StyledLink
          // to={routes.formPage.getPath(row.offerId)}
          to={path}
          // ref={"/"}
        >
          {row?.offerId ? row?.offerId : null}
        </StyledLink>
      );
    },
    pdf: {
      width: 100,
      render: ({ offer }) => {
        return {
          text: offer?.offerId,
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
  },
  {
    key: "options",
    name: "options",
    isResizable: true,
    isSortable: false,
    labelKey: "",

    maxWidth: 50,
    minWidth: 50,
    isVisible: true,
    // isFixed: true,
    onRender: (row) => {
      if (!row) return;
      return (
        <CenteredCell>
          <ContextButton offer={row} />
        </CenteredCell>
      );
    },
  },
  {
    key: "offerRequestedAt",
    name: "Date Of Offer",
    labelKey: "bcm.offers.offerRequestedAt.label",
    isResizable: true,
    minWidth: 140,
    maxWidth: 220,
    isVisible: true,
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    isSortable: true,
    isFixed: true,
    iconName: "Sort",
    onRender: (row) => {
      if (!row) return;
      return row?.offerRequestedAt
        ? formatDateString(row?.offerRequestedAt)
        : null;
    },
    pdf: {
      width: 100,
      render: ({ offer }) => {
        return {
          text: formatDateString(offer?.offerRequestedAt),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
  },

  {
    key: "offerRequestedByName",
    name: "Offer Made By",
    labelKey: "bcm.offers.offerRequestedByName.label",
    isResizable: true,
    minWidth: 200,
    maxWidth: 300,
    isFixed: true,
    filter: {
      type: "text",
      value: "",
    },
    isVisible: true,
    // isFixed: true,
    isSortable: true,
    iconName: "Sort",
  },
  {
    key: "inp_contract_type_ut",
    name: "Contract Type",
    labelKey: "bcm.inp_contract_type.label",
    isResizable: true,
    // width: 140,
    minWidth: 200,
    maxWidth: 300,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      return <ContractType data={row?.inp_contract_type_ut} />;
    },
  },
  {
    key: "inp_vehicle_brand_ut",
    name: "Vehicle Brand",
    labelKey: "bcm.inp_vehicle_brand.label",
    isResizable: true,
    maxWidth: 170,
    minWidth: 100,
    // width: 140,

    isVisible: true,
    isSortable: true,
    iconName: "Sort",
  },
  {
    key: "inp_vehicle_model_ut",
    name: "Vehicle Model",
    labelKey: "bcm.inp_vehicle_model.label",
    isResizable: true,
    maxWidth: 300,
    minWidth: 200,

    isVisible: true,
    isSortable: true,
    iconName: "Sort",
  },
  {
    key: "inp_lessee_company_name_ut",
    name: "companyName",
    labelKey: "bcm.offers.inp_lessee_company_name_ut.label",
    isResizable: true,

    minWidth: 200,
    maxWidth: 300,

    isVisible: true,
    isSortable: false,
  },
  {
    key: "inp_lessee_uid_ut",
    name: "id",
    labelKey: "bcm.offers.inp_lessee_id.label",
    isResizable: true,
    maxWidth: 170,
    minWidth: 100,
    // width: 140,
    isVisible: true,
    isSortable: false,
  },
  {
    key: "inp_policy_leasingctrno_ut",
    name: "id",
    labelKey: "bcm.offers.inp_policy_leasingctrno_ut.label",
    isResizable: true,
    maxWidth: 170,
    minWidth: 100,
    // width: 140,

    isVisible: true,
    isSortable: false,
  },
  {
    key: "validFrom",
    name: "Valid From",
    labelKey: "bcm.offers.validFrom.label",
    isResizable: true,
    // maxWidth: 170,
    // minWidth: 100,
    minWidth: 140,
    maxWidth: 200,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    pdf: {
      width: 100,
      render: ({ offer }) => {
        return {
          text: formatDateString(offer?.validFrom),
          style: {
            bold: true,
            alignment: "center",
          },
        };
      },
    },
    onRender: (row) => {
      return formatDateString(row?.validFrom);
    },
  },

  {
    key: "status",
    name: "Document",
    labelKey: "bcm.offers.document.label",
    // maxWidth: 170,
    // minWidth: 100,
    minWidth: 200,
    maxWidth: 300,

    isResizable: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
  },

  {
    key: "invoiceCascoId",
    name: "Insurer Casco Id",
    labelKey: "bcm.invoiceCascoId.label",
    // maxWidth: 170,
    // minWidth: 100,
    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      return <Flex>{row?.invoiceCascoId}</Flex>;
    },
  },
  {
    key: "inp_beneficiar_ut",
    name: "Insurer Casco",
    labelKey: "bcm.insurerCasco.label",
    // maxWidth: 170,
    // minWidth: 100,

    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      const insurer = row.inp_beneficiar_ut;
      if (!insurer) return null;
      const formatedInsurer = firstLetterUpper(row.inp_beneficiar_ut);

      return <Flex>{formatedInsurer}</Flex>;
    },
  },
  {
    key: "cascoInvoiceIssuedAt",
    name: "Insurer Casco Issued At",
    labelKey: "bcm.cascoInvoiceIssuedAt.label",
    // maxWidth: 170,
    // minWidth: 100,

    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      return <Flex>{formatDateString(row?.cascoInvoiceIssuedAt)}</Flex>;
    },
  },
  {
    key: "cascoInvoiceIssuedByName",
    name: "Insurer Casco Issued By",
    labelKey: "bcm.cascoInvoiceIssuedByName.label",
    // maxWidth: 170,
    // minWidth: 100,
    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      return <Flex>{row?.cascoInvoiceIssuedByName}</Flex>;
    },
  },
  {
    key: "invoiceCascoPremium",
    name: "Invoice Casco",
    labelKey: "bcm.invoiceCascoPremium.label",
    // maxWidth: 170,
    // minWidth: 100,

    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      return <Flex>{row?.invoiceCascoPremium}</Flex>;
    },
  },
  {
    key: "policyCascoId",
    name: "Policy Casco Id",
    labelKey: "bcm.policyCascoId.label",
    // maxWidth: 170,
    // minWidth: 100,

    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      return <Flex>{row?.policyCascoId}</Flex>;
    },
  },
  {
    key: "cascoPolicyIssuedAt",
    name: "Policy Casco Issued At",
    labelKey: "bcm.cascoPolicyIssuedAt.label",
    // maxWidth: 170,
    // minWidth: 100,

    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      return <Flex>{formatDateString(row?.cascoPolicyIssuedAt)}</Flex>;
    },
  },
  {
    key: "cascoPolicyIssuedByName",
    name: "Policy Casco Issued By",
    labelKey: "bcm.cascoPolicyIssuedByName.label",
    // maxWidth: 170,
    // minWidth: 100,

    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      return <Flex>{row?.cascoPolicyIssuedByName}</Flex>;
    },
  },
  {
    key: "policyCascoPremium",
    name: "Policy Casco Premium",
    labelKey: "bcm.policyCascoPremium.label",
    // maxWidth: 170,
    // minWidth: 100,
    minWidth: 200,
    maxWidth: 300,
    isResizable: true,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      return <Flex>{row?.policyCascoPremium}</Flex>;
    },
  },
];
