import { ComboboxField } from "components/controls/ComboBoxField";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  groupamaEquipmentGroupOptions,
  groupamaMachineTypeAllOptions,
} from "./constants";
import { FieldTitle, FieldsContainer } from "pages/VehicleItem/styled";

function GrupamaEquipmentFields() {
  const { watch, setValue } = useFormContext();
  const values = watch();

  const groupamaMachineTypeOptions =
    groupamaMachineTypeAllOptions[values.inp_group_ut_gro] || [];

  const groupamaEquipmentGroupValue = values?.inp_group_ut_gro;

  const firstMachineTypeOption = groupamaMachineTypeOptions[0]?.value;

  useEffect(() => {
    setValue("inp_type_ut_gro", firstMachineTypeOption);
  }, [groupamaEquipmentGroupValue, setValue]);

  return (
    <>
      <FieldTitle>Groupama</FieldTitle>
      <FieldsContainer>
        <ComboboxField
          required
          name="inp_group_ut_gro"
          label="bcm.groupamaEquipmentGroup.label"
          value={values.inp_group_ut_gro}
          options={groupamaEquipmentGroupOptions}
        />
        <ComboboxField
          required
          name="inp_type_ut_gro"
          label="bcm.groupamaMachineType.label"
          value={values.inp_type_ut_gro}
          options={groupamaMachineTypeOptions}
        />
      </FieldsContainer>
    </>
  );
}

export default GrupamaEquipmentFields;
