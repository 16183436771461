import React, { useEffect } from "react";
import {
  mergeStyleSets,
  MessageBar,
  MessageBarType,
} from "office-ui-fabric-react";
import { toast } from "react-toastify";
import { useAppContext } from "providers/AppProvider";
import { ActionButton } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { INotify } from "library/types";

export const errorHandlerClasses = mergeStyleSets({
  contentContainer: {
    whiteSpace: "initial",
    margin: "10px 0",
    display: "flex",
    flexDirection: "column",
  },
  copyTo: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  copyToIcon: { height: 17 },
  notificationMessage: {
    borderBottom: "2px solid #faf9f8",
    cursor: "pointer",
  },
});

interface IErrorHandler {
  value: string;
  type: string;
  label: string;
  fieldName?: string;
  errors?: any;
}

export const errorHandler = (props: IErrorHandler) => {
  let { value, type, label, fieldName, errors } = props;
  if (!value) return;

  const notify: INotify = {
    notifyCode: "",
    notifyText: value,
    type: type || "error", // default is error
    label: label,
  };

  toast(
    <NotificationMessages
      addNotification={true}
      notify={notify}
      fieldName={fieldName}
      errors={errors}
    />,

    {
      toastId: value,
      position: "top-right",
      pauseOnHover: true,
      className: "toast-default-background",
      autoClose: 3000,
      progressClassName: "toast-blue-loading",
      onClick: () => {},
    }
  );
};

export const NotificationMessages = ({
  notify,
  errors,
  fieldName,
  addNotification,
}) => {
  const { t } = useTranslation();

  const type: string = notify?.type.toLowerCase();
  let messageBarType: number = MessageBarType[type];
  const { onRemoveNotification, onSetNotifications } = useAppContext();
  const typeFormated = t(`greco.${type}`);

  const handleNotificationClick = (ev) => {
    ev.preventDefault();
    if (fieldName) {
      const element: any = Array.from(
        document.querySelectorAll("input, textarea")
      ).find((el) => {
        const inputName = el.getAttribute("name");
        return inputName === fieldName;
      });
      element.parentElement.scrollIntoView({ block: "center" });
      element?.focus();
    }
    if (notify.type !== "success") {
      onRemoveNotification(notify.label);
    }
  };

  useEffect(() => {
    if (notify.type !== "success" && addNotification) {
      onSetNotifications({
        errors,
        fieldName,
        key: notify.label,
        handleNotificationClick,
        messageBarType,
        notify,
      });
    }
  }, [notify, addNotification]);

  const isWarningMessage = messageBarType === 5; // use info color for warnings
  return (
    <MessageBar
      className="message-bar"
      messageBarType={messageBarType}
      onDismiss={() => {
        onRemoveNotification(notify.label);
      }}
      isMultiline={false}
      styles={{
        root: {
          height: "100%",
          width: "100%",
          ".ms-MessageBar-innerText": {
            width: "100%",
          },
        },
      }}
    >
      <>
        <h2>{typeFormated}</h2>
        <div className={errorHandlerClasses.contentContainer}>
          <span>{notify.notifyText}</span>
          {!isWarningMessage && (
            <div className={errorHandlerClasses.copyTo}>
              <strong> {t("bcm.copyToClipboard.label")}</strong>:
              <ActionButton
                iconProps={{ iconName: "Copy" }}
                className={errorHandlerClasses.copyToIcon}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(notify.notifyText);

                  // toast.success(t("ehm.copiedToClipboard"));
                }}
              ></ActionButton>
            </div>
          )}
        </div>
      </>
    </MessageBar>
  );
};
