import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CommandBarButton } from "@fluentui/react";

import { createPDFOffers, hydrateOffers } from "library/utils";
import { getVehicleList } from "hooks/data/queries/useGetVehicleList";
import { useMainContext } from "providers/MainProvider";

type Props = {
  disabled: boolean;
};

export const ExportPDF = ({ disabled }: Props) => {
  const { t } = useTranslation();
  const { columns, params } = useMainContext();

  const [loading, setLoading] = useState(false);

  const handleExportPdf = async () => {
    try {
      setLoading(true);
      const response = await getVehicleList(
        {
          ...params,
          skip: 0,
          top: 1000000,
        },
        columns
      );
      const data = hydrateOffers(response.results.data.value);

      createPDFOffers({
        result: data,
        columns: columns.filter((c) => c.isVisible),
        t: t,
      }).open();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <CommandBarButton
      text={t("greco.exportPDF")}
      onClick={handleExportPdf}
      disabled={disabled || loading}
      iconProps={{
        iconName: loading ? "ProgressRingDots" : "PDF",
      }}
    />
  );
};
