import { Input, Spinner, useId } from "@fluentui/react-components";
import * as React from "react";
import { useFormContext } from "react-hook-form";
import { styled } from "styled-components";
import { ErrorMessage } from "@hookform/error-message";
import { StyledErrorMessage } from "components/styled";
import { useTranslation } from "react-i18next";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import { FlashAutoRegular } from "@fluentui/react-icons";

type Props = {
  name: string;
  value: string;
  label: string;
  required?: boolean;
  onActionBtnClick?: () => void;
  isLoading?: boolean;
  type?:
    | "number"
    | "search"
    | "time"
    | "text"
    | "email"
    | "password"
    | "tel"
    | "url"
    | "date"
    | "datetime-local"
    | "month"
    | "week";
};

const InputField = ({
  name,
  value,
  label,
  required = false,
  type = "text",
  onActionBtnClick,
  isLoading,
}: Props) => {
  const inputId = useId(name);
  const { t } = useTranslation();
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const onChange = (event, data) => {
    setValue(name, data.value);
  };

  return (
    <Container>
      <FormFieldLabel label={label} required={required} />
      <Input
        type={type}
        appearance="underline"
        id={inputId}
        name={name}
        onChange={onChange}
        value={value || ""}
        contentAfter={
          isLoading ? (
            <Spinner size="tiny" />
          ) : (
            onActionBtnClick && (
              <FlashAutoRegular
                style={{
                  cursor: value ? "pointer" : "inherit",
                  opacity: value ? "1" : "0.5",
                }}
                onClick={() => {
                  if (value) {
                    onActionBtnClick();
                  }
                }}
              />
            )
          )
        }
      />

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <StyledErrorMessage>{message}</StyledErrorMessage>
        )}
      />
    </Container>
  );
};

export default InputField;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
`;
