import {
  firstLetterUpper,
  formatDateString,
  formatFullDate,
  formatNumber,
  getTaxonomyValue,
} from "library/utils";
import { ESidemenuTab } from "./types";
import yup from "validation";

import {
  contractCurrencyOptions,
  contractTypeOptions,
  paymentMethodOptions,
  personTypeOptions,
  vehicleUseOptions,
} from "./VehicleFormItems/GeneralFormItems/constants";
import { vehicleConditionOptions } from "./VehicleFormItems/VehicleItems/constants";
import {
  asiromGroupOfGoods,
  asiromMachineGroupOptions,
  asiromMachineTypesAllOptions,
} from "./VehicleFormItems/InsurerSettings/asirom/constants";
import {
  oldUniqaMachineOptions,
  uniqaEquipmentGroupOptions,
  uniqaGroupOfGoodsAllOptions,
  uniqaInactivityPeriodOptions,
} from "./VehicleFormItems/InsurerSettings/uniqa/constants";
import {
  omniasiEquipmentTypeOptions,
  omniasigGroupOptions,
} from "./VehicleFormItems/InsurerSettings/omniasig/constants";
import {
  groupamaEquipmentGroupOptions,
  groupamaMachineTypeAllOptions,
} from "./VehicleFormItems/InsurerSettings/grupama/constants";
import {
  allianzEquipmentGroupOptions,
  allianzMachineTypesAllOptions,
} from "./VehicleFormItems/InsurerSettings/allianz/constants";
import { countyOptions } from "./VehicleFormItems/ClientFormItems/constants";

export const formatDataForReadOnlyView = (data, t) => {
  const formatedData = {
    [ESidemenuTab.general]: {},
    [ESidemenuTab.vehicle]: {},
    [ESidemenuTab.insurerSettings]: {},
    [ESidemenuTab.client]: {},
    invoice: {},
    policy: {},
  };
  if (!data) return formatedData;

  const currency = getTaxonomyValue(
    contractCurrencyOptions,
    data.inp_policy_currency_ut,
    t
  );

  formatedData[ESidemenuTab.general] = {
    "bcm.personType.label": getTaxonomyValue(
      personTypeOptions,
      data.inp_lessee_type_ut,
      t
    ),
    "bcm.contractLeasing.label": formatDateString(
      data.inp_external_contractdate_ut
    ),
    "bcm.vehicleUse.label": getTaxonomyValue(
      vehicleUseOptions,
      data.inp_vehicle_carusage_ut,
      t
    ),
    "bcm.paymentMethod.label": getTaxonomyValue(
      paymentMethodOptions,
      data.inp_policy_payment_ut,
      t
    ),
    "bcm.valueForLeasingVAT.label": `${formatNumber(
      data.inp_policy_value_ut
    )} ${currency}`,
    "bcm.leasingPeriod.label": data.inp_leasing_monthsinterval_ut,
    "bcm.contactCurrency.label": currency,
    "bcm.contractType.label": getTaxonomyValue(
      contractTypeOptions,
      data.inp_contract_type_ut,
      t
    ),
  };

  formatedData[ESidemenuTab.vehicle] = {
    "bcm.vehicleConditionLabel.label": getTaxonomyValue(
      vehicleConditionOptions,
      data.inp_vehicle_state_ut,
      t
    ),
    "bcm.manufacturingYear.label": data.inp_vehicle_construction_ut,
    "bcm.brand.label": data.inp_vehicle_brand_ut,
    "bcm.model.label": data.inp_vehicle_model_ut,
    "bcm.chassisSeries.label": data.inp_vehicle_vin_ut,
  };

  formatedData[ESidemenuTab.insurerSettings] = [
    {
      title: "Asirom",
      data: {
        "bcm.asiromMachineGroup.label": getTaxonomyValue(
          asiromMachineGroupOptions,
          data.inp_group_ut_asirom,
          t
        ),
        "bcm.asiromMachineType.label": getTaxonomyValue(
          asiromMachineTypesAllOptions[data.inp_group_ut_asirom],
          data.inp_type_ut_asirom,
          t
        ),
        "bcm.groupOfGoodsMbsAsirom.label": getTaxonomyValue(
          asiromGroupOfGoods,
          data.inp_mbk_asirom,
          t
        ),
      },
    },
    {
      title: "Uniqa",
      data: {
        "bcm.uniqaEquipmentGroup.label": getTaxonomyValue(
          uniqaEquipmentGroupOptions,
          data.inp_group_ut_uniqa,
          t
        ),
        "bcm.uniqaGroupOfGoods.label": getTaxonomyValue(
          uniqaGroupOfGoodsAllOptions[data.inp_group_ut_uniqa],
          data.inp_mbk_uniqa,
          t
        ),
        "bcm.oldUniqaMachine.label": getTaxonomyValue(
          oldUniqaMachineOptions,
          data.inp_usage_ut,
          t
        ),
        "bcm.uniqaInactivityPeriod.label": getTaxonomyValue(
          uniqaInactivityPeriodOptions,
          data.inp_inactivity_ut,
          t
        ),
      },
    },
    {
      title: "Omniasig",
      data: {
        "bcm.omniasigEquipmentGroup.label": getTaxonomyValue(
          omniasigGroupOptions,
          data.inp_group_ut_omn,
          t
        ),
        "bcm.omniasigEquipmentType.label": getTaxonomyValue(
          omniasiEquipmentTypeOptions,
          data.inp_type_ut_omn,
          t
        ),
      },
    },
    {
      title: "Groupama",
      data: {
        "bcm.groupamaEquipmentGroup.label": getTaxonomyValue(
          groupamaEquipmentGroupOptions,
          data.inp_group_ut_gro,
          t
        ),
        "bcm.groupamaMachineType.label": getTaxonomyValue(
          groupamaMachineTypeAllOptions[data?.inp_group_ut_gro],
          data.inp_type_ut_gro,
          t
        ),
      },
    },
    {
      title: "Allianz",
      data: {
        "bcm.allianzEquipmentGroup.label": getTaxonomyValue(
          allianzEquipmentGroupOptions,
          data.inp_group_ut_all,
          t
        ),
        "bcm.allianzMachineType.label": getTaxonomyValue(
          allianzMachineTypesAllOptions[data.inp_group_ut_all],
          data.inp_type_ut_all,
          t
        ),
      },
    },
  ];

  formatedData[ESidemenuTab.client] = {
    "bcm.companyName.label": data.inp_lessee_company_name_ut,
    "bcm.cui.label": data.inp_lessee_uid_ut,
    "bcm.county.label": getTaxonomyValue(
      countyOptions,
      data.inp_lessee_county_ut,
      t
    ),
    "bcm.oras.label": data.inp_lessee_city_ut,

    "bcm.strada.label": data.inp_lessee_street_ut,
    "bcm.number.label": data.inp_lessee_housenr_ut,
    "bcm.block.label": data.inp_lessee_building_ut,
    "bcm.entrance.label": data.inp_lessee_entrance_ut,
    "bcm.apartament.label": data.inp_lessee_door_ut,
    "bcm.floor.label": data.inp_lessee_floor_ut,
    "bcm.mobilePhone.label": data.inp_lessee_mobile_ut,
    "bcm.email.label": data.inp_lessee_email_ut,
  };

  formatedData.invoice = {
    "bcm.insurer.label": firstLetterUpper(data.inp_beneficiar_ut),
    "bcm.contractLeasingNumber.label": data.inp_policy_leasingctrno_ut,
    "bcm.invoiceCascoId.label": data.invoiceCascoId,
    "bcm.received.label": `${formatNumber(
      data.invoiceCascoPremium
    )} ${currency}`,
    "bcm.firstRate.label": `${formatNumber(
      data.invoiceCascoFirstRate
    )} ${currency}`,
    "bcm.lastModifyAt.label": formatFullDate(data.cascoInvoiceIssuedAt),
    "bcm.lastModifyBy.label": data.cascoInvoiceIssuedByName,
  };
  formatedData.policy = {
    "bcm.startDate.label": formatDateString(data.validFrom),
    "bcm.policyNumber.label": data.policyCascoId,

    "bcm.received.label": `${formatNumber(
      data.policyCascoPremium
    )} ${currency}`,
    "bcm.lastModifyAt.label": formatFullDate(data.cascoPolicyIssuedAt),
    "bcm.lastModifyBy.label": data.cascoInvoiceIssuedByName,
  };

  return formatedData;
};

export const getValidationSchemaObj = (t) => {
  return {
    //general fields

    inp_lessee_type_ut: yup.string().required(),
    inp_external_contractdate_ut: yup.date().required(),
    inp_vehicle_carusage_ut: yup.mixed().required(),
    inp_policy_payment_ut: yup.mixed().required(),
    inp_policy_value_ut: yup.number().required(),
    inp_leasing_monthsinterval_ut: yup.number().min(12).max(84).required(),
    inp_policy_currency_ut: yup.mixed().required(),
    inp_contract_type_ut: yup.mixed().required(),

    //vehicle fields
    inp_vehicle_state_ut: yup.string().required(),
    inp_vehicle_construction_ut: yup.string().required(),
    inp_vehicle_brand_ut: yup.string().required(),
    inp_vehicle_model_ut: yup.string().required(),
    inp_vehicle_vin_ut: yup
      .string()
      .length(17, t("bcm.vinValidation.label"))
      .required(),

    //insurer settings
    inp_group_ut_asirom: yup.mixed().required(),
    inp_type_ut_asirom: yup.mixed().required(),
    inp_mbk_asirom: yup.mixed().required(),

    inp_group_ut_uniqa: yup.mixed().required(),
    inp_mbk_uniqa: yup.mixed().required(),
    inp_usage_ut: yup.mixed().required(),
    inp_inactivity_ut: yup.mixed().required(),

    inp_group_ut_omn: yup.mixed().required(),
    inp_type_ut_omn: yup.mixed().required(),

    inp_group_ut_gro: yup.mixed().required(),
    inp_type_ut_gro: yup.mixed().required(),

    inp_group_ut_all: yup.mixed().required(),
    inp_type_ut_all: yup.mixed().required(),

    //client

    inp_lessee_company_name_ut: yup.string().required(),
    inp_lessee_uid_ut: yup.string().required(),
    inp_lessee_street_ut: yup.string().required(),
    inp_lessee_housenr_ut: yup.string().required(),
    inp_lessee_city_ut: yup.string().required(),
    inp_lessee_county_ut: yup.string().required(),
    inp_lessee_mobile_ut: yup.string().required(),
    inp_lessee_email_ut: yup.string().required().email(),
  };
};

export const newOfferDefaultValues = {
  inp_lessee_type_ut: "Persoana juridica",
  inp_vehicle_state_ut: "Nou",
  inp_external_contractdate_ut: new Date().toDateString(),
  inp_vehicle_construction_ut: new Date().getFullYear(),
  inp_group_ut_asirom: "Utilaje pentru terasamente",
  inp_mbk_asirom: "Cazane cu combustibili conventionali",
  inp_vehicle_carusage_ut: "Romania",
  inp_policy_payment_ut: "anuala",
  inp_group_ut_uniqa: "Autopropulsate",
  inp_usage_ut: "0 - 0,5 ani",
  inp_inactivity_ut: "3 luni",
  inp_group_ut_omn: "Utilaje neinmatriculabile",
  inp_type_ut_omn: "Utilaje de constructii",
  inp_group_ut_gro: "Utilaje agricole nesupuse inmatricularii",
  inp_group_ut_all: "Utilaje si masini agricole",
  inp_policy_currency_ut: "EUR",
  inp_contract_type_ut: "Contract de leasing financiar",
  inp_type_ut_asirom: "Excavatoare, screpere, utilaje de sapat, dumpere",
  inp_mbk_uniqa: "Autopropulsate agricole",
  inp_type_ut_gro: "Tractoare agricole, combine si alte utilaje si echipamente",
  inp_type_ut_all: "Combine",
};
