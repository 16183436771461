export const MSGraphParams = {
  appId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: window.location.origin,
  scopes: ["User.Read"],
  tenantId: process.env.REACT_APP_DIRECTORY_ID,
};

export const FleetBFFParams = {
  appId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: window.location.origin,
  scopes: [`${process.env.REACT_APP_CLIENT_ID}/.default`],
  tenantId: process.env.REACT_APP_DIRECTORY_ID,
};

export const AzureGraphParams = {
  appId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: window.location.origin,
  scopes: ["https://graph.windows.net/User.Read"],
  tenantId: process.env.REACT_APP_DIRECTORY_ID,
};

export const ProjectParams = {
  appId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: window.location.origin,
  scopes: [`${process.env.REACT_APP_CLIENT_ID}/.default`],
  tenantId: process.env.REACT_APP_DIRECTORY_ID,
};
