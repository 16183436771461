import React from "react";
import {
  ExcelExportColumn,
  ExcelExport,
} from "@progress/kendo-react-excel-export";
import { useTranslation } from "react-i18next";
import { formatDateWithTime } from "library/utils";
import { useMainContext } from "providers/MainProvider";

type Props = {};

export const ExportExcelVehicles = React.forwardRef<ExcelExport, Props>(
  (data, ref) => {
    const { t } = useTranslation();

    const { columns } = useMainContext();

    const filteredColumns = columns
      .filter((c) => c.key !== "options")
      .filter((c) => c.isVisible);

    return (
      <ExcelExport
        data={[]}
        ref={ref}
        fileName={`Oferta ${formatDateWithTime(new Date())}`}
      >
        {filteredColumns.map((col) => {
          if (col.excel && col.excel.isVisible === false) return null;
          let width = col.width;
          if (col.excel && col.excel.width !== undefined) {
            width = col.excel.width;
          }
          return (
            <ExcelExportColumn
              key={col.key}
              field={col.key}
              title={t(col.labelKey)}
              cellOptions={{ format: "dd.MM.yyyy" }}
              width={width}
              headerCellOptions={{
                background: "#005aa1",
                color: "#fff",
                textAlign: col.align ?? ("center" as any),
              }}
            />
          );
        })}
      </ExcelExport>
    );
  }
);
