export const offerConfig = [
  {
    name: "Omniasig",
    fields: [
      "out_quote_omn",
      "out_quote_omn_value",
      "out_quote_mbk_omn",
      "out_quote_omn_mbk_value",
      "out_pa_omn",
    ],
    totalPremiumField: "out_pa_omn",
  },
  {
    name: "Uniqa",
    fields: [
      "out_quote_uniqa",
      "out_quote_uniqa_value",
      "out_quote_mbk_uniqa",
      "out_quote_uniqa_mbk_value",
      "out_pa_uniqa",
    ],
    totalPremiumField: "out_pa_uniqa",
  },
  {
    name: "Asirom",
    fields: [
      "out_quote_asirom",
      "out_quote_asirom_value",
      "out_quote_mbk_asirom",
      "out_quote_asirom_mbk_value",
      "out_pa_asirom",
    ],
    totalPremiumField: "out_pa_asirom",
  },
  {
    name: "Allianz",
    fields: [
      "out_quote_all",
      "out_quote_all_value",
      "out_quote_mbk_all",
      "out_quote_all_mbk_value",
      "out_pa_all",
    ],
    totalPremiumField: "out_pa_all",
  },
  {
    name: "Groupama",
    fields: [
      "out_quote_gro",
      "out_quote_gro_value",
      "out_quote_mbk_gro",
      "out_quote_gro_mbk_value",
      "out_pa_gro",
    ],
    totalPremiumField: "out_pa_gro",
  },
];
