import { notificationTypes } from "library/shared";

import { useMutation } from "react-query";
import api from "service/api";
import { errorHandler } from "components/AppHeader/NotificationMessages";

const deleteOffer = async (offerId: string) => {
  const response = await api.post(`/InvalidateOffer`, {
    appInfo: {
      dataObj: {
        offerId,
      },
      resolvedProperties: {
        title: "BCR Heavy Machinery",
      },
    },
  });

  return response.data?.appInfo;
};

export function useDeleteOffer() {
  return useMutation(deleteOffer, {
    onError: (error: any) => {
      errorHandler({
        label: "Error",
        type: notificationTypes.error,
        value: "Error while deleting offer item ",
        errors: error?.message,
        fieldName: "",
      });
    },
  });
}
