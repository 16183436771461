import { QUERY_KEYS } from "library/shared";
import { ISetting } from "library/types";
import { urlFormat } from "library/utils";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";

const uadUrl = urlFormat(process.env.REACT_APP_BFF_HOST_UAD);

export const deleteAppSetting = async (
  userAppSettingId: ISetting["userAppSettingId"]
) => {
  return await api.delete(`${uadUrl}/deleteappsettings`, {
    data: [userAppSettingId],
  });
};

export function useDeleteAppSettings() {
  const queryClient = useQueryClient();

  return useMutation(deleteAppSetting, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.appSettings);
    },
    onError: (error) => {
      // handle error
    },
  });
}
