import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navigation from "./Navigation";
import VehicleFormItems from "./VehicleFormItems/VehicleFormItems";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ESidemenuTab } from "./types";
import { yupResolver } from "@hookform/resolvers/yup";

import yup from "validation";
import VehicleFormSkeleton from "./VehicleFormSkeleton";
import { useCreateOffer } from "hooks/data/mutations/useCreateOffer";
import { useVehicleFormContext } from "providers/VehicleFormProvider";
import useGenerateCascoInvoice from "hooks/data/mutations/useGenerateCascoInvoice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ROUTES, notificationTypes } from "library/shared";
import { errorHandler } from "components/AppHeader/NotificationMessages";
import { newOfferDefaultValues } from "./utils";
import CreatePolicyDialog from "./VehicleFormItems/PolicyForm/CreatePolicyDialog";
import { openLinkInNewTab } from "library/utils";

function VehicleForm() {
  const {
    onSideTabSet,
    offerData,
    offerDataLoadedSuccessfully,
    isLoadingOfferData,
    validationSchemaObj,
  } = useVehicleFormContext();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const [createPolicyDialogOpen, setCreatePolicyDialogOpen] = useState(false);

  const { t } = useTranslation();

  const { mutate: createOffer, isLoading: createOfferInProgress } =
    useCreateOffer();

  const { mutate: generateCascoInvoice, isLoading: generateCascoInProgress } =
    useGenerateCascoInvoice();

  const offerId = queryParams.get("offerId");

  const isNewOffer = !offerId;

  const defaultValues = isNewOffer ? newOfferDefaultValues : offerData?.dataObj;

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(yup.object().shape(validationSchemaObj)),
  });

  const toggleCreatePolicyDialog = useCallback(() => {
    setCreatePolicyDialogOpen((prev) => !prev);
  }, []);

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (errors) {
      Object.keys(errors).forEach((error) => {
        errorHandler({
          label: t("greco.error"),
          type: notificationTypes.error,
          value: errors[error].message,
          errors: errors,
        });
      });
    }
  }, [errors]);

  useEffect(() => {
    if (offerDataLoadedSuccessfully) {
      // console.log("RESET");
      reset(defaultValues);
    }
  }, [defaultValues, offerDataLoadedSuccessfully]);

  const onSubmit: SubmitHandler<any> = (data) => {
    const payload = {
      ...data,
    };

    if (payload?.invoiceCascoId) {
      setCreatePolicyDialogOpen(true);

      return;
    }

    if (payload.offerId) {
      generateCascoInvoice(payload, {
        onSuccess: (data) => {
          const path = ROUTES.getVehicleItemPath({
            offerId,
            hasInvoice: true,
            // actionType: CREATE_OFFER,
          });
          const invoicePdfLink = data?.dataObj?.pathToInvoiceCascoPdf;
          openLinkInNewTab(invoicePdfLink);

          navigate(path);
          toast.success(t("greco.success"));
        },
      });
      return;
    }

    createOffer(payload, {
      onSuccess: (data) => {
        const offerId = data?.dataObj?.offerId;
        const path = ROUTES.getVehicleItemPath({
          offerId,
          // actionType: CREATE_OFFER,
        });

        const pdfOfferLink = data?.dataObj?.pathToOfferPdf;

        openLinkInNewTab(pdfOfferLink);

        navigate(path);

        onSideTabSet(ESidemenuTab.cascoOffer);

        toast.success(t("greco.success"));
      },
    });
  };

  if (isLoadingOfferData || createOfferInProgress || generateCascoInProgress) {
    return <VehicleFormSkeleton />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Navigation />

        <VehicleFormItems />
        <CreatePolicyDialog
          isOpen={createPolicyDialogOpen}
          toggleDialogOpen={toggleCreatePolicyDialog}
        />
      </form>
    </FormProvider>
  );
}

export default VehicleForm;
