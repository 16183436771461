import { UseQueryResult, useQuery } from "react-query";
import { getUserDetails } from "../../../service/GraphService";
import { QUERY_KEYS } from "library/shared";
import { IUser } from "library/types";

export function useUserData(userId?: string): UseQueryResult<IUser> {
  return useQuery([QUERY_KEYS.userData], () => getUserDetails(userId), {
    enabled: true,
  });
}
