import { ComboboxField } from "components/controls/ComboBoxField";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  asiromGroupOfGoods,
  asiromMachineGroupOptions,
  asiromMachineTypesAllOptions,
} from "./constants";

import { FieldTitle, FieldsContainer } from "pages/VehicleItem/styled";
import { usePrevious } from "hooks/usePreviousHook";

function AsiromMachineGroupFields() {
  const { watch, setValue } = useFormContext();
  const values = watch();

  const asiromMachineGroupValue = values.inp_group_ut_asirom;

  const asiromMachineTypeOptions =
    asiromMachineTypesAllOptions[values.inp_group_ut_asirom] || [];

  const firstMachineGroupOption = asiromMachineGroupOptions[0]?.value;

  const firstMachineTypeOption = asiromMachineTypeOptions[0]?.value;

  const prevGroupValue = usePrevious(values?.inp_group_ut_asirom);

  useEffect(() => {
    if (values?.inp_group_ut_asirom === firstMachineGroupOption) {
      setValue("inp_type_ut_asirom", firstMachineTypeOption);
    } else if (prevGroupValue) {
      setValue("inp_type_ut_asirom", "");
    }
  }, [asiromMachineGroupValue, setValue]);

  return (
    <>
      <FieldTitle>Asirom</FieldTitle>
      <FieldsContainer>
        <ComboboxField
          required
          name={"inp_group_ut_asirom"}
          label={"bcm.asiromMachineGroup.label"}
          options={asiromMachineGroupOptions}
          value={values.inp_group_ut_asirom}
        />
        <ComboboxField
          required
          name={"inp_type_ut_asirom"}
          label={"bcm.asiromMachineType.label"}
          options={asiromMachineTypeOptions}
          value={values.inp_type_ut_asirom}
        />
        <ComboboxField
          required
          name={"inp_mbk_asirom"}
          label={"bcm.groupOfGoodsMbsAsirom.label"}
          options={asiromGroupOfGoods}
          value={values.inp_mbk_asirom}
        />
      </FieldsContainer>
    </>
  );
}

export default AsiromMachineGroupFields;
