class Session {
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  get(key) {
    const val = localStorage.getItem(key);
    return JSON.parse(val);
  }

  remove(key) {
    return localStorage.removeItem(key);
  }
}

const LocalStorage = new Session();

export { LocalStorage };
