export const groupamaEquipmentGroupOptions = [
  {
    label: "bcm.groupamaEquipmentGroupOption1.label",
    value: "Utilaje agricole nesupuse inmatricularii",
  },
  {
    label: "bcm.groupamaEquipmentGroupOption2.label",
    value: "Utilaje agricole supuse inmatricularii",
  },
  {
    label: "bcm.groupamaEquipmentGroupOption3.label",
    value: "Utilaje de constructii",
  },
];

export const groupamaMachineTypeAllOptions = {
  "Utilaje agricole nesupuse inmatricularii": [
    {
      label: "bcm.agrEquipmentNoRegistrationOption1.label",
      value: "Tractoare agricole, combine si alte utilaje si echipamente",
    },
  ],
  "Utilaje agricole supuse inmatricularii": [
    {
      label: "bcm.agrEquipmentRegistrationOption1.label",
      value: "Tractoare agricole",
    },
  ],
  "Utilaje de constructii": [
    {
      label: "bcm.groupamaConstructionMachineryOption1.label",
      value: "Utilaje de constructii",
    },
  ],
};
