import { QUERY_KEYS } from "library/shared";
import moment from "moment";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";

const createOffer = async (dataObj) => {
  const response = await api.post(`/GetOffer`, {
    appInfo: {
      dataObj: {
        ...dataObj,
        inp_external_contractdate_ut: moment(
          dataObj?.inp_external_contractdate_ut
        ).format("YYYY-MM-DD"),
      },
      resolvedProperties: {
        title: "BCR Heavy Machinery",
      },
    },
  });

  return response.data?.appInfo;
};

export function useCreateOffer() {
  const queryClient = useQueryClient();

  return useMutation(createOffer, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.vehicleList });
    },
    onError: (error) => {
      // handle error
    },
  });
}
