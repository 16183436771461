export const STORAGE_KEYS = {
  darkMode: "dark-mode",
};

export const QUERY_KEYS = {
  userData: "userData",
  vehicleList: "vehicleList",
  excelVehicleList: "excelVehicleList",
  offerListData: "offerListData",
  offerDataItem: "offerDataItem",
  offerCopyDataItem: "offerCopyDataItem",
  uadTaxonomy: "uadTaxonomy",
  appSettings: "appSettings",
  calculationData: "calculationData",
  vehicleByVin: "vehicleByVin",
};

export const notificationTypes = {
  error: "error",
  succes: "success",
  warning: "warning",
  info: "info",
};

export const ROUTES = {
  getVehicleItemPath: ({
    offerId,
    actionType,
    hasInvoice,
    hasPolicy,
  }: {
    offerId?: string | null;
    hasInvoice?: boolean;
    hasPolicy?: boolean;
    actionType?: "copy" | "create" | "preview" | "open";
  }) => {
    const queryParams = new URLSearchParams();

    if (offerId) {
      queryParams.set("offerId", offerId);
    }
    if (hasInvoice) {
      queryParams.set("hasInvoice", "true");
    }

    if (actionType) {
      queryParams.set("actionType", actionType);
    }
    if (hasPolicy) {
      queryParams.set("hasPolicy", "true");
    }
    return "/vehicle?" + queryParams;
  },
};

export const CREATE_OFFER = "create";
export const COPY_OFFER = "copy";
export const PREVIEW_OFFER = "preview";
export const OPEN_OFFER = "open";
