import { ComboboxField } from "components/controls/ComboBoxField";
import React from "react";
import { useFormContext } from "react-hook-form";

import { omniasiEquipmentTypeOptions, omniasigGroupOptions } from "./constants";
import { FieldTitle, FieldsContainer } from "pages/VehicleItem/styled";

function OmniasigGroupFields() {
  const { watch } = useFormContext();
  const values = watch();

  return (
    <>
      <FieldTitle>Omniasig</FieldTitle>
      <FieldsContainer>
        <ComboboxField
          required
          name="inp_group_ut_omn"
          label="bcm.omniasigEquipmentGroup.label"
          value={values.inp_group_ut_omn}
          options={omniasigGroupOptions}
        />
        <ComboboxField
          required
          name="inp_type_ut_omn"
          label="bcm.omniasigEquipmentType.label"
          value={values.inp_type_ut_omn}
          options={omniasiEquipmentTypeOptions}
        />
      </FieldsContainer>
    </>
  );
}

export default OmniasigGroupFields;
