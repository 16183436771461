import React from "react";

import OmniasigGroupFields from "./omniasig/OmniasigGroupFields";
import UniqaGroupFields from "./uniqa/UniqaGroupFields";
import GrupamaEquipmentFields from "./grupama/GrupamaEquipmentFields";
import AllianzGroupFields from "./allianz/AllianzGroupFields";
import { FieldDivider } from "pages/VehicleItem/styled";
import AsiromMachineGroupFields from "./asirom/AsiromMachineGroupFields";

function InsurerSettings() {
  return (
    <>
      <AsiromMachineGroupFields />
      <FieldDivider />
      <UniqaGroupFields />
      <FieldDivider />

      <OmniasigGroupFields />
      <FieldDivider />

      <GrupamaEquipmentFields />
      <FieldDivider />

      <AllianzGroupFields />
    </>
  );
}

export default InsurerSettings;
