export const vehicleConditionOptions = [
  {
    label: "bcm.new.label",
    value: "Nou",
  },
  {
    label: "bcm.used.label",
    value: "Second-hand",
  },
];
