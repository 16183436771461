import { Button, CardFooter } from "@fluentui/react-components";

import { COPY_OFFER, CREATE_OFFER, OPEN_OFFER } from "library/shared";
import React, { useCallback, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import CalculationDialog from "./CalculationDialog";
import { useFormContext } from "react-hook-form";
import {
  requiredFieldsForCalculation,
  requiredFieldsForCreateOffer,
  requiredFieldsForGenerateInvoice,
} from "./constants";
import { useCalculateCasco } from "hooks/data/mutations/useCalculateCasco";
import { ICascoCalculation } from "library/types";
import { Flex, RightSection } from "components/styled";
import { EMainTab, ESidemenuTab } from "pages/VehicleItem/types";
import { useVehicleFormContext } from "providers/VehicleFormProvider";
import { DocumentPdfRegular } from "@fluentui/react-icons";
import usePolicyValueValidation from "../hooks/usePolicyValueValidation";

function FormFooter() {
  const theme = useTheme() as IStyledTheme;
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const offerId = queryParams.get("offerId");

  const { watch } = useFormContext();
  const formData = watch();

  const { isAllianzInsurer, maxPolicyValueCorrect } =
    usePolicyValueValidation(formData);

  const { onNextSideTab, activeSideTab, activeMainTab, onPrevSideTab } =
    useVehicleFormContext();

  const [calculationData, setCalculationData] =
    useState<ICascoCalculation | null>(null);

  const { mutate: onCalculateCasco, isLoading: loadingCalcData } =
    useCalculateCasco();

  const onSetInfoDialog = useCallback((value) => {
    setInfoDialogOpen(value);
  }, []);

  const actionType = queryParams.get("actionType");

  const isOpenOfferAction = actionType === OPEN_OFFER;
  const isCopyAction = actionType === COPY_OFFER;
  const isCreateAction = actionType === CREATE_OFFER;

  const showCalculationActionBtn = isCopyAction || isCreateAction;

  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const hasPolicy = formData?.policyCascoId;

  const hasInvoice = formData?.invoiceCascoId;

  const calculateOfferFieldsFilled = requiredFieldsForCalculation.every(
    (field) => formData[field]
  );

  const createOfferFieldsFilled = requiredFieldsForCreateOffer.every(
    (field) => formData[field]
  );

  const generateInvoiceFieldsFilled = requiredFieldsForGenerateInvoice.every(
    (field) => formData[field]
  );

  const issuePolicyFieldFilled = formData?.validFrom;

  const showActionButtons = !isOpenOfferAction;
  const noOfferCreated = !offerId || isCopyAction;

  const isFirstTab = activeSideTab === ESidemenuTab.general;
  const isLastSideTab = noOfferCreated
    ? activeSideTab === ESidemenuTab.client
    : activeSideTab === ESidemenuTab.cascoOffer;
  const isInvoiceTab = activeMainTab === EMainTab.invoice;
  const isPolicyTab = activeMainTab === EMainTab.policy;
  const isOfferTab = activeMainTab === EMainTab.offer;

  const showNextBtn = !isLastSideTab && !isInvoiceTab && !isPolicyTab;
  const showPrevBtn = !isFirstTab && !isInvoiceTab && !isPolicyTab;

  const showCreateOfferBtn = isCopyAction ? !isInvoiceTab : !offerId;

  const showGenerateInvoiceBtn = isInvoiceTab && !hasInvoice && !hasPolicy;

  const showIssuePolicyBtn = hasInvoice && !hasPolicy && isPolicyTab;

  const showPdfOfferLink = formData?.pathToOfferPdf && isOfferTab;
  const showInvoicePdfLink = formData?.pathToInvoiceCascoPdf && isInvoiceTab;
  const showPolicyPdfLink = formData?.pathToPolicyCascoPdf && isPolicyTab;

  ////

  return (
    <CardFooter
      style={{
        flexDirection: "row",
        padding: "20px",
        borderTop: `1px solid ${theme.palette.titanWhite}`,
      }}
    >
      {showActionButtons && showCalculationActionBtn && (
        <Button
          disabled={!calculateOfferFieldsFilled || loadingCalcData}
          onClick={() => {
            onCalculateCasco(formData, {
              onSuccess(data) {
                setCalculationData(data.dataObj);
                setInfoDialogOpen(true);
              },
            });
          }}
        >
          {t("bcm.infoCasco.label")}
        </Button>
      )}
      {showPdfOfferLink && (
        <Button
          as="a"
          appearance="primary"
          href={formData?.pathToOfferPdf}
          target="_blank"
          icon={<DocumentPdfRegular />}
        >
          {t("bcm.offer.label")}
        </Button>
      )}
      {showInvoicePdfLink && (
        <Button
          as="a"
          appearance="primary"
          href={formData?.pathToInvoiceCascoPdf}
          target="_blank"
          icon={<DocumentPdfRegular />}
        >
          {t("bcm.invoice.label")}
        </Button>
      )}
      {showPolicyPdfLink && (
        <Button
          as="a"
          appearance="primary"
          href={formData?.pathToPolicyCascoPdf}
          target="_blank"
          icon={<DocumentPdfRegular />}
        >
          {t("bcm.policy.label")}
        </Button>
      )}
      <RightSection>
        <Flex gap={10}>
          {showPrevBtn && (
            <Button onClick={onPrevSideTab}>{t("bcm.previous.label")}</Button>
          )}
          {showNextBtn && (
            <Button onClick={onNextSideTab}>{t("bcm.next.label")}</Button>
          )}

          {showCreateOfferBtn && (
            <Button
              disabled={!createOfferFieldsFilled}
              appearance="primary"
              type="submit"
            >
              {t("bcm.getOffer.label")}
            </Button>
          )}
          {showGenerateInvoiceBtn && (
            <Button
              disabled={!generateInvoiceFieldsFilled}
              appearance="primary"
              type="submit"
            >
              {t("bcm.generateInvoice.label")}
            </Button>
          )}
          {showIssuePolicyBtn && (
            <Button
              disabled={
                !issuePolicyFieldFilled ||
                isAllianzInsurer ||
                !maxPolicyValueCorrect
              }
              appearance="primary"
              type="submit"
            >
              {t("bcm.issuePolicy.label")}
            </Button>
          )}
        </Flex>
      </RightSection>

      <CalculationDialog
        open={infoDialogOpen}
        setOpen={onSetInfoDialog}
        calculationData={calculationData}
      />
    </CardFooter>
  );
}

export default FormFooter;
