export const omniasigGroupOptions = [
  {
    label: "bcm.omniasigEquipmentGroupOption1.label",
    value: "Utilaje neinmatriculabile",
  },
  {
    label: "bcm.omniasigEquipmentGroupOption2.label",
    value: "Utilaje inmatriculate",
  },
];

export const omniasiEquipmentTypeOptions = [
  {
    label: "bcm.omniasigEquipmentTypeOption1.label",
    value: "Utilaje de constructii",
  },
  {
    label: "bcm.omniasigEquipmentTypeOption2.label",
    value: "Utilaje agricole",
  },
  {
    label: "bcm.omniasigEquipmentTypeOption3.label",
    value: "Utilaje de speciale",
  },
];
