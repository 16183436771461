import React, { Suspense } from "react";
import { AccountInfo } from "@azure/msal-browser";
import { ReactQueryDevtools } from "react-query/devtools";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { MSGraphAuth } from "./service/GraphService";
import { AppProvider } from "./providers/AppProvider";
import "@greco/components/dist/index.css";
import "react-toastify/dist/ReactToastify.css";

import { QueryClientProvider, QueryClient } from "react-query";
import { GrecoSpinner } from "@greco/components";

import { initializeIcons } from "@uifabric/icons";

import pdfMake from "pdfmake/build/pdfmake.js";

import pdfFonts from "pdfmake/build/vfs_fonts.js";

const APP_URL =
  process.env.REACT_APP_APP_URL ||
  `${window.location.protocol}${window.location.host}`;

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  SegoeUI: {
    normal: `${APP_URL}/fonts/SEGOEUI.ttf`,
    bold: `${APP_URL}/fonts/SEGOEUI.ttf`,
  },
  Remix: {
    normal: `${APP_URL}/fonts/remixicon.ttf`,
  },
  LineAwesome: {
    normal: `${APP_URL}/fonts/la-regular-400.ttf`,
  },
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnMount: true,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
});

const startApplication = () => {
  initializeIcons();

  root.render(
    <React.Fragment>
      <Suspense fallback={<GrecoSpinner />}>
        <QueryClientProvider client={queryClient}>
          <AppProvider>
            <App />
          </AppProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Suspense>
    </React.Fragment>
  );
};

MSGraphAuth.accountObj
  .then((accountInfo: AccountInfo | null) => {
    if (accountInfo) {
      startApplication();
    } else {
      MSGraphAuth.login();
    }
  })
  .catch((error: any) => {
    if (error === "There isn't any account detected.") {
      MSGraphAuth.login();
    } else {
      console.log(error);
    }
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
