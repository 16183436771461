import { Flex } from "components/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";

type Props = {
  insurerData: {
    data: Record<string, string>;
    title: string;
  }[];
};

function InsurerSettingsReadOnly({ insurerData }: Props) {
  const { t } = useTranslation();
  return (
    <GridContainer>
      {insurerData.map((item) => {
        const objKeys = Object.keys(item?.data || []);

        return (
          <Wrap key={item.title}>
            <Title>{item.title.toUpperCase()}</Title>
            {objKeys.map((key) => {
              return (
                <Flex gap={10} key={key}>
                  <Label>{t(key)}: </Label>
                  <Value>{item?.data[key]}</Value>
                </Flex>
              );
            })}
          </Wrap>
        );
      })}
    </GridContainer>
  );
}

export default InsurerSettingsReadOnly;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 5px;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  gap: 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.black};
`;

const Title = styled.span`
  font-weight: 600;
  margin-bottom: 10px;
`;
const Value = styled.span`
  font-weight: 400;
`;
const Label = styled.div`
  display: flex;
  font-weight: 600;
  width: 300px;
`;
