import * as React from "react";

import { ContextualMenu } from "@fluentui/react";

import DeleteOfferDialog from "./columns/DeleteOfferDialog";
import useOfferContextualMenu from "./hooks/useOfferContextualMenu";

export const RightClickContextMenu = ({
  contextualMenu,
  closeContextualMenu,
}) => {
  const offer = contextualMenu?.offer;
  const offerId = offer?.offerId;
  const { items, deleteOfferDialogOpen, toggleDeleteDialogOpen } =
    useOfferContextualMenu({ offer });

  const onHideContextualMenu = React.useCallback(
    () => closeContextualMenu(),
    []
  );

  return (
    <>
      <ContextualMenu
        target={contextualMenu.target}
        items={items}
        hidden={!contextualMenu.open}
        onItemClick={onHideContextualMenu}
        onDismiss={onHideContextualMenu}
      />
      {deleteOfferDialogOpen && (
        <DeleteOfferDialog
          isOpen={deleteOfferDialogOpen}
          offerId={offerId}
          toggleDialogOpen={toggleDeleteDialogOpen}
        />
      )}
    </>
  );
};

export default RightClickContextMenu;
