import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OfferAzureSearch } from "library/types";

import { COPY_OFFER, OPEN_OFFER, ROUTES } from "library/shared";
import { openLinkInNewTab } from "library/utils";

type Props = {
  offer?: OfferAzureSearch;
};

function useOfferContextualMenu({ offer }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [deleteOfferDialogOpen, setDeleteOfferDialogOpen] = useState(false);
  const offerId = offer?.offerId;

  const hasOfferId = !!offerId;

  const deleteOfferDisable = !hasOfferId || !offer?.status.includes("Oferta");

  const toggleDeleteDialogOpen = () => {
    setDeleteOfferDialogOpen((prev) => !prev);
  };

  const onRepeatOffer = async () => {
    const path = ROUTES.getVehicleItemPath({
      offerId: offer.offerId,
      actionType: COPY_OFFER,
    });
    navigate(path);
  };

  const onOpenInNewTab = () => {
    const hasInvoice = offer?.status.includes("Decont");
    const path = ROUTES.getVehicleItemPath({
      offerId: offer.offerId,
      actionType: OPEN_OFFER,
      ...(hasInvoice && { hasInvoice }),
    });
    openLinkInNewTab(path);
  };

  const items = [
    {
      key: "newTab",
      text: t("bcm.openInNewTab.label"),
      iconProps: { iconName: "OpenInNewWindow" },
      disabled: false,
      onClick: onOpenInNewTab,
    },
    {
      key: "invalidateOffer",
      text: t("bcm.invalidateOffer.label"),
      iconProps: { iconName: "ErrorBadge" },
      disabled: deleteOfferDisable,
      onClick: toggleDeleteDialogOpen,
    },
    {
      key: "repeatOffer",
      text: t("bcm.repeatOffer.label"),
      iconProps: { iconName: "Refresh" },
      disabled: false,
      onClick: onRepeatOffer,
    },
  ];
  return {
    items,
    deleteOfferDialogOpen,
    toggleDeleteDialogOpen,
  };
}

export default useOfferContextualMenu;
