import MainPageSubheader from "pages/MainPage/MainPageSubheader/MainPageSubheader";
import React from "react";
import VehicleList from "./VehicleList/VehicleList";

function MainPage() {
  return (
    <>
      <MainPageSubheader />
      <VehicleList />
    </>
  );
}

export default MainPage;
