export const personTypeOptions = [
  {
    label: "bcm.personTypeLegal.label",
    value: "Persoana juridica",
  },
];

export const vehicleUseOptions = [
  {
    label: "bcm.vehicleUseOption1.label",
    value: "Romania",
  },
];

export const paymentMethodOptions = [
  {
    label: "bcm.paymentMethodOption1.label",
    value: "anuala",
  },
  {
    label: "bcm.paymentMethodOption2.label",
    value: "semestriala",
  },
  {
    label: "bcm.paymentMethodOption3.label",
    value: "trimestriala",
  },
  {
    label: "bcm.paymentMethodOption4.label",
    value: "lunara",
  },
];

export const contractCurrencyOptions = [
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "RON",
    value: "RON",
  },
];

export const contractTypeOptions = [
  {
    label: "bcm.contractTypeOption1.label",
    value: "Contract de leasing financiar",
  },
  {
    label: "bcm.contractTypeOption2.label",
    value: "Contract de credit",
  },
];
