import { useQuery, UseQueryResult } from "react-query";
import api from "../../../service/api";
import { QUERY_KEYS } from "library/shared";
import { ISetting } from "library/types";
import { urlFormat } from "library/utils";

const uadUrl = urlFormat(process.env.REACT_APP_BFF_HOST_UAD);

export const getAppSettings = async (appID: number | string) => {
  const response = await api.get(`${uadUrl}/appsettings/${appID}`);

  return response.data;
};

export function useGetAppSettings(
  appId: number | string
): UseQueryResult<ISetting[]> {
  return useQuery([QUERY_KEYS.appSettings], () => getAppSettings(appId), {
    enabled: !!appId,
  });
}
