import { mergeStyleSets } from "@fluentui/react";
import { IStyledTheme } from "theme/types";

export const getClassNames = (theme: IStyledTheme) =>
  mergeStyleSets({
    table: {
      ".ms-List-cell .ms-DetailsRow": {
        background: theme.palette.white,
        borderBottom: `1px solid ${theme.palette?.neutralLighter}`,
      },
      ".ms-List-cell:nth-child(odd) .ms-DetailsRow": {
        background: theme.palette?.themeLighterAlt,
      },
      ".ms-DetailsRow ": {
        height: "30px !important",
      },

      ".ms-List-cell:hover, .ms-List-cell .is-selected": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(187, 187, 187) !important",
      },
      ".ms-List-cell .ms-DetailsRow:hover": {
        background: theme.isDark
          ? "rgb(9, 27, 41) !important"
          : "rgb(187, 187, 187) !important",
      },

      ".ms-DetailsHeader-cellTitle": {
        justifyContent: "flex-start",
      },

      ".ms-DetailsRow-cell": {
        display: "flex",
        justifyContent: "flex-start",
      },

      ".ms-DetailsHeader-cellName ": {
        overflow: "visible",
      },

      ".ms-DetailsHeader-cellName i": {
        marginRight: "5px",
        fontSize: "smaller",
        fontWeight: "400",
      },
    },
  });
