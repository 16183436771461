import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import {
  FluentProvider,
  webDarkTheme,
  webLightTheme,
} from "@fluentui/react-components";
import { ThemeProvider, GlobalStyles, GrecoSpinner } from "@greco/components";
import {
  ThemeProvider as StyledThemeProvider,
  styled,
} from "styled-components";

import AppHeader from "./components/AppHeader/AppHeader";
import { useAppContext } from "./providers/AppProvider";

import "./App.css";
import MainPage from "pages/MainPage/MainPage";
import { darkStyledTheme, styledTheme } from "theme";
import { ToastContainer } from "react-toastify";
import VehicleForm from "pages/VehicleItem/VehicleForm";
import { VehicleFormProvider } from "providers/VehicleFormProvider";
import { MainProvider } from "providers/MainProvider";
import { useLoadUADTaxonomy } from "hooks/data/queries/useLoadUADTaxonomy";
import { useGetAppSettings } from "hooks/data/queries/useGetAppSettings";
import { useTranslation } from "react-i18next";
import { setYupLocale } from "library/validation";

function App() {
  const { darkMode, panelIsOpen } = useAppContext();
  const { t } = useTranslation();

  const { data: taxonomyUad } = useLoadUADTaxonomy();

  const applicationCodeId = taxonomyUad?.ApplicationCode?.find(
    (el) => el.code === "Application.BCM"
  ).id;

  const { isFetched } = useGetAppSettings(applicationCodeId);

  useEffect(() => {
    setYupLocale(t);
  }, [t]);

  if (!isFetched) {
    //important for column options default view (to improve main vehicle list render performace)
    return <GrecoSpinner />;
  }

  return (
    <FluentProvider theme={darkMode ? webDarkTheme : webLightTheme}>
      <ThemeProvider isDarkMode={darkMode}>
        <StyledThemeProvider theme={darkMode ? darkStyledTheme : styledTheme}>
          <MainProvider>
            <AppHeader />

            <Container $isPanelOpen={panelIsOpen}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<MainPage />} />
                  <Route
                    path="/vehicle"
                    element={
                      <VehicleFormProvider>
                        <VehicleForm />
                      </VehicleFormProvider>
                    }
                  />
                  <Route
                    path="/vehicle/:offerId/:actionType"
                    element={<VehicleForm />}
                  />
                </Routes>
              </BrowserRouter>
            </Container>
            <ToastContainer />
            <GlobalStyles />
          </MainProvider>
        </StyledThemeProvider>
      </ThemeProvider>
    </FluentProvider>
  );
}

export default App;

const Container = styled.div<{ $isPanelOpen: boolean }>`
  position: absolute;
  overflow: auto;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  width: ${({ $isPanelOpen }) =>
    $isPanelOpen ? "calc(100% - 340px)" : "100%"};

  background-image: url(./assets/GrECoGraphicTriangles.svg);
  background-size: auto;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
`;
