import { ComboboxField } from "components/controls/ComboBoxField";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  oldUniqaMachineOptions,
  uniqaEquipmentGroupOptions,
  uniqaGroupOfGoodsAllOptions,
  uniqaInactivityPeriodOptions,
} from "./constants";
import { FieldTitle, FieldsContainer } from "pages/VehicleItem/styled";
import { usePrevious } from "hooks/usePreviousHook";

function UniqaGroupFields() {
  const { watch, setValue } = useFormContext();
  const values = watch();

  const uniqaGroupOfGoodsOptions =
    uniqaGroupOfGoodsAllOptions[values.inp_group_ut_uniqa] || [];

  const uniqaEquipmentGroupValue = values?.inp_group_ut_uniqa;

  const firstEquipmentGroupOption = uniqaEquipmentGroupOptions[0]?.value;

  const firstGroupOfGoodsOption = uniqaGroupOfGoodsOptions[0]?.value;

  const prevGroupValue = usePrevious(values?.inp_group_ut_uniqa);

  useEffect(() => {
    if (values?.inp_group_ut_uniqa === firstEquipmentGroupOption) {
      setValue("inp_mbk_uniqa", firstGroupOfGoodsOption);
    } else if (prevGroupValue) {
      setValue("inp_mbk_uniqa", "");
    }
  }, [uniqaEquipmentGroupValue, setValue]);

  return (
    <>
      <FieldTitle>Uniqa</FieldTitle>
      <FieldsContainer>
        <ComboboxField
          required
          name="inp_group_ut_uniqa"
          label="bcm.uniqaEquipmentGroup.label"
          value={values.inp_group_ut_uniqa}
          options={uniqaEquipmentGroupOptions}
        />
        <ComboboxField
          required
          name="inp_mbk_uniqa"
          label="bcm.uniqaGroupOfGoods.label"
          value={values.inp_mbk_uniqa}
          options={uniqaGroupOfGoodsOptions}
        />
        <ComboboxField
          required
          name="inp_usage_ut"
          label="bcm.oldUniqaMachine.label"
          value={values.inp_usage_ut}
          options={oldUniqaMachineOptions}
        />
        <ComboboxField
          required
          name="inp_inactivity_ut"
          label="bcm.uniqaInactivityPeriod.label"
          value={values.inp_inactivity_ut}
          options={uniqaInactivityPeriodOptions}
        />
      </FieldsContainer>
    </>
  );
}

export default UniqaGroupFields;
