import { ChoiceGroup } from "@fluentui/react";
import { Checkbox } from "@fluentui/react-components";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const Flex = styled.div<{
  gap?: number;
  direction?: "row" | "column";
  justify?: "center" | "flex-end" | "flex-start" | "space-between";
  align?: "center" | "flex-end" | "flex-start" | "normal";
  wrap?: "nowrap" | "wrap";
}>`
  display: flex;
  align-items: ${(p) => p?.align || "center"};
  gap: ${(p) => (p.gap !== undefined ? `${p.gap}px` : undefined)};
  flex-direction: ${(p) => p.direction};
  justify-content: ${(p) => p.justify};
  flex-wrap: ${(p) => p.wrap || "wrap"};
`;

export const StyledNavigationBar = styled.div`
  height: 44px;
  background: ${(p) => p.theme.palette.white};
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  border-bottom: 1px solid ${(p) => p.theme.palette.neutralLight};
  position: sticky;
  top: 0;
  z-index: 333;
`;

export const StyledCheckbox = styled(Checkbox)<{ background?: string }>`
  & > label > div {
    width: 16px;
    height: 16px;
    margin-top: 2px;
  }
  & > label > span {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const CenteredCell = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLink = styled(Link)`
  flex: 1;
  text-align: left;
  min-width: 0;
  text-decoration: underline;

  color: ${(p) => p.theme.palette.neutralPrimary};
  &:visited {
    color: ${(p) => p.theme.palette.neutralPrimary};
  }
  &:hover {
    color: ${(p) => p.theme.palette.themePrimary};
  }
`;

export const RightSection = styled.div`
  margin-left: auto;
`;

export const HorizontalChoiceGroup = styled(ChoiceGroup)`
  .ms-ChoiceFieldGroup {
    display: block;
  }
  .ms-ChoiceFieldGroup-flexContainer {
    display: flex;
    flex-wrap: wrap;
  }
  .ms-ChoiceField {
    width: 25%;
    flex-grow: 1;
  }
`;

export const StyledErrorMessage = styled.span`
  color: ${({ theme }) => theme.semanticColors.errorText};
  font-size: 12;
`;
