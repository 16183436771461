import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import { getUserDetails } from "service/GraphService";
import { getAppSettings } from "hooks/data/queries/useGetAppSettings";
import { urlFormat } from "library/utils";
import PhraseInContextEditorPostProcessor from "i18next-phrase-in-context-editor-post-processor";
import queryString from "query-string";

const phraseProjectId =
  process.env.REACT_APP_PHRASE_ID || "6bcadb0a360c1e31644ffe97cd739340";

const defaultLanguage = "en-GB";

export const applicationCodeId =
  process.env.REACT_APP_APPLICATION_BCM || 900000000010215;
export const appSettingUserSettingsCodeId =
  process.env.REACT_APP_USER_SETTINGS || 900000000010304;

const phraseUrl = urlFormat(process.env.REACT_APP_PHRASE_BASE_URL);

export const inContextEditorPostProcessor =
  new PhraseInContextEditorPostProcessor({
    phraseEnabled: !!queryString.parse(window.location.search).phrase_context,
    // @ts-ignore
    projectId: phraseProjectId,
    useOldICE: true,
    // accountId: phraseAccountId,

    autoLowercase: false,
  });

const languageDetector = {
  type: "languageDetector" as any, // Exact type is 'backend' | 'logger' | 'languageDetector' | 'postProcessor' | 'i18nFormat' | '3rdParty'
  async: true, // flags below detection to be async
  detect: (callback: Function) =>
    getAppSettings(Number(applicationCodeId)!)
      .then((userSettings) => {
        const userSettingsForViewOptions: any[] = userSettings.filter(
          (item: any) =>
            `${item.appSettingCodeId}` === appSettingUserSettingsCodeId
        );
        if (userSettingsForViewOptions.length) {
          callback(
            userSettingsForViewOptions.find(
              (userSetting: any) => userSetting.isDefault
            )?.userAppSettingValue || defaultLanguage
          );
        } else {
          getUserDetails()
            .then((userData) =>
              callback(userData.preferredLanguage || defaultLanguage)
            )
            .catch(() => callback(defaultLanguage));
        }
      })
      .catch(() =>
        getUserDetails()
          .then((userData) =>
            callback(userData.preferredLanguage || defaultLanguage)
          )
          .catch(() => callback(defaultLanguage))
      ),
  init: () => {},
  cacheUserLanguage: () => {},
};
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .use(languageDetector)
  .use(inContextEditorPostProcessor)
  .init({
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    load: "currentOnly",
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: `${phraseUrl}/{{lng}}.json`,
    },
    fallbackLng: (code) => {
      if (!code || code === "en-US") return ["en-GB", "de-AT"];

      if (code === "de-DE") return ["de-AT", "en-GB"];
      if (code === "de-CH") return ["de-AT", "en-GB"];
      if (code === "de-AT") return ["de-AT", "en-GB"];

      return ["en-GB", "de-AT"];
    },
    missingKeyHandler: (lngs, ns, key, fallbackValue) => {
      // Check if the key is available in 'en-GB', and if so, return its value

      if (lngs[1] === "en-GB") {
        return i18n.t(key, { lng: "en-GB" });
      }

      // If not found in 'en-GB', return the fallback value
      // console.warn(`Missing translation key: ${key}`);
      return fallbackValue;
    },
    saveMissing: true,

    supportedLngs: [
      "en-GB",
      "de-AT",
      "bg-BG",
      "cs-CZ",
      "et-EE",
      "hr-HR",
      "hu-HU",
      "lt-LT",
      "pl-PL",
      "ro-RO",
      "ru-RU",
      "sr-Latn-RS",
      "sk-SK",
      "sl-SI",
      "tr-TR",
      "uk-UA",
    ],
  });

export default i18n;
(window as any).i18n = i18n;
