import { SearchBox } from "@fluentui/react";
import { QUERY_KEYS } from "library/shared";
import { debounce } from "library/utils";
import { useMainContext } from "providers/MainProvider";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

const searchBoxStyles = {
  root: {
    height: 30,
    width: 300,
    marginLeft: 10,
    border: "1px solid",
    selectors: {
      "&::after": {
        borderWidth: 1,
      },
    },
  },
};

export const Search = () => {
  const queryClient = useQueryClient();
  const { onSetParams, params } = useMainContext();
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState(params.search);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((searchValue) => {
      if (searchValue || searchValue === "") {
        onSetParams({
          skip: 0,
          search: searchValue || "",
        });
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (!params.search) {
      setSearchInput("");
    }
  }, [params.search]);

  return (
    <div>
      <SearchBox
        value={searchInput}
        onClear={() => {
          queryClient.removeQueries(QUERY_KEYS.vehicleList);
          onSetParams({
            skip: 0,
            search: "",
          });
        }}
        styles={searchBoxStyles}
        placeholder={t("greco.form.searchPlaceholder")}
        onChange={(_, value) => {
          setSearchInput(value);
          handleSearch(value);
        }}
      />
    </div>
  );
};
