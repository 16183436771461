import React from "react";

import {
  CertificateRegular,
  ClipboardSettingsFilled,
  DocumentBulletListRegular,
  FormRegular,
  MoneyHandRegular,
  PersonNoteRegular,
  VehicleCarRegular,
} from "@fluentui/react-icons";

import GeneralFormItems from "./VehicleFormItems/GeneralFormItems/GeneralFormItems";
import ReadOnlyFields from "./ReadOnlyFields/ReadOnlyFields";
import VehicleItems from "./VehicleFormItems/VehicleItems/VehicleItem";
import { EMainTab, ESidemenuTab } from "./types";
import InsurerSettings from "./VehicleFormItems/InsurerSettings/InsurerSettings";
import OfferPreview from "./OfferPreview/OfferPreview";
import InvoiceForm from "./VehicleFormItems/InvoiceForm/InvoiceForm";
import PolicyForm from "./VehicleFormItems/PolicyForm/PolicyForm";
import ClientFormItems from "./VehicleFormItems/ClientFormItems/ClientFormItems";
import InsurerSettingsReadOnly from "./VehicleFormItems/InsurerSettings/InsurerSettingsReadOnly";

export const getTabConfig = ({
  readOnlyData,
  offerTabsAreReadonly,
  noOfferCreated,
  noInvoiceCreated,
  hasPolicy,
  noOffer,
}) => ({
  [EMainTab.offer]: {
    value: EMainTab.offer,
    label: "bcm.offer.label",
    Icon: <DocumentBulletListRegular />,
    disabled: false,

    sideTabs: [
      {
        value: ESidemenuTab.general,
        Icon: <FormRegular />,
        label: "bcm.general.label",
        disabled: false,

        content: offerTabsAreReadonly ? (
          <ReadOnlyFields data={readOnlyData[ESidemenuTab.general]} />
        ) : (
          <GeneralFormItems />
        ),
      },
      {
        value: ESidemenuTab.vehicle,
        Icon: <VehicleCarRegular />,
        disabled: false,

        label: "bcm.vehicle.label",
        content: offerTabsAreReadonly ? (
          <ReadOnlyFields data={readOnlyData[ESidemenuTab.vehicle]} />
        ) : (
          <VehicleItems />
        ),
      },
      {
        value: ESidemenuTab.insurerSettings,
        Icon: <ClipboardSettingsFilled />,
        label: "bcm.insurerSettings.label",
        disabled: false,

        content: offerTabsAreReadonly ? (
          <InsurerSettingsReadOnly
            insurerData={readOnlyData[ESidemenuTab.insurerSettings]}
          />
        ) : (
          <InsurerSettings />
        ),
      },
      {
        value: ESidemenuTab.client,
        label: "bcm.client.label",
        Icon: <PersonNoteRegular />,
        disabled: false,
        content: offerTabsAreReadonly ? (
          <ReadOnlyFields data={readOnlyData[ESidemenuTab.client]} />
        ) : (
          <ClientFormItems />
        ),
      },
      {
        value: ESidemenuTab.cascoOffer,
        label: "bcm.offer.label",
        disabled: noOfferCreated,
        Icon: <DocumentBulletListRegular />,
        content: <OfferPreview />,
      },
    ],
  },
  [EMainTab.invoice]: {
    value: EMainTab.invoice,
    label: "bcm.invoice.label",
    disabled: noOfferCreated || noOffer,
    Icon: (
      <MoneyHandRegular
        style={{
          opacity: noOfferCreated ? "0.5" : "1",
        }}
      />
    ),
    sideTabs: [
      {
        value: "bcm.invoice.label",
        label: "CASCO Invoice",
        Icon: <MoneyHandRegular />,
        content: noInvoiceCreated ? (
          <InvoiceForm />
        ) : (
          <ReadOnlyFields data={readOnlyData["invoice"]} />
        ),
      },
    ],
  },
  [EMainTab.policy]: {
    value: EMainTab.policy,
    label: "bcm.policy.label",
    disabled: noOfferCreated || noInvoiceCreated,
    Icon: (
      <CertificateRegular
        style={{
          opacity: noInvoiceCreated ? "0.5" : "1",
        }}
      />
    ),
    sideTabs: [
      {
        value: "bcm.policy.label",
        label: "CASCO Policy",
        disabed: noOfferCreated || noInvoiceCreated,
        Icon: <CertificateRegular />,
        content: hasPolicy ? (
          <ReadOnlyFields data={readOnlyData["policy"]} />
        ) : (
          <PolicyForm />
        ),
      },
    ],
  },
});
