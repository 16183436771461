import React, { useEffect } from "react";

import { Card, Tab, TabList } from "@fluentui/react-components";
import { styled } from "styled-components";
import { Flex } from "components/styled";

import FormFooter from "./FormFooter/FormFooter";
import { useVehicleFormContext } from "providers/VehicleFormProvider";
import { useTranslation } from "react-i18next";
import { EMainTab } from "../types";

function VehicleFormItems() {
  const {
    activeMainTab,
    onMainTabSet,
    activeSideTab,
    offerData,
    onSideTabSet,
    isLoadingOfferData,
    tabConfig,
  } = useVehicleFormContext();
  const { t } = useTranslation();
  const activeContent = tabConfig[activeMainTab]?.sideTabs.find(
    (item) => item.value === activeSideTab
  )?.content;

  useEffect(() => {
    //set active tabs based on offer data
    if (isLoadingOfferData) return;

    const offerId = offerData?.dataObj.offerId;
    const hasPolicy = offerData?.dataObj.policyCascoId;
    const hasInvoice = offerData?.dataObj.invoiceCascoId;

    // if (hasPolicy || hasInvoice) {
    //   onMainTabSet(EMainTab.policy);
    //   const sideTab = tabConfig[EMainTab.policy]?.sideTabs[0].value;
    //   onSideTabSet(sideTab);
    //   return;
    // }
    if (hasPolicy) {
      onMainTabSet(EMainTab.policy);
      const sideTab = tabConfig[EMainTab.policy]?.sideTabs[0].value;
      onSideTabSet(sideTab);
      return;
    }
    if (hasInvoice) {
      onMainTabSet(EMainTab.invoice);
      const sideTab = tabConfig[EMainTab.invoice]?.sideTabs[0].value;
      onSideTabSet(sideTab);
      return;
    }

    // if (offerId) {
    //   onMainTabSet(EMainTab.invoice);
    //   const sideTab = tabConfig[EMainTab.invoice]?.sideTabs[0].value;
    //   onSideTabSet(sideTab);
    // }
  }, [offerData, isLoadingOfferData]);

  return (
    <div>
      <MainTabList selectedValue={activeMainTab} size="large">
        {/* main tabs */}
        {Object.keys(tabConfig).map((config) => {
          const item = tabConfig[config];
          return (
            <Tab
              disabled={item.disabled}
              key={item.value}
              icon={item.Icon}
              value={item.value}
              onClick={() => {
                onMainTabSet(item.value);
                onSideTabSet(item.sideTabs[0].value);
              }}
            >
              {t(item.label)}
            </Tab>
          );
        })}
      </MainTabList>
      <StyledCard>
        <Flex align="normal" wrap="nowrap">
          <SidemenuTabList selectedValue={activeSideTab} vertical size="large">
            {/* side menu tabs */}
            {tabConfig[activeMainTab]?.sideTabs.map((item) => {
              return (
                <Tab
                  key={item.value}
                  icon={item.Icon}
                  value={item.value}
                  disabled={item.disabled}
                  onClick={() => {
                    onSideTabSet(item.value);
                  }}
                >
                  {t(item.label)}
                </Tab>
              );
            })}
          </SidemenuTabList>
          <Content>{activeContent}</Content>
        </Flex>
        <FormFooter />
      </StyledCard>
    </div>
  );
}

export default VehicleFormItems;

const StyledCard = styled(Card)`
  width: 1200px;
  margin: 20px auto 20px;
  padding: 0;
  gap: 0;
  border-top: ${({ theme }) => `5px solid ${theme.palette.themePrimary}`};
`;

const MainTabList = styled(TabList)`
  justify-content: center;
  margin: 20px auto;
`;

const SidemenuTabList = styled(TabList)`
  width: 250px;
  padding: 20px 0 200px 0;
  margin: 0;
  justify-content: flex-start;
  box-sizing: border-box;
  background: ${({ theme }) => theme.palette.titanWhite};
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 40px;
  box-sizing: border-box;
`;
