import { useMutation, useQueryClient } from "react-query";
import { QUERY_KEYS } from "library/shared";
import api from "service/api";
import { urlFormat } from "library/utils";

const uadUrl = urlFormat(process.env.REACT_APP_BFF_HOST_UAD);

const addAppSetting = async (setting) => {
  return await api.post(`${uadUrl}/addappsettings`, [setting]);
};

export function useAddAppSettings() {
  const queryClient = useQueryClient();

  return useMutation(addAppSetting, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS.appSettings);
    },
    onError: (error) => {},
  });
}
