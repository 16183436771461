export const allianzEquipmentGroupOptions = [
  {
    label: "bcm.allianzEquipmentGroupOption1.label",
    value: "Utilaje si masini agricole",
  },
  {
    label: "bcm.allianzEquipmentGroupOption2.label",
    value: "Alte utilaje neutilizate pe santiere de constructii",
  },
  {
    label: "bcm.allianzEquipmentGroupOption3.label",
    value: "Utilaje de constructii",
  },
  {
    label: "bcm.allianzEquipmentGroupOption4.label",
    value: "Utilaje de asfaltat",
  },
];

export const allianzMachineTypesAllOptions = {
  "Utilaje si masini agricole": [
    {
      label: "bcm.agrMachinesAndEquipmentOption1.label",
      value: "Combine",
    },
    {
      label: "bcm.agrMachinesAndEquipmentOption2.label",

      value: "Tractoare agricole",
    },
    {
      label: "bcm.agrMachinesAndEquipmentOption3.label",

      value: "Screper, freze, motocositoare, masini de ierbicidat, dislocator",
    },
    {
      label: "bcm.agrMachinesAndEquipmentOption4.label",
      value: "Alte utilaje agricole - accesorii",
    },
  ],
  "Alte utilaje neutilizate pe santiere de constructii": [
    {
      label: "bcm.allianzOtherMachinesOption1.label",
      value: "Motostivuitoare, elevatoare, incarcatoare",
    },
  ],

  "Utilaje de constructii": [
    {
      label: "bcm.constructionMachineryOption1.label",
      value: "Buldozer, Buldoexcavator",
    },
    {
      label: "bcm.constructionMachineryOption2.label",
      value: "Exacavatoare (pe roti)",
    },
    {
      label: "bcm.constructionMachineryOption3.label",
      value: "Exacavatoare (pe senile)",
    },
    {
      label: "bcm.constructionMachineryOption4.label",
      value: "Miniexacavatoare",
    },
    {
      label: "bcm.constructionMachineryOption5.label",
      value: "Autogreder, Greder",
    },
    {
      label: "bcm.constructionMachineryOption6.label",
      value: "Macara turn, macara fereastra, pod rulant",
    },
    {
      label: "bcm.constructionMachineryOption7.label",
      value: "Automacara",
    },
    {
      label: "bcm.constructionMachineryOption8.label",
      value: "Autobetoniere, Autobasculante",
    },
    {
      label: "bcm.constructionMachineryOption9.label",
      value: "Platforme autoridicatoare, elevatoare, troliu, vinci",
    },
    {
      label: "bcm.constructionMachineryOption10.label",
      value: "Masini forat piloti",
    },
    {
      label: "bcm.constructionMachineryOption11.label",
      value: "Masini sapat santuri",
    },
    {
      label: "bcm.constructionMachineryOption12.label",
      value: "Pompe beton fixe, malaxor mortar, betoniera fixa",
    },
    {
      label: "bcm.constructionMachineryOption13.label",
      value: "Pompe beton pe auto, instalatie de injectat beton",
    },
    {
      label: "bcm.constructionMachineryOption14.label",
      value: "Compresoare, generatoare Diesel, motopompe / remorca",
    },
    {
      label: "bcm.constructionMachineryOption15.label",
      value: "Vibrator interior, masa vibranta",
    },
    {
      label: "bcm.constructionMachineryOption16.label",
      value: "Motostivuitor, stivuitor, incarcator cu ghiare",
    },
    {
      label: "bcm.constructionMachineryOption17.label",
      value: "Incarcator pe roti, senile",
    },
    {
      label: "bcm.constructionMachineryOption18.label",
      value: "Concasor, Ciur, Clasificator, Spalator, Sortator de agregate",
    },
    {
      label: "bcm.constructionMachineryOption19.label",
      value: "Baraci, Birouri de santier, Ateliere, Aeroterme",
    },
    {
      label: "bcm.constructionMachineryOption20.label",
      value: "Schele, cofraje glisante, cofraje mobile",
    },
    {
      label: "bcm.constructionMachineryOption21.label",
      value: "Statie de betoane",
    },
    {
      label: "bcm.constructionMachineryOption22.label",
      value: "Statie de betoane mobila",
    },
    {
      label: "bcm.constructionMachineryOption23.label",
      value: "Alte echipamente",
    },
  ],
  "Utilaje de asfaltat": [
    {
      label: "bcm.allianzAsphaltingMachinesOption1.label",
      value: "Masini de preparat si turnat covor asfaltatic",
    },
    {
      label: "bcm.allianzAsphaltingMachinesOption2.label",
      value: "Cilindru compactor, vibrocompactor, freze rutiere",
    },
    {
      label: "bcm.allianzAsphaltingMachinesOption3.label",
      value: "Statie mixtura asfaltica fixa",
    },
    {
      label: "bcm.allianzAsphaltingMachinesOption4.label",
      value: "Statie mixtura asfaltica mobila",
    },
    {
      label: "bcm.allianzAsphaltingMachinesOption5.label",
      value: "Malaxor mixture asfaltice si suspensii buituminoase",
    },
    {
      label: "bcm.allianzAsphaltingMachinesOption6.label",
      value: "Alte echipamente",
    },
  ],
};
