import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { styled } from "styled-components";
import { ErrorMessage } from "@hookform/error-message";
import { StyledErrorMessage } from "components/styled";
import { DatePicker } from "@fluentui/react-datepicker-compat";

import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";

type Props = {
  name: string;
  value?: Date;
  label: string;
  required?: boolean;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
};

const onFormatDate = (value?: any): string => {
  const date = new Date(value);
  return !date
    ? ""
    : ((date?.getDate() + "").length === 1 ? "0" : "") +
        date?.getDate() +
        "." +
        ((date?.getMonth() + 1 + "").length === 1 ? "0" : "") +
        (date?.getMonth() + 1) +
        "." +
        date?.getFullYear();
};

const DatePickerField = ({
  name,
  value,
  label,
  maxDate,
  minDate,
  required = false,
  disabled = false,
}: Props) => {
  const datePickerRef = React.useRef<any>(null);
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const onParseDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = value || new Date();
      const newValueParts = (newValue || "").trim().split("/");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year +=
          previousValue.getFullYear() - (previousValue.getFullYear() % 100);
      }

      return new Date(year, month, day);
    },
    [value]
  );

  React.useEffect(() => {
    if (value) {
      setValue(name, value);
    }
  }, []);

  return (
    <Container>
      <FormFieldLabel disabled={disabled} label={label} required={required} />

      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <DatePicker
              disabled={disabled}
              appearance="underline"
              ref={datePickerRef}
              allowTextInput
              value={field.value ? new Date(field.value) : new Date()}
              onSelectDate={(date?: Date | null) => {
                setValue(name, date);
              }}
              maxDate={maxDate}
              minDate={minDate}
              formatDate={onFormatDate}
              parseDateFromString={onParseDateFromString}
              placeholder="Select a date..."
            />
          );
        }}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <StyledErrorMessage>{message}</StyledErrorMessage>
        )}
      />
    </Container>
  );
};

export default DatePickerField;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
`;
