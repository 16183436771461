import DatePickerField from "components/controls/DatePickerField";
import { StyledErrorMessage } from "components/styled";
import { FieldsContainer } from "pages/VehicleItem/styled";
import { useVehicleFormContext } from "providers/VehicleFormProvider";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import yup from "validation";
import usePolicyValueValidation from "../hooks/usePolicyValueValidation";
import { firstLetterUpper, formatNumber } from "library/utils";
import { useTranslation } from "react-i18next";

function PolicyForm() {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const { onUpdateValidationSchemaObj } = useVehicleFormContext();
  const values = watch();
  const policyCurrency = values?.inp_policy_currency_ut;
  const { isAllianzInsurer, maxPolicyValueCorrect, insurer, maxPolicyValue } =
    usePolicyValueValidation(values);

  useEffect(() => {
    onUpdateValidationSchemaObj({
      validFrom: yup.mixed().required(),
    });
  }, []);

  const maxPolicyValueErrorMsg =
    !maxPolicyValueCorrect &&
    `${t("bcm.maximumValuePolicyError.label", {
      insurer: firstLetterUpper(insurer),
      value: formatNumber(maxPolicyValue),
    })} ${policyCurrency}`;

  return (
    <>
      <FieldsContainer>
        <DatePickerField
          disabled={isAllianzInsurer || !maxPolicyValueCorrect}
          name="validFrom"
          label="bcm.startDate.label"
          minDate={new Date()}
          value={new Date(values.validFrom) || null}
          // maxDate={null}
        />
      </FieldsContainer>
      {isAllianzInsurer && (
        <StyledErrorMessage
          style={{
            marginTop: 10,
          }}
        >
          {t("bcm.allianzPolicyError.label")}
        </StyledErrorMessage>
      )}
      {!maxPolicyValueCorrect && (
        <StyledErrorMessage
          style={{
            marginTop: 10,
          }}
        >
          {maxPolicyValueErrorMsg}
        </StyledErrorMessage>
      )}
    </>
  );
}

export default PolicyForm;
