import { styled } from "styled-components";

export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  grid-gap: 20px;
`;

export const FieldDivider = styled.div`
  margin: 25px 0;
`;

export const FieldTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 25px;
`;
