import React from "react";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableHeaderCell,
  TableCell,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { offerConfig } from "./config";
import { Flex } from "components/styled";
import { styled } from "styled-components";
import moment from "moment";
import { useLocation } from "react-router";
import { useLoadOfferData } from "hooks/data/queries/useLoadOfferData";
import { ICascoCalculation } from "library/types";
import { formatDateString } from "library/utils";

type Props = {
  infoCalculationData?: ICascoCalculation;
};

function OfferPreview({ infoCalculationData }: Props) {
  const { t } = useTranslation();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const offerId = queryParams.get("offerId");

  // const actionType = queryParams.get("actionType");
  const hasOfferId = !!offerId;

  const { data } = useLoadOfferData(Number(offerId) ? offerId : null);

  const calculationData = infoCalculationData
    ? infoCalculationData
    : data?.dataObj;

  const formattedExchangeRateDate = moment(
    calculationData?.EURExchangeRateDate
  ).format("DD.MM.YYYY");

  if (!calculationData) return;

  const offerExist = offerConfig.some((row) => {
    return (
      calculationData[row.totalPremiumField] !== "0 EUR" &&
      calculationData[row.totalPremiumField] !== "0 RON"
    );
  });

  if (!offerExist) {
    return <div>No offer</div>;
  }

  return (
    <div>
      <Table
        style={{
          width: "100%",
        }}
      >
        <TableHeader as="thead">
          <TableRow as="tr">
            <TableHeaderCell></TableHeaderCell>
            <TableHeaderCell colSpan={2}>
              {t("bcm.equipmentInsurance.label")}
            </TableHeaderCell>
            <TableHeaderCell colSpan={2}>
              {t("bcm.damageInsurance.label")}
            </TableHeaderCell>
            <TableHeaderCell colSpan={1}>
              {t("bcm.totalPremium.label")}
            </TableHeaderCell>
          </TableRow>
          <TableRow as="tr">
            <TableCell> </TableCell>
            <TableHeaderCell> {t("bcm.quota.label")}</TableHeaderCell>
            <TableHeaderCell>{t("bcm.received.label")}</TableHeaderCell>
            <TableHeaderCell> {t("bcm.quota.label")}</TableHeaderCell>
            <TableHeaderCell>{t("bcm.received.label")}</TableHeaderCell>
            <TableHeaderCell></TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody as="tbody">
          {offerConfig.map((row) => {
            if (
              calculationData[row.totalPremiumField] === "0 EUR" ||
              calculationData[row.totalPremiumField] === "0 RON"
            ) {
              return null;
            }
            return (
              <TableRow key={row.name} as="tr">
                <TableCell>{row.name}</TableCell>
                {row.fields.map((item) => {
                  let value = calculationData[item];

                  return <TableCell key={item}>{value}</TableCell>;
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Flex justify="space-between" align="flex-start">
        <div>
          <Flex align="center" gap={10}>
            <Label>{`${t("bcm.eurExchangeRate.label")} :`}</Label>
            <Value>
              {calculationData?.EURExchangeRate} {" RON"}
            </Value>
          </Flex>
          <Flex align="center" gap={10}>
            <Label>{`${t("bcm.eurExchangeRateDate.label")}:`}</Label>
            <Value>{formattedExchangeRateDate}</Value>
          </Flex>
        </div>
        {calculationData?.offerId && (
          <div>
            <Flex align="center" gap={10}>
              <Label>{`${t("bcm.offers.offerId.label")}:`}</Label>
              <Value data-id={"offer-id"}>{calculationData?.offerId}</Value>
            </Flex>
            <Flex align="center" gap={10}>
              <Label>{`${t("bcm.offers.offerRequestedAt.label")}:`}</Label>
              <Value data-id={"request-date"}>
                {formatDateString(calculationData?.offerRequestedAt)}
              </Value>
            </Flex>
            <Flex align="center" gap={10}>
              <Label>{`${t("bcm.offers.offerRequestedByName.label")}:`}</Label>
              <Value data-id={"offer-made-by"}>
                {calculationData?.offerRequestedByName}
              </Value>
            </Flex>
          </div>
        )}
      </Flex>
    </div>
  );
}

export default OfferPreview;

const Label = styled.div`
  margin-top: 10px;
  font-weight: 400;
`;

const Value = styled.div`
  margin-top: 10px;

  font-weight: 600;
`;
