import React from "react";
import { styled } from "styled-components";

function RequiredAsterisk() {
  return <Container>*</Container>;
}

export default RequiredAsterisk;

const Container = styled.span`
  color: ${({ theme }) => theme.semanticColors.errorText};
`;
