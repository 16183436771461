import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CommandBarButton } from "@fluentui/react";
import { useMainContext } from "providers/MainProvider";
import { getVehicleList } from "hooks/data/queries/useGetVehicleList";
import { hydrateOffers, transformVehiclesForExcel } from "library/utils";
import { ExportExcelVehicles } from "./ExportExcelVehicles";

type Props = {
  disabled: boolean;
  refExcelExport: React.MutableRefObject<any>;
};

export const ExportExcel = ({ refExcelExport, disabled }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const getVehicleExcelData = async () => {
    try {
      setLoading(true);
      const response = await getVehicleList(
        {
          ...params,
          skip: 0,
          top: 1000000,
        },
        columns
      );
      let newOffers = response.results.data.value;
      const data = hydrateOffers(newOffers);

      refExcelExport?.current.save(
        transformVehiclesForExcel({
          vehicles: data,
          columns: columns.filter((c) => c.isVisible),
          t,
        })
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const { columns, params } = useMainContext();

  return (
    <>
      <CommandBarButton
        disabled={disabled || loading}
        iconProps={{
          iconName: loading ? "ProgressRingDots" : "ExcelDocument",
        }}
        text={t("greco.exportExcel")}
        onClick={getVehicleExcelData}
      />
      <ExportExcelVehicles ref={refExcelExport} />
    </>
  );
};
