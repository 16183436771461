import React from "react";

import { Button } from "@fluentui/react-components";
import { ArrowClockwiseFilled } from "@fluentui/react-icons";
import { useMainContext } from "providers/MainProvider";
import { QUERY_KEYS } from "library/shared";
import { useQueryClient } from "react-query";

export const RefreshButton = () => {
  const queryClient = useQueryClient();

  const { onResetParams } = useMainContext();
  const loading = false;

  return (
    <Button
      disabled={loading}
      appearance="subtle"
      icon={<ArrowClockwiseFilled fontSize={24} />}
      onClick={() => {
        queryClient.removeQueries(QUERY_KEYS.vehicleList);
        onResetParams();
      }}
    ></Button>
  );
};
