import * as React from "react";
import { Button, makeStyles } from "@fluentui/react-components";
import { GrecoHeader } from "@greco/components";

import i18n from "../../i18n";

import Logo from "../../assets/GrECo_Logo_small.jpg";

import { getHeaderConfig } from "./utils";
import { logout } from "../../service/GraphService";
import { useAppContext } from "../../providers/AppProvider";
import styled from "styled-components";
import { SupportedLanguage } from "library/types";
import { IDropdownOption } from "@fluentui/react";
import { useTranslation } from "react-i18next";

import { useLoadUADTaxonomy } from "hooks/data/queries/useLoadUADTaxonomy";
import { useUserData } from "hooks/data/queries/useUserData";
import { useAddAppSettings } from "hooks/data/mutations/useAddAppSettings";
import { useGetAppSettings } from "hooks/data/queries/useGetAppSettings";
import { NotificationMessages } from "components/AppHeader/NotificationMessages";
import { AlertOff20Regular } from "@fluentui/react-icons";

export const useErrorHandlerClasses = makeStyles({
  contentContainer: {
    whiteSpace: "initial",
    display: "flex",
    flexDirection: "column",
  },
  copyTo: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  copyToIcon: { height: "17px" },
  notificationMessage: {
    cursor: "pointer",
  },
});

const languageList: { [key: string]: string } = {
  "Language.EN_GB": "en-GB",
  "Language.RO_RO": "ro-RO",
};

const AppHeader = () => {
  const { t } = useTranslation();
  const {
    onSetDarkMode,
    onSetPanelIsOpen,
    notifications,
    onClearNotifications,
  } = useAppContext();
  const { mutate: addAppSettings } = useAddAppSettings();
  const { mutate: updateAppSetting } = useAddAppSettings();
  const { data: taxonomyUad } = useLoadUADTaxonomy();

  const applicationCodeId = taxonomyUad?.ApplicationCode?.find(
    (el) => el.code === "Application.BCM"
  ).id;
  const { data: userSettings } = useGetAppSettings(applicationCodeId);

  const { data: user } = useUserData();

  const appSettingCodeId = taxonomyUad?.ApplicationSetting?.find(
    (el) => el.code === "AppSetting.UserSettings"
  ).id;

  const errorHandlerClasses = useErrorHandlerClasses();

  const headerConfig = getHeaderConfig(`BCM Heavy Machinery`);

  return (
    <Wrap>
      <GrecoHeader
        headerConfig={headerConfig}
        panelStatus={onSetPanelIsOpen}
        user={user}
        logo={Logo}
        logout={logout}
        darkMode={(isDarkMode: boolean) => {
          onSetDarkMode(isDarkMode);
        }}
        languageOptions={
          taxonomyUad
            ? (taxonomyUad.SupportedLanguage as SupportedLanguage[])
                .filter(
                  (language: SupportedLanguage) =>
                    language.isGrECoLanguage &&
                    (language.code === "Language.EN_GB" ||
                      language.code === "Language.RO_RO")
                )
                .map<IDropdownOption>((language: SupportedLanguage) => ({
                  key: languageList[language.code],
                  text: t(language.code),
                }))
            : []
        }
        defaultLanguage={i18n.language}
        onChangeLanguage={async (
          __event: any,
          languageOption?: IDropdownOption
        ) => {
          if (!languageOption) return;

          const createData: any = {
            applicationCodeId: applicationCodeId,
            appSettingCodeId: appSettingCodeId,
            userAppSettingName: "Language",
            userAppSettingValue: languageOption.key,
            isDefault: true,
          };
          try {
            const userSettingsForViewOptions: any = userSettings
              .filter((item: any) => item.appSettingCodeId === appSettingCodeId)
              ?.find((userSetting: any) => userSetting.isDefault);

            if (userSettingsForViewOptions) {
              const editData = {
                applicationCodeId: userSettingsForViewOptions.applicationCodeId,
                appSettingCodeId: userSettingsForViewOptions.appSettingCodeId,
                userAppSettingId: userSettingsForViewOptions.userAppSettingId,
                userAppSettingName:
                  userSettingsForViewOptions.userAppSettingName,
                userAppSettingDescription:
                  userSettingsForViewOptions.userAppSettingDescription,
                userAppSettingValue: languageOption.key,
                isDefault: userSettingsForViewOptions.isDefault,
              };
              await updateAppSetting(editData);
            } else await addAppSettings(createData);

            i18n.changeLanguage(languageOption.key as string);
          } catch (err) {
            console.log(err, "error here");
            i18n.changeLanguage(languageOption.key as string);
          }
        }}
        headerStrings={{
          notificationsPanelStrings: {
            panelTitle: t("header.Notifications"),
            noNotificationsTitle: t("header.NoNotificationsTitle"),
            noNotificationsSubtitle: t("header.NoNotificationsSubtitle"),
          },
          helpPanelStrings: {
            panelTitle: t("header.Help"),
          },
          settingsPanelStrings: {
            panelTitle: t("header.Settings"),
            darkModeToggleLabel: t("header.DarkMode"),
            allwaysShowTooltipsToggleLabel: t("header.AlwaysShowTooltips"),
            selectLanguageLabel: t("header.SelectLanguage"),
          },
          userSettingsPanelStrings: {
            panelTitle: t("header.UserSettings"),
            myOfficeProfileLinkText: t("header.MyOfficeProfile"),
            myAccountLinkText: t("header.UserSettings"),
            signOutLinkText: t("header.SignOut"),
          },
          searchPlaceholder: t("header.Search"),
        }}
        messagesCount={notifications.length}
        showErrorAndWarningMessages={() => {
          return (
            <>
              <Button
                style={{
                  margin: "10px 0",
                }}
                icon={<AlertOff20Regular />}
                onClick={onClearNotifications}
              >
                {t("greco.clear")}
              </Button>
              {notifications?.map((item) => {
                return (
                  <div
                    key={item.notify?.label}
                    onClick={(e) => item.handleNotificationClick(e)}
                    className={errorHandlerClasses.notificationMessage}
                  >
                    <NotificationMessages
                      addNotification={false}
                      notify={item.notify}
                      errors={null}
                      fieldName={null}
                    />
                  </div>
                );
              })}
            </>
          );
        }}
      />
    </Wrap>
  );
};

export default AppHeader;

const Wrap = styled.div`
  .greco-header {
  }
`;
