import { ComboboxField } from "components/controls/ComboBoxField";
import InputField from "components/controls/InputField";
import { FieldsContainer } from "pages/VehicleItem/styled";
import React, { useCallback, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { countyOptions } from "./constants";
import { countiesCitiesOptions } from "./countiesCities";
import { useGetCompanyDataByCui } from "hooks/data/mutations/useGetCompanyDataByCui";
import { useLocation } from "react-router-dom";
import { COPY_OFFER } from "library/shared";

function ClientFormItems() {
  const { watch, setValue } = useFormContext();

  const {
    mutate: onGetCompanyData,
    data: companyDataByCui,
    isLoading,
  } = useGetCompanyDataByCui();

  const values = watch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const actionType = queryParams.get("actionType");

  const isCopyAction = actionType === COPY_OFFER;

  const cityOptionsByCountyRaw =
    countiesCitiesOptions[values.inp_lessee_county_ut] || [];

  const cityOptionsByCounty = cityOptionsByCountyRaw.map((item) => ({
    label: item,
    value: item,
  }));

  const firstCityOption = cityOptionsByCounty[0]?.value;

  const countyValue = values?.inp_lessee_county_ut;

  const setCompanyValuesByCui = useCallback(() => {
    Object.keys(companyDataByCui).forEach((key) => {
      const value = companyDataByCui[key];
      setValue(key, value);
    });
  }, [companyDataByCui, setValue]);

  useEffect(() => {
    if (isCopyAction) return;
    setValue("inp_lessee_city_ut", firstCityOption);
  }, [isCopyAction, countyValue, setValue]);

  useEffect(() => {
    if (!companyDataByCui) return;
    setCompanyValuesByCui();
  }, [companyDataByCui, setCompanyValuesByCui, setValue]);

  return (
    <>
      <FieldsContainer>
        <InputField
          required
          name="inp_lessee_company_name_ut"
          label="bcm.companyName.label"
          value={values?.inp_lessee_company_name_ut}
        />
        <InputField
          required
          name="inp_lessee_uid_ut"
          label="bcm.cui.label"
          value={values?.inp_lessee_uid_ut}
          isLoading={isLoading}
          onActionBtnClick={() => {
            onGetCompanyData(values.inp_lessee_uid_ut);
          }}
        />
        <ComboboxField
          required
          name="inp_lessee_county_ut"
          label="bcm.county.label"
          value={values?.inp_lessee_county_ut}
          options={countyOptions}
        />
        <ComboboxField
          required
          name="inp_lessee_city_ut"
          label="bcm.oras.label"
          value={values?.inp_lessee_city_ut}
          options={cityOptionsByCounty}
        />
        <InputField
          required
          name="inp_lessee_street_ut"
          label="bcm.strada.label"
          value={values?.inp_lessee_street_ut}
        />
        <InputField
          required
          name="inp_lessee_housenr_ut"
          label="bcm.number.label"
          value={values?.inp_lessee_housenr_ut}
        />

        <InputField
          name="inp_lessee_building_ut"
          label="bcm.block.label"
          value={values?.inp_lessee_building_ut}
        />
        <InputField
          name="inp_lessee_entrance_ut"
          label="bcm.entrance.label"
          value={values?.inp_lessee_entrance_ut}
        />
        <InputField
          name="inp_lessee_door_ut"
          label="bcm.apartament.label"
          value={values?.inp_lessee_door_ut}
        />
        <InputField
          name="inp_lessee_floor_ut"
          label="bcm.floor.label"
          value={values?.inp_lessee_floor_ut}
        />
        <InputField
          required
          name="inp_lessee_mobile_ut"
          label="bcm.mobilePhone.label"
          value={values?.inp_lessee_mobile_ut}
        />
        <InputField
          required
          type="email"
          name="inp_lessee_email_ut"
          label="bcm.email.label"
          value={values?.inp_lessee_email_ut}
        />
      </FieldsContainer>
    </>
  );
}

export default ClientFormItems;
