import { Button } from "@fluentui/react-components";
import React from "react";

type Props = {
  text: string;
  onClick: () => void;
  isDisabled?: boolean;
  Icon?: React.ReactElement;
};

export const TransparentButton = ({
  text,
  onClick,
  Icon,
  isDisabled,
}: Props) => {
  return (
    <Button
      icon={Icon}
      appearance="transparent"
      onClick={onClick}
      disabled={isDisabled}
    >
      {text}
    </Button>
  );
};
