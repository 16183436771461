import { useQuery, UseQueryResult } from "react-query";
import api from "../../../service/api";
import { QUERY_KEYS } from "library/shared";
import { IUadTaxonomy } from "library/types";
import { urlFormat } from "library/utils";

const uadUrl = urlFormat(process.env.REACT_APP_BFF_HOST_UAD);

const getUADTaxonomy = async () => {
  const response = await api.get(`${uadUrl}/taxonomy`);

  return response.data;
};

export function useLoadUADTaxonomy(): UseQueryResult<IUadTaxonomy> {
  return useQuery([QUERY_KEYS.uadTaxonomy], getUADTaxonomy, {
    enabled: true,
  });
}
