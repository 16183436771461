import { ComboboxField } from "components/controls/ComboBoxField";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import {
  allianzEquipmentGroupOptions,
  allianzMachineTypesAllOptions,
} from "./constants";
import { FieldTitle, FieldsContainer } from "pages/VehicleItem/styled";
import { useLocation } from "react-router-dom";
import { COPY_OFFER } from "library/shared";

function AllianzGroupFields() {
  const { watch, setValue } = useFormContext();
  const values = watch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const actionType = queryParams.get("actionType");
  const isCopyAction = actionType === COPY_OFFER;

  const allianzTypeOptions =
    allianzMachineTypesAllOptions[values.inp_group_ut_all] || [];

  const allianzEquipmentGroupValue = values?.inp_group_ut_all;

  const firstMachineTypeOption = allianzTypeOptions[0]?.value;

  useEffect(() => {
    if (isCopyAction) return;

    setValue("inp_type_ut_all", firstMachineTypeOption);
  }, [allianzEquipmentGroupValue, setValue, isCopyAction]);

  return (
    <>
      <FieldTitle>Allianz</FieldTitle>
      <FieldsContainer>
        <ComboboxField
          required
          name="inp_group_ut_all"
          label="bcm.allianzEquipmentGroup.label"
          value={values.inp_group_ut_all}
          options={allianzEquipmentGroupOptions}
        />
        <ComboboxField
          required
          name="inp_type_ut_all"
          label="bcm.allianzMachineType.label"
          value={values.inp_type_ut_all}
          options={allianzTypeOptions}
        />
      </FieldsContainer>
    </>
  );
}

export default AllianzGroupFields;
