import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Spinner,
} from "@fluentui/react-components";
import { CertificateRegular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ROUTES } from "library/shared";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import useIssueCascoPolicy from "hooks/data/mutations/useIssueCascoPolicy";
import { openLinkInNewTab } from "library/utils";

type Props = {
  isOpen: boolean;
  toggleDialogOpen: () => void;
};

function CreatePolicyDialog({ isOpen, toggleDialogOpen }: Props) {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const navigate = useNavigate();

  const values = getValues();

  const offerId = values?.offerId;

  const { mutate: issueCascoPolicy, isLoading: issuePolicyInProgress } =
    useIssueCascoPolicy();

  const onCreatePolicy = async () => {
    issueCascoPolicy(values, {
      onSuccess: (data) => {
        const path = ROUTES.getVehicleItemPath({
          offerId,
          hasInvoice: true,
          hasPolicy: true,
          // actionType: CREATE_OFFER,
        });

        const policyPdfLink = data?.dataObj?.pathToPolicyCascoPdf;
        openLinkInNewTab(policyPdfLink);

        navigate(path);

        toggleDialogOpen();
        toast.success(t("greco.success"));
      },
    });
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        toggleDialogOpen();
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t("bcm.issuePolicy.label")}</DialogTitle>
          <DialogContent>{t("bcm.issuePolicyQuestion.label")}</DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">{t("bcm.close.label")}</Button>
            </DialogTrigger>
            <Button
              appearance="primary"
              onClick={onCreatePolicy}
              disabled={issuePolicyInProgress}
              icon={
                issuePolicyInProgress ? (
                  <Spinner size="tiny" />
                ) : (
                  <CertificateRegular fontSize={16} />
                )
              }
            >
              {t("bcm.issuePolicy.label")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}

export default CreatePolicyDialog;
