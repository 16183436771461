export const uniqaEquipmentGroupOptions = [
  {
    label: "bcm.uniqaEquipmentGroupOption1.label",
    value: "Autopropulsate",
  },
  {
    label: "bcm.uniqaEquipmentGroupOption2.label",
    value: "Nepropulsate",
  },
];

export const uniqaGroupOfGoodsAllOptions = {
  Autopropulsate: [
    {
      label: "bcm.selfPropelledOption1.label",
      value: "Autopropulsate agricole",
    },

    {
      label: "bcm.selfPropelledOption2.label",
      value: "Autopropulsate constructii",
    },
  ],
  Nepropulsate: [
    {
      label: "bcm.nonPropelledOption1.label",
      value: "Nepropulsate agricole",
    },
    {
      label: "bcm.nonPropelledOption2.label",
      value: "Nepropulsate constructii",
    },
  ],
};

export const oldUniqaMachineOptions = [
  {
    label: "bcm.oldUniqaMachineOption1.label",
    value: "0 - 0,5 ani",
  },
  {
    label: "bcm.oldUniqaMachineOption2.label",

    value: "0,5 - 1 an",
  },
  {
    label: "bcm.oldUniqaMachineOption3.label",

    value: "1 - 2 ani",
  },
  {
    label: "bcm.oldUniqaMachineOption4.label",

    value: "2 - 3 ani",
  },
  {
    label: "bcm.oldUniqaMachineOption5.label",

    value: "3 - 4 ani",
  },
  {
    label: "bcm.oldUniqaMachineOption6.label",

    value: "4 -5 ani",
  },
];

export const uniqaInactivityPeriodOptions = [
  {
    label: "bcm.uniqaInactivityPeriodOption1.label",
    value: "3 luni",
  },
  {
    label: "bcm.uniqaInactivityPeriodOption2.label",
    value: "4 luni",
  },
  {
    label: "bcm.uniqaInactivityPeriodOption3.label",
    value: "5 luni",
  },
  {
    label: "bcm.uniqaInactivityPeriodOption4.label",
    value: "6 luni",
  },
  {
    label: "bcm.uniqaInactivityPeriodOption5.label",
    value: "7 luni",
  },
  {
    label: "bcm.uniqaInactivityPeriodOption6.label",
    value: "8 luni",
  },
  {
    label: "bcm.uniqaInactivityPeriodOption7.label",
    value: "≥ 9luni",
  },
];
