import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";

type Props = {
  data: Record<string, any>;
  doubleColumnView?: boolean;
};

const ReadOnlyFields = ({ data, doubleColumnView = false }: Props) => {
  const { t } = useTranslation();

  if (!data) return <></>;

  const dataKeys = Object.keys(data || []);

  return (
    <GridContainer doubleColumnView={doubleColumnView}>
      {dataKeys.map((key) => {
        const value = data[key];

        return (
          <Wrap key={key}>
            <Label>{t(key)}: </Label>
            <Value>{value}</Value>
          </Wrap>
        );
      })}
    </GridContainer>
  );
};

export default ReadOnlyFields;

const GridContainer = styled.div<{ doubleColumnView?: boolean }>`
  display: grid;
  grid-template-columns: ${({ doubleColumnView }) =>
    doubleColumnView ? "1fr 1fr" : "1fr"};
  grid-column-gap: 40px;
  grid-row-gap: 5px;
`;

const Wrap = styled.div`
  display: flex;
  margin-top: 10px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.black};
  flex-wrap: nowrap;
`;

const Value = styled.div`
  font-weight: 400;
`;
const Label = styled.div`
  font-weight: 600;
  width: 300px;
`;
