import React from "react";

import { ComboboxField } from "components/controls/ComboBoxField";
import DatePickerField from "components/controls/DatePickerField";
import InputField from "components/controls/InputField";

import { useFormContext } from "react-hook-form";

import {
  contractCurrencyOptions,
  contractTypeOptions,
  paymentMethodOptions,
  personTypeOptions,
  vehicleUseOptions,
} from "./constants";
import { FieldsContainer } from "pages/VehicleItem/styled";

function GeneralFormItems() {
  const { watch } = useFormContext();
  const values = watch();

  return (
    <FieldsContainer>
      <ComboboxField
        required
        name={"inp_lessee_type_ut"}
        label={"bcm.personType.label"}
        options={personTypeOptions}
        value={values.inp_lessee_type_ut}
      />
      <DatePickerField
        maxDate={new Date()}
        required
        name={"inp_external_contractdate_ut"}
        label={"bcm.contractLeasing.label"}
        value={
          values.inp_external_contractdate_ut
            ? new Date(values.inp_external_contractdate_ut)
            : null
        }
      />
      <ComboboxField
        required
        name="inp_vehicle_carusage_ut"
        label="bcm.vehicleUse.label"
        value={values.inp_vehicle_carusage_ut}
        options={vehicleUseOptions}
      />
      <ComboboxField
        required
        name="inp_policy_payment_ut"
        label="bcm.paymentMethod.label"
        value={values.inp_policy_payment_ut}
        options={paymentMethodOptions}
      />
      <InputField
        type="number"
        required
        name={"inp_policy_value_ut"}
        label={"bcm.leasingTVA.label"}
        value={values.inp_policy_value_ut}
      />
      <InputField
        type="number"
        required
        name={"inp_leasing_monthsinterval_ut"}
        label={"bcm.leasingPeriod.label"}
        value={values.inp_leasing_monthsinterval_ut}
      />
      <ComboboxField
        required
        name="inp_policy_currency_ut"
        label="bcm.contactCurrency.label"
        value={values.inp_policy_currency_ut}
        options={contractCurrencyOptions}
      />
      <ComboboxField
        required
        name="inp_contract_type_ut"
        label="bcm.contractType.label"
        value={values.inp_contract_type_ut}
        options={contractTypeOptions}
      />
    </FieldsContainer>
  );
}

export default GeneralFormItems;
