import axios from "axios";
import { MSGraphAuth } from "./GraphService";
import { ProjectParams } from "config/GraphConfig";
import { urlFormat } from "library/utils";

let authHeaders = {};

const axiosInstance = axios.create({
  baseURL: urlFormat(process.env.REACT_APP_BASE_URL),
});

async function execute(func, url, config) {
  try {
    const response = await func(url, config);

    return {
      status: response.status,
      statusText: response.statusText,
      data: response.data,
    };
  } catch (error: any) {
    if (error.response) {
      const err = {
        status: error?.response?.status,
        statusText: error?.response?.statusText,
        error:
          error?.response?.data?.error || error?.response?.data?.data?.error,
        message:
          error?.response?.data?.message ||
          error?.response?.data?.data?.message ||
          "",
      };

      throw err;
    }

    throw error;
  }
}

const api = {
  setAuthHeaders: (headers) => {
    authHeaders = {
      ...authHeaders,
      ...headers,
    };
  },
  get: async (url, config = {}) => execute(axiosInstance.get, url, config),
  post: async (url, config) => execute(axiosInstance.post, url, config),
  put: async (url, config) => execute(axiosInstance.put, url, config),
  patch: async (url, config) => execute(axiosInstance.patch, url, config),
  delete: async (url, config) => execute(axiosInstance.delete, url, config),
};

export default api;

axiosInstance.interceptors.request.use(async (config) => {
  let authResponse = await MSGraphAuth.getAccessToken(ProjectParams);

  if (!authResponse) return config;
  if (config.url?.indexOf("thumbnailPhoto") !== -1) {
    return config;
  }
  config!.headers!.Authorization = `Bearer ${authResponse.accessToken}`;
  return config;
});
