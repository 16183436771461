import { useMutation, useQueryClient } from "react-query";
import { QUERY_KEYS } from "library/shared";
import api from "service/api";
import { urlFormat } from "library/utils";

const uadUrl = urlFormat(process.env.REACT_APP_BFF_HOST_UAD);

export const updateAppSetting = async (setting: any) => {
  return await api.patch(`${uadUrl}/updateappsettings`, [setting]);
};

export function useUpdateAppSettings() {
  const queryClient = useQueryClient();

  return useMutation(updateAppSetting, {
    onSuccess: () => {
      // invalidate the query cache for 'books'
      queryClient.invalidateQueries(QUERY_KEYS.appSettings);
    },
    onError: (error) => {
      // handle error
    },
  });
}
