import React from "react";

import { ComboboxField } from "components/controls/ComboBoxField";
import InputField from "components/controls/InputField";

import { getLastNYearsOptions } from "library/utils";
import { useFormContext } from "react-hook-form";

import { FieldsContainer } from "pages/VehicleItem/styled";
import { vehicleConditionOptions } from "./constants";

const last13year = getLastNYearsOptions(13);

function VehicleItems() {
  const { watch } = useFormContext();
  const values = watch();

  return (
    <FieldsContainer>
      <ComboboxField
        required
        name={"inp_vehicle_state_ut"}
        label={"bcm.vehicleConditionLabel.label"}
        options={vehicleConditionOptions}
        value={values.inp_vehicle_state_ut}
      />
      <ComboboxField
        required
        name={"inp_vehicle_construction_ut"}
        label={"bcm.manufacturingYear.label"}
        options={last13year}
        value={values.inp_vehicle_construction_ut}
      />
      <InputField
        required
        name={"inp_vehicle_brand_ut"}
        label={"bcm.brand.label"}
        value={values.inp_vehicle_brand_ut}
      />
      <InputField
        required
        name={"inp_vehicle_model_ut"}
        label={"bcm.model.label"}
        value={values.inp_vehicle_model_ut}
      />
      <InputField
        required
        name={"inp_vehicle_vin_ut"}
        label={"bcm.chassisSeries.label"}
        value={values.inp_vehicle_vin_ut}
      />
    </FieldsContainer>
  );
}

export default VehicleItems;
