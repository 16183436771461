export const countyOptions = [
  {
    label: "bcm.countryOption2.label",
    value: "ALBA",
  },
  {
    label: "bcm.countryOption3.label",
    value: "ARGES",
  },
  {
    label: "bcm.countryOption4.label",
    value: "ARAD",
  },
  {
    label: "bcm.countryOption5.label",
    value: "BUCURESTI",
  },
  {
    label: "bcm.countryOption6.label",
    value: "BACAU",
  },
  {
    label: "bcm.countryOption7.label",
    value: "BIHOR",
  },
  {
    label: "bcm.countryOption8.label",
    value: "BISTRITA-NASAUD",
  },
  {
    label: "bcm.countryOption9.label",
    value: "BRAILA",
  },
  {
    label: "bcm.countryOption10.label",
    value: "BOTOSANI",
  },
  {
    label: "bcm.countryOption11.label",
    value: "BRASOV",
  },
  {
    label: "bcm.countryOption12.label",
    value: "BUZAU",
  },
  {
    label: "bcm.countryOption13.label",
    value: "CLUJ",
  },
  {
    label: "bcm.countryOption14.label",
    value: "CALARASI",
  },
  {
    label: "bcm.countryOption15.label",
    value: "CARAS-SEVERIN",
  },
  {
    label: "bcm.countryOption16.label",
    value: "CONSTANTA",
  },
  {
    label: "bcm.countryOption17.label",
    value: "COVASNA",
  },
  {
    label: "bcm.countryOption18.label",
    value: "DAMBOVITA",
  },
  {
    label: "bcm.countryOption19.label",
    value: "DOLJ",
  },
  {
    label: "bcm.countryOption20.label",
    value: "GORJ",
  },
  {
    label: "bcm.countryOption21.label",
    value: "GALATI",
  },
  {
    label: "bcm.countryOption22.label",
    value: "GIURGIU",
  },
  {
    label: "bcm.countryOption23.label",
    value: "HUNEDOARA",
  },
  {
    label: "bcm.countryOption24.label",
    value: "HARGHITA",
  },
  {
    label: "bcm.countryOption25.label",
    value: "ILFOV",
  },
  {
    label: "bcm.countryOption26.label",
    value: "IALOMITA",
  },
  {
    label: "bcm.countryOption27.label",
    value: "IASI",
  },
  {
    label: "bcm.countryOption28.label",
    value: "MEHEDINTI",
  },
  {
    label: "bcm.countryOption29.label",
    value: "MARAMURES",
  },
  {
    label: "bcm.countryOption30.label",
    value: "MURES",
  },
  {
    label: "bcm.countryOption31.label",
    value: "NEAMT",
  },
  {
    label: "bcm.countryOption32.label",
    value: "OLT",
  },
  {
    label: "bcm.countryOption33.label",
    value: "PRAHOVA",
  },
  {
    label: "bcm.countryOption34.label",
    value: "SIBIU",
  },
  {
    label: "bcm.countryOption35.label",
    value: "SALAJ",
  },
  {
    label: "bcm.countryOption36.label",
    value: "SATU-MARE",
  },
  {
    label: "bcm.countryOption37.label",
    value: "SUCEAVA",
  },
  {
    label: "bcm.countryOption38.label",
    value: "TULCEA",
  },
  {
    label: "bcm.countryOption39.label",
    value: "TIMIS",
  },
  {
    label: "bcm.countryOption40.label",
    value: "TELEORMAN",
  },
  {
    label: "bcm.countryOption41.label",
    value: "VALCEA",
  },
  {
    label: "bcm.countryOption42.label",
    value: "VRANCEA",
  },
  {
    label: "bcm.countryOption43.label",
    value: "VASLUI",
  },
];
