import * as yup from "yup";
import i18n from "../i18n";
import { TFunction } from "i18next";

export const setYupLocale = (t: TFunction) => {
  yup.setLocale({
    mixed: {
      required: i18n.t("greco.form.errors.required"),
    },
    number: {
      min: ({ min }) => i18n.t("greco.form.errors.number.min", { min }),
      max: ({ max }) => i18n.t("greco.form.errors.number.max", { max }),
    },
  });
};

export default yup;
