export const requiredFieldsForCalculation = [
  "inp_lessee_type_ut",
  "inp_vehicle_state_ut",
  "inp_policy_value_ut",
  "inp_vehicle_construction_ut",
  "inp_external_contractdate_ut",
  "inp_vehicle_brand_ut",
  "inp_vehicle_model_ut",
  "inp_group_ut_asirom",
  "inp_type_ut_asirom",
  "inp_mbk_asirom",
  "inp_vehicle_carusage_ut",
  "inp_policy_payment_ut",
  "inp_group_ut_uniqa",
  "inp_mbk_uniqa",
  "inp_usage_ut",
  "inp_inactivity_ut",
  "inp_group_ut_omn",
  "inp_type_ut_omn",
  "inp_group_ut_gro",
  "inp_type_ut_gro",
  "inp_group_ut_all",
  "inp_type_ut_all",
  "inp_leasing_monthsinterval_ut",
  "inp_policy_currency_ut",
  "inp_contract_type_ut",
];

export const requiredFieldsForCreateOffer = [
  "inp_lessee_type_ut",
  "inp_vehicle_state_ut",
  "inp_policy_value_ut",
  "inp_vehicle_construction_ut",
  "inp_external_contractdate_ut",
  "inp_vehicle_brand_ut",
  "inp_vehicle_model_ut",
  "inp_group_ut_asirom",
  "inp_type_ut_asirom",
  "inp_mbk_asirom",
  "inp_vehicle_carusage_ut",
  "inp_policy_payment_ut",
  "inp_group_ut_uniqa",
  "inp_mbk_uniqa",
  "inp_usage_ut",
  "inp_inactivity_ut",
  "inp_group_ut_omn",
  "inp_type_ut_omn",
  "inp_group_ut_gro",
  "inp_type_ut_gro",
  "inp_group_ut_all",
  "inp_type_ut_all",
  "inp_leasing_monthsinterval_ut",
  "inp_policy_currency_ut",
  "inp_contract_type_ut",

  "inp_lessee_company_name_ut",
  "inp_lessee_uid_ut",
  "inp_lessee_street_ut",
  "inp_lessee_housenr_ut",
  "inp_lessee_city_ut",
  "inp_lessee_county_ut",
  "inp_lessee_mobile_ut",
  "inp_lessee_email_ut",
];

export const requiredFieldsForGenerateInvoice = [
  "inp_beneficiar_ut",
  "inp_policy_leasingctrno_ut",
];
