import { useMutation } from "react-query";
import { notificationTypes } from "library/shared";
import api from "service/api";
import { errorHandler } from "components/AppHeader/NotificationMessages";

const getCompanyData = async (cui: string) => {
  const response = await api.get(`/GetCompanyInfo/${cui}`);

  return response.data?.appInfo?.dataObj;
};

export function useGetCompanyDataByCui() {
  return useMutation(getCompanyData, {
    onSuccess: () => {},

    onError: (error: any) => {
      errorHandler({
        label: "Error",
        type: notificationTypes.error,
        value: "Error while loading company data by c.u.i",
        errors: error?.message,
        fieldName: "",
      });
    },
  });
}
