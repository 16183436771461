import React, {
  useMemo,
  useState,
  useCallback,
  createContext,
  useContext,
} from "react";

import { LocalStorage } from "service/storage";
import { STORAGE_KEYS } from "library/shared";
import { useUserData } from "hooks/data/queries/useUserData";
import { INotification } from "library/types";
import { useLoadOfferListData } from "hooks/data/queries/useLoadOfferListData";

interface IAppContext {
  onSetPanelIsOpen: (status: boolean) => void;
  darkMode: boolean;
  panelIsOpen: boolean;
  onSetDarkMode: (value: boolean) => void;

  onSetNotifications: (value: INotification) => void;
  notifications: INotification[];
  onClearNotifications: () => void;
  onRemoveNotification: (fieldName: INotification["fieldName"]) => void;
}

type Props = {
  children: React.ReactNode;
};

export const AppContext = createContext<IAppContext | undefined>(undefined);

export const useAppContext = (): IAppContext => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("useAppContext cannot be used without AppContext");
  }

  return context;
};

export const AppProvider = ({ children }: Props) => {
  const isDarkMode = LocalStorage.get(STORAGE_KEYS.darkMode);

  useUserData();
  useLoadOfferListData();

  const [darkMode, setDarkMode] = useState(!!isDarkMode);
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const onSetNotifications = useCallback((notification: any) => {
    setNotifications((prev) => [...prev, notification]);
  }, []);

  const onClearNotifications = useCallback(() => {
    setNotifications([]);
  }, []);

  const onRemoveNotification = useCallback((notification: string) => {
    setNotifications((prev) => {
      const filterNotifications = prev.filter(
        (item) => item.key !== notification
      );
      return filterNotifications;
    });
  }, []);

  const onSetPanelIsOpen = useCallback((status: boolean) => {
    setPanelIsOpen(status);
  }, []);

  const onSetDarkMode = useCallback((value) => {
    setDarkMode(value);
    LocalStorage.set(STORAGE_KEYS.darkMode, value);
  }, []);

  const value = useMemo(
    () => ({
      darkMode,
      panelIsOpen,
      onSetDarkMode,
      onSetPanelIsOpen,
      onSetNotifications,
      notifications,
      onClearNotifications,
      onRemoveNotification,
    }),
    [
      darkMode,
      panelIsOpen,
      onSetDarkMode,
      onSetPanelIsOpen,
      onSetNotifications,
      notifications,
      onClearNotifications,
      onRemoveNotification,
    ]
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
