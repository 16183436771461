import { ArrowLeft20Filled } from "@fluentui/react-icons";
import { NavigationBar } from "@greco/components";
import { TransparentButton } from "components/TransparentButton/TransparentButton";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Navigation() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToMainPage = useCallback(() => {
    navigate("/");
  }, []);

  return (
    <NavigationBar>
      <TransparentButton
        text={t("bcm.back.label")}
        onClick={goToMainPage}
        Icon={<ArrowLeft20Filled />}
      />
    </NavigationBar>
  );
}

export default Navigation;
