import { Checkbox } from "@fluentui/react-components";
import { QUERY_KEYS } from "library/shared";
import { useMainContext } from "providers/MainProvider";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";

function ShowArchived() {
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const { params, onSetParams } = useMainContext();

  const handleChange = (ev, data) => {
    queryClient.removeQueries(QUERY_KEYS.vehicleList);

    onSetParams({
      skip: 0,
      showArchived: data.checked,
    });
  };
  return (
    <Checkbox
      checked={params.showArchived}
      onChange={handleChange}
      label={t("bcm.showArchived.label")}
    />
  );
}

export default ShowArchived;
