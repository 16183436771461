export const GrecoThemes = {
  default :{
    palette: {
      themePrimary: "#0078d4",
      themeLighterAlt: "#f3f9fd",
      themeLighter: "#d0e7f8",
      themeLight: "#a9d3f2",
      themeTertiary: "#5ca9e5",
      themeSecondary: "#1a86d9",
      themeDarkAlt: "#006cbe",
      themeDark: "#005ba1",
      themeDarker: "#004377",
      neutralLighterAlt: "#f8f8f8",
      neutralLighter: "#f4f4f4",
      neutralLight: "#eaeaea",
      neutralQuaternaryAlt: "#dadada",
      neutralQuaternary: "#d0d0d0",
      neutralTertiaryAlt: "#c8c8c8",
      neutralTertiary: "#bab8b7",
      neutralSecondary: "#a3a2a0",
      neutralPrimaryAlt: "#8d8b8a",
      neutralPrimary: "#323130",
      neutralDark: "#605e5d",
      black: "#494847",
      white: "#ffffff",
    }
  },
  darkMode: {
    palette: {
      themePrimary: '#0076d1',
      themeLighterAlt: '#000508',
      themeLighter: '#001321',
      themeLight: '#00243f',
      themeTertiary: '#00477d',
      themeSecondary: '#0068b8',
      themeDarkAlt: '#1582d6',
      themeDark: '#3594dc',
      themeDarker: '#65aee5',
      neutralLighterAlt: '#0b0b0b',
      neutralLighter: '#151515',
      neutralLight: '#252525',
      neutralQuaternaryAlt: '#2f2f2f',
      neutralQuaternary: '#373737',
      neutralTertiaryAlt: '#595959',
      neutralTertiary: '#c8c8c8',
      neutralSecondary: '#d0d0d0',
      neutralPrimaryAlt: '#dadada',
      neutralPrimary: '#ffffff',
      neutralDark: '#f4f4f4',
      black: '#f8f8f8',
      white: '#000000',
    },
    effects: {
      elevation4: '0 1.6px 3.6px 0 rgba(248, 248, 248, 0.132), 0 0.3px 0.9px 0 rgba(248, 248, 248, 0.108)',
      elevation8: '0 3.2px 7.2px 0 rgba(248, 248, 248, 0.132), 0 0.6px 1.8px 0 rgba(248, 248, 248, 0.108)',
      elevation16: '0 6.4px 14.4px 0 rgba(248, 248, 248, 0.132), 0 1.2px 3.6px 0 rgba(248, 248, 248, 0.108)',
      elevation64: '0 25.6px 57.6px 0 rgba(248, 248, 248, 0.22), 0 4.8px 14.4px 0 rgba(248, 248, 248, 0.18)'
    }
  }
}